import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { routerObj } from './../../../../core/constants/router-constants';
@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent {
  /**
   *used for detecting component type to be render on basic of route param (ex. forgotPassword | resetPassword | loginpage )
   *
   * @type {(string | null)}
   * @memberof AuthenticationComponent
   */
  componentType: string | null;
  /**
   *it contains reference of all routes
   *
   * @type {*}
   * @memberof AuthenticationComponent
   */
  authRoute = routerObj;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {

    this.route?.paramMap?.subscribe((params) => {
      this.componentType = params.get('componentType');
    });
    this.translate.setDefaultLang('en');
  }
}
