<lib-rspl-layout-card>

	<h2 class="text-center mb-5 fs-32 text-bold">{{ 'auth.loginPage.adminLogin' | translate }}</h2>
	<form [formGroup]="loginForm">
		<ul class="form-element-wrap list-style-none p-0 m-0">
			<li class="form-element mb-1">
				<lib-rspl-textbox className="form-Field with-bg" type="email" id="email" name="email" label="Email"
					[isLabelShow]="false" maxLength="128" placeholder="Email" formControlName="username">
				</lib-rspl-textbox>
			</li>
			<li class="form-element mb-1">
				<lib-rspl-textbox className="form-Field with-bg" type="password" id="password" name="password" label="Password"
					[isLabelShow]="false" maxLength="16" placeholder="Password" formControlName="password"
					[blockCopyPaste]="true">
				</lib-rspl-textbox>
			</li>
			<li class="form-element mb-2 felem-checkbox felem-remember d-flex flex-wrap flex-justify-between flex-items-center fs-14">
				<lib-rspl-checkbox labelPosition="after" id="rememberMe" formControlName="rememberMe"
					class="example-margin" label="{{ 'auth.loginPage.rememberMe' | translate }}">
				</lib-rspl-checkbox>
				<a [routerLink]="['/'+ routerObj.AUTH.AUTH + '/' + routerObj.AUTH.FORGOT_PASSWORD]"
					routerLinkActive="router-link-active" title="{{ 'auth.loginPage.forgotPassword' | translate }}"
					class="p-2 ghost-btn br-xs color-primary fs-14">{{ 'auth.loginPage.forgotPassword' | translate }}</a>
			</li>
			<li class="form-element felem-submit">
				<div class="mdc-touch-target-wrapper">
					<lib-rspl-button [styles]="buttonWidth" id="loginButton" buttonType="flat-button" color="primary"
						label="{{ 'auth.loginPage.login' | translate }}" (buttonClick)="onSubmit()"></lib-rspl-button>
				</div>
			</li>
		</ul>
	</form>
</lib-rspl-layout-card>
