import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCanLoad } from '../../core/guards/authCanLoad.guard';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginPageComponent } from './components/login-page/login-page.component';

const routes: Routes = [
  { path: '', pathMatch: "full", component: AuthenticationComponent },
  { path: ':componentType', pathMatch: "full", component: AuthenticationComponent },
  { path: ':componentType/email/:emailId/code/:token', pathMatch: "full", component: AuthenticationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
