import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { RadioButtonList } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/radiobuton-list.model';
import { HighlightLoader, HighlightResult } from 'ngx-highlightjs';
import uiComponentsData from './json/ui-components.json';
import { RadioSourceData } from './renderers/radio';
import { checkboxGroup } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/checkbox-group.model';
import { HttpClient } from '@angular/common/http';
import { CheckboxSourceData } from './renderers/checkbox';
import { IconSourceData } from './renderers/icon';
import { SingleSelectSourceData } from './renderers/singleSelectSourceData';
import { MultiSelectDropDownSourceData } from './renderers/multiSelectDropDownSourceData';
import { Button, ButtonSourceData} from './renderers/button';
import { InputSourceData } from './renderers/input';
import { DatepickerSourceData } from './renderers/datepicker';
import moment from 'moment';
import { DraglistSourceData } from './renderers/draglist';
import { customizeListItem } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/customize-list-item.model';
import { HierarchyModel, NavItem } from '@rspl/angular-deps-library/dist/rspl-angular-lib';
import { HierarchySourceData } from './renderers/hierarchy';
import { ComponentModel } from '../../shared/model/component.model';
import { Menu } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/menu.model';
import { selectableChipMenu } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/selecteble-chip-with-menu.model';
import { PopupService } from '../../shared/services/popup/popup.service';
import { TabsSourceData } from './renderers/tabs';
import { TextareaSourceData } from './renderers/textarea';
import { MenuSourceData } from './renderers/menu';
import { SelectChipMenuSourceData } from './renderers/selectChipMenu';
import { AccordianSourceData } from './renderers/accordian';
import { PopupSourceData } from './renderers/popup';
import { ProfileIconSourceData } from './renderers/profileIcon';
import { ProgressBarSourceData } from './renderers/progressBar';
import { PopoverSourceData } from './renderers/popover';
import { SlideToggleSourceData } from './renderers/slideToggle';
import { BadgeSourceData } from './renderers/badge';
import { ChipSetSourceData } from './renderers/chipSet';
import { CameraSourceData } from './renderers/camera';
import { CarosulSourceData } from './renderers/carosul';
import { NestedMenuSourceData } from './renderers/nestedMenu';
import { LoaderSourceData } from './renderers/loader';
import { ListSourceData } from './renderers/list';
import { FabMenuSourceData } from './renderers/fabMenu';
import { chipSetItem } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/chipset.model';
import { WebcamImage } from 'ngx-webcam';
import { list } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/list.model';
import { CardsSourceData } from './renderers/cards';
import { ChartsSourceData } from './renderers/charts';
import { LegendPosition } from '@swimlane/ngx-charts';
import { ConfirmationPopupComponent } from '../shared/components/confirmation-popup/confirmation-popup.component';
import { ISelectDropdown } from '@app/shared/model/select-list.model';
const themeGithub: string = 'highlight.js/styles/github-dark.css';
const themeAndroidStudio: string = 'highlight.js/styles/androidstudio.css';

export interface itemList {
  id: number,
  name: string,
  value: string,
  module: string,
  description: string
}

@Component({
  selector: 'app-ui-components',
  templateUrl: './ui-components.component.html',
  styleUrls: ['./ui-components.component.scss'],
})
export class UiComponentsComponent implements OnInit {
  currentTheme: string = themeAndroidStudio;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  viewType: RadioButtonList[] = [
    {
      id: 1,
      label: 'RSPL Library',
      value: 'rspl-library',
    },
    {
      id: 2,
      label: '5Day Specific',
      value: '5day-specific',
    },
  ];

  /**
   * Simple radio list data
   */
  simpleRadioList: RadioButtonList[] =
    uiComponentsData.uiComponent.radioList.simpleRadioBtn;

  /**
   * Icon radio list data
   */
  iconRadioList: RadioButtonList[] =
    uiComponentsData.uiComponent.radioList.iconRadioBtn;

  /**
   * Icon tooltip radio list data
   */
  iconTooltipRadioList: RadioButtonList[] =
    uiComponentsData.uiComponent.radioList.iconTooltipRadioBtn;

  /**
   * Check box list data
   */
  Checkbox: checkboxGroup[] = uiComponentsData.uiComponent.checkBoxList;

  dropdownList: ISelectDropdown[] =
    uiComponentsData.uiComponent.dropDownList;

  /**
   * Button list data
   */
  buttonList: Button[]  = uiComponentsData.uiComponent.buttonTypes;

  /**
   * Drag list data
   */
  dragList: customizeListItem[] | any = uiComponentsData.uiComponent.dragList;

  /**
   * Hierachy dropdown data
   */
  TREE_DATA: HierarchyModel[] | any = uiComponentsData.uiComponent.TREE_DATA;

  /**
   * Tabs Data
   */
  tabsList: Array<ComponentModel> = [
    {
      data: { label: 'Demo Tab 1', value: 1 },
    },
    {
      data: { label: 'Demo Tab 2', value: 2 },
    },
  ];

  /**
   * Menu Data
   */
  menuItems: Menu[] = uiComponentsData.uiComponent.menuList;

  /**
   * Select menu chip data
   */
  chipData: selectableChipMenu[] = uiComponentsData.uiComponent.chipdata;
  accordianData = [
    {
      panelOpenState: false,
      text: 'Accordian 1',
      type: 'ProjectSummary',

      disabled: false,

      data: [],
    },
    {
      panelOpenState: false,
      text: 'Accordian 2',
      type: 'ProjectSummary',

      disabled: false,

      data: [],
    },
  ];

  dataSource: chipSetItem[] = uiComponentsData.uiComponent.chipSetData;

  nestedMenuu: NavItem[] =  uiComponentsData.uiComponent.nestedMenu

  listData:list[]=  uiComponentsData.uiComponent.list;

  cardContent: Array<any> = [
    {
      icon:'Add',
      label:'Chip Card',
      value:'Chip Card'
    }
  ]

  pieChartData = [
    //TODO will be updated with API data once API's are available.
    {
      name: 'Overdue',
      value: 54,
      myValue: 2,
    },
    {
      name: 'Today',
      value: 147,
    },
    {
      name: 'Upcoming',
      value: 62,
      myValue: 1,
    },
    {
      name: 'No data set',
      value: 71,
      myValue: 2,
    },
  ];

  lineGraphData = [
    {
      name: 'Karthikeyan',
      series: [
        {
          name: 'Jan',
          value: '1000',
        },
        {
          name: 'Feb',
          value: '500',
        },
        {
          name: 'Mar',
          value: '1500',
        },
        {
          name: 'Apr',
          value: '3000',
        },
        {
          name: 'May',
          value: '2000',
        },
        {
          name: 'Jun',
          value: '5000',
        },

      ],
    },
  ];

  popupOpen: ComponentModel = {
    componentName: ConfirmationPopupComponent,
  };
  itemList: itemList[]= [
    {
      id: 2,
      name: 'Singleselect Dropdown',
      value: 'singleSelectDropdown',
      module: 'RsplDropdownSingleselectModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 3,
      name: 'Button Group',
      value: 'button',
      module: 'RsplButtonModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 4,
      name: 'Input Group',
      value: 'input',
      module: ' RsplTextboxModule',
      description: `This sample demonstrates the overview of basic grid features with its performances metrics of large data.`,
    },
    {
      id: 5,
      name: 'Icon Group',
      value: 'icon',
      module: 'RsplMatIconModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 6,
      name: 'Checkbox Group',
      value: 'checkbox',
      module: 'RsplCheckboxModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 7,
      name: 'Radio Button Group',
      value: 'radio',
      module: 'RsplRadioListModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 8,
      name: 'Date Picker Group',
      value: 'datepicker',
      module: 'RsplDatePickerModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 9,
      name: 'Drag List Group',
      value: 'draglist',
      module: 'RsplDragListModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 10,
      name: 'Hierarchy Dropdown',
      value: 'hierarchy',
      module: 'RsplHierarchyDropdownModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 11,
      name: 'Tabs Group',
      value: 'tabs',
      module: 'RsplTabGroupModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 12,
      name: 'Text Area',
      value: 'textarea',
      module: 'RsplTextareaModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 13,
      name: 'Menu Group',
      value: 'menu',
      module: 'RsplMenuModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 14,
      name: 'Selectable Chip Menu ',
      value: 'chipmenu',
      module: 'RsplSelectableMenuChipModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 15,
      name: 'Accordian Group',
      value: 'accordian',
      module: 'RsplAccordianModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 16,
      name: 'Popup',
      value: 'popup',
      module: 'RsplPopupModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 17,
      name: 'Profile Icon',
      value: 'profileIcon',
      module: 'RsplProfileIconModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 18,
      name: 'Progress Bar',
      value: 'progressBar',
      module: 'RsplProgressBarModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 19,
      name: 'Popover',
      value: 'popover',
      module: 'RsplPopoverModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 20,
      name: 'Slide Toggle',
      value: 'slideToggle',
      module: 'RsplSlideToggleModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 21,
      name: 'Badge',
      value: 'badge',
      module: 'RsplBadgeModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 22,
      name: 'Chip Set',
      value: 'chipSet',
      module: 'RsplChipSetModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 23,
      name: 'Camera',
      value: 'camera',
      module: 'RsplCameraModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 24,
      name: 'Carosul',
      value: 'carosul',
      module: 'RsplCarouselModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 25,
      name: 'Nested Menu',
      value: 'nestedMenu',
      module: 'RsplNestedMenuModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 26,
      name: 'Loader',
      value: 'loader',
      module: 'RsplLoaderModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 27,
      name: 'List',
      value: 'list',
      module: 'RsplListModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 28,
      name: 'Fab Menu',
      value: 'fabMenu',
      module: 'RsplFabMenuModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 29,
      name: 'Cards',
      value: 'cards',
      module: 'RsplCardsModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    },
    {
      id: 30,
      name: 'Charts',
      value: 'charts',
      module: 'RsplChartsModule',
      description: `This sample demonstrates the overview of basic grid features with its performance metrics of large data.`,
    }
  ];
  secondarySelectedDropdown: any;
  primarySelectedDropdown: any;
  viewForm: FormGroup;
  dateForm: FormGroup;
  radioForm: FormGroup;
  viewComponent: string = 'multi-select-dropdown';
  apiImport: string = 'RsplDropdownSingleselectModule1';
  viewComponentName: string = 'Singleselect Dropdown';
  htmlCode: any;
  tsCode: any;
  jsonCode: any;
  sourceItems: any;
  apiReference: string;
  configUrl: string;
  selectedViewDescription: string;
  constructor(
    private fb: FormBuilder,
    private radio: RadioSourceData,
    private http: HttpClient,
    private checkbox: CheckboxSourceData,
    private icon: IconSourceData,
    private singleSelectDropdown: SingleSelectSourceData,
    private multiSelectDropdown: MultiSelectDropDownSourceData,
    private button: ButtonSourceData,
    private input: InputSourceData,
    private datepicker: DatepickerSourceData,
    private draglist: DraglistSourceData,
    private hierarchy: HierarchySourceData,
    private tabs: TabsSourceData,
    private textarea: TextareaSourceData,
    private menu: MenuSourceData,
    private chipmenu: SelectChipMenuSourceData,
    private accordian: AccordianSourceData,
    private popup: PopupSourceData,
    private profileIcon: ProfileIconSourceData,
    private progressBar: ProgressBarSourceData,
    private popupService: PopupService,
    private popover: PopoverSourceData,
    private slideToggle: SlideToggleSourceData,
    private badge: BadgeSourceData,
    private chipSet: ChipSetSourceData,
    private camera: CameraSourceData,
    private carosul: CarosulSourceData,
    private nestedMenu: NestedMenuSourceData,
    private loader: LoaderSourceData,
    private list: ListSourceData,
    private fabMenu: FabMenuSourceData,
    private cards: CardsSourceData,
    private charts: ChartsSourceData
  ) {
    this.radioForm = this.fb.group({
      radiobutton1: [''],
      radiobutton2: [''],
      radiobutton3: ['']
    });
    this.dateForm = this.fb.group({
      startDate: moment('June 01, 2020', 'MMM DD,YYYY'),
    });
  }

  regularChart = {
    donut: true,
    colorScheme: { domain: ['#ff2e2e', '#00c2f9', '#ffc400', '#bcd0d6'] },
    gradient: true,
    legend: false,
    legendPosition: LegendPosition.Below,
    labels: false,
    tooltipDisabled: false,
    chartType: {
      type: 'regular',
      theaders: ['Tasks', 'My', 'All'],
    },
  };

  summaryChart = {
    donut: true,
    colorScheme: { domain: ['#5cced5', '#a259ff', '#6ccd76', '#ff9500'] },
    gradient: true,
    legend: false,
    legendPosition: LegendPosition.Below,
    labels: false,
    tooltipDisabled: false,
    chartType: {
      type: 'summary',
      theaders: ['Timesheets summary', 'My', 'All'],
      summarybtnlbl: [
        { label: 'Total logged', value: '' },
        { label: 'TMy logged', value: '' },
      ],
    },
  };

  response: HighlightResult;
  //selecteble-chip-with-menu
  selectebleChipWithMenu: number = 0;
  ngOnInit(): void {
    this.accordion.openAll();
    this.viewForm = this.fb.group({
      timeEntryRadio: [''],
    });


  }

  onItemSelection(item: any) {
    this.selectedViewDescription = item.description;
    this.configUrl = './renderers/' + item.value + '.ts';
    console.log(item.value);
    this.viewComponent = item.value;
    this.apiImport = item.module;
    this.viewComponentName = item.name;
    this.sourceItems = this[item.value][item.value];
    console.log('data', this.sourceItems);
    this.apiReference =
      `import { ` +
      item.module +
      `} from '@rspl/angular-deps-library/dist/rspl-angular-lib';`;
  }

  onTypeofDemoSelect(value: object) {
    console.log('value', value);
  }

  //app-selecteble-chip-with-menu actions
  chipSelect(selectedItem: any) {
    this.selectebleChipWithMenu = selectedItem;
  }

  openPopup() {
   this.popupService.openPopup(this.popupOpen);
    this.popupService.dialogRef.afterClosed();
  }

  dateFormDetails = {
    startDate: {
      id: 'startDate',
      name: 'Start Date',
      className: 'icon5D-calendar',
      show: true,
    },
  };

  captureImage: string = '';
  state: 'Ideal' | 'Capture' = 'Ideal';
  snapShot(event: WebcamImage) {
    this.captureImage = event.imageAsDataUrl;
  }

  /**
   * To retake photo
   */
  reTakeBtn() {
    this.captureImage = '';
    this.state = 'Capture';
  }
}
