export class CameraSourceData {
  camera = [
    {
      type: 'Camera',
      html: `<h4>Camera</h4>
      <img [src]="captureImage" *ngIf="captureImage" />
      <lib-rspl-button
      *ngIf="captureImage"
      buttonType="stroked-button"
      color="primary"
      label="Click Again"
      (buttonClick)="reTakeBtn()"
    >
    </lib-rspl-button>
      <lib-rspl-camera *ngIf="!captureImage" (takeSnapshot)="snapShot($event)" [state]="state"></lib-rspl-camera>`,
      css: ``,
      ts: `  /**
      *event for capturing camera output
      *
      * @memberof CameraComponent
      */
     @Output() takeSnapshot = new EventEmitter();
     /**
      *store webcam image
      *
      * @type {WebcamImage}
      * @memberof CameraComponent
      */
     public webcamImage: WebcamImage | any;
     // webcam snapshot trigger
     private trigger: Subject<void> = new Subject<void>();

     captureImage: string = '';
     state: 'Ideal' | 'Capture' = 'Ideal';
     snapShot(event: WebcamImage){
       this.captureImage = event.imageAsDataUrl;
     }

     /**
      * To retake photo
      */
     reTakeBtn() {
       this.captureImage = '';
       this.state = 'Capture';
     }
     `,
      json: ` `,
    },
  ];
}
