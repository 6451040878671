import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { FieldConfig } from '@app/shared/module/dynamic-form/components/model/field.interface';

@Component({
  selector: 'app-f-radiobutton-list',
  templateUrl: './f-radiobutton-list.component.html',
  styleUrls: ['./f-radiobutton-list.component.scss'],
})
export class FBRadioButtonListComponent {
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group: UntypedFormGroup;
}
