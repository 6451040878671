import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RsplAccordianComponent } from '@rspl/angular-deps-library/dist/rspl-angular-lib';
import { SnackBarService } from 'src/app/shared/services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-demo-popup-page',
  templateUrl: './demo-popup-page.component.html',
  styleUrls: ['./demo-popup-page.component.scss']
})
export class DemoPopupPageComponent implements OnInit {

  /**
   * it holds if any data is coming from parent
   */
  data: any;

  /**
   * it holds textarea field value
   */
  textareaFieldValue: string = '';
  customizeList: any;
  constructor(
    private snackbarService: SnackBarService,
    private accordionData : RsplAccordianComponent) {
     // console.log("DATA ", accordionData);
  }
  // constructor(private dialogRef: MatDialogRef<PopupComponent>,
  //   private snackbarService: SnackBarService) {
  // }

  ngOnInit(): void {
    // this.customizeList = this.data;
    this.customizeList = this.accordionData;
    // this.textareaFieldValue = this.data.textareaFieldValue;
  }

  /**
   * this gives updated value from textarea component
   */
  getTextareaConent(value: string) {
    this.textareaFieldValue = value;
  }

  /**
   * This method sends updated value to parent
   */
  sendTextareaValue() {
    if (this.textareaFieldValue == '') {
      this.snackbarService.openSnackBar('Please enter the data!', 'error')
    } else {
      this.data.textareaFieldValue = this.textareaFieldValue;
      // this.dialogRef.close(this.data);
    }
  }

  /**
   * this method is to close the popup without updated values
   */
  closePopup() {
    // this.dialogRef.close();
  }

  addNewStatus() {
    // TODO : this random id will be removed and will add new one
    let obj = {
      id: Math.random(),
      name: '',
      color : '#12db0b',
      showActions: false,
      dragDisabled: false,
      menuIcon: 'more_vert',
      actionList: {
        color: true,
        menu: true
      },
      menuOptions: [{ id: 1, label: 'Delete' }]
    };
    this.customizeList.push(obj);
  }
}
