import { Component, Input, Self, Optional, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';

//typecasting purpose
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { FieldConfig } from '@app/shared/module/dynamic-form/components/model/field.interface';
import { DynamicFormService } from '../../../services/dynamic-form.service';

/**
 *@example
 * Example
 * <lib-rspl-textbox label="Full name" type="text" name="email"
 * placeholder="John Doe" formControlName="fullname">
 * </lib-rspl-textbox>
 *
 * @export
 * @class FTextboxComponent
 * @implements {ControlValueAccessor}
 */
@Component({
  selector: 'app-fb-textbox',
  templateUrl: './f-textbox.component.html',
  styleUrls: ['./f-textbox.component.scss'],
})
export class FBTextboxComponent implements OnInit{
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group: UntypedFormGroup;
  formData: UntypedFormGroup
  @Input() appearance: MatFormFieldAppearance = 'outline';

  /**
   * Country codes List for ph number
   */
  // TODO : update the codes Dynamically later
  countryCodes = [{ 'code': '91', 'name': 'India' },
  { 'code': '61', 'name': 'Afghanistan' },
  { 'code': '32', 'name': 'Belgium' },
  { 'code': '55', 'name': 'Brazil' }];



  constructor(private fb: UntypedFormBuilder, private dynamicFormService: DynamicFormService) {
  }
  ngOnInit() {
    if(this.field && this.field.conditional) {
      if(this.group.value[this.field.conditional.checkControlName] === this.field.conditional.compairControlNameValue) {
        this.group.controls[this.field.name].addValidators([Validators.minLength(1), Validators.maxLength(30)]);
      }
    }
      // this.formData.controls.dedicatedSchema.patchValue('someValue');
      // console.log(this.formData);
      this.dynamicFormService.formDataSubject.subscribe(data=>{
        this.formData = data;
        if(this.field.conditional) {
        if(this.group.value[this.field.conditional.checkControlName] === this.field.conditional.compairControlNameValue) {
          this.group.controls[this.field.name].addValidators(Validators.required);
          this.group.controls[this.field.name].updateValueAndValidity();
        } else {
          this.group.controls[this.field.name].clearValidators();
          this.group.controls[this.field.name].patchValue(null);
          this.group.controls[this.field.name].updateValueAndValidity();
        }
      }
    })
}


//   public ngDoCheck(): void {


// }

  /**
   * Create Control to store the countrycode value
   */
   createCountryCodeControl(fieldName: any) {
    if (this.field && this.field.countryCodeRequired) {
      if (this.group.get(fieldName + 'countryCode')) {
        this.group.patchValue({ [fieldName + 'countryCode']: this.field.defaultCountryCode.code });
      } else {
        this.group.addControl(fieldName + 'countryCode', this.fb.control(this.field.defaultCountryCode.code));
      }
    }
  }

  /**
   * This method is to maintain the Format for phone number (### ### ####)
   */
  phoneNumberFormat(fieldName: string) {
    if (this.field && this.field.countryCodeRequired) {
      let number: any = this.group.get(fieldName)?.value;
      number = number.replace(/\D/g, '').substring(0, 10);
      let size = number.length;
      if (size > 3) { number = number.slice(0, 3) + " " + number.slice(3) }
      if (size > 6) { number = number.slice(0, 7) + " " + number.slice(7) }
      this.group.patchValue({ [fieldName]: number });
    }
  }
}
