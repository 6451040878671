import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PopupComponent } from '@app/shared/components/popup/popup.component';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {
  data: any;

  constructor(private dialogRef: MatDialogRef<PopupComponent>) { }

  ok() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close();
  }

}
