export class ProgressBarSourceData {
  progressBar = [
    {
      type: 'Progress Bar',
      html: `<strong>40% Completed</strong>
            <lib-rspl-progress-bar
              id="progress-bar-project"
              [bufferValue]="10"
              barColor="green"
              [value]="40"
            >
            </lib-rspl-progress-bar>`,
      css: ``,
      ts: `/**
            * To hold initial progress bar value
            */
          @Input() bufferValue: number = 0;
          /**
           * To hold progress bar color
           */
          @Input() barColor: 'green' | 'red' | 'yellow' = 'yellow';
          /**
           * To hold mode of the progress bar
           */
          @Input() mode: ProgressBarMode = 'determinate'; //'determinate' | 'indeterminate' | 'buffer' | 'query'
          /**
           * To hold bar value
           */
          @Input() value: number = 9;`,
      json: ``,
    },
  ];
}
