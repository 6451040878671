import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routerObj } from './core/constants/router-constants';
import { LoadingService } from './shared/components/loader/loading.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title:string='five-day-admin';
  backBtnEnable: boolean = false;
  constructor(public loader: LoadingService, private router: Router) {
    if (this.router.url === '/' + routerObj.ADMIN + '/' + routerObj.ACCOUNTS.ACCOUNTS + '/' + routerObj.ACCOUNTS.TENANT_DETAILS) {
      this.backBtnEnable = true;
    } else {
      this.backBtnEnable = false;
    }
  }


}
