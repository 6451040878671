<div class="modal__inner">
  <h3>{{ 'auth.changePasswordPage.changePassword' | translate }}</h3>
  <form [formGroup]="changePasswordForm">
    <ul class="acct-details__list">
      <li>
        <small class="acct-details__label" id="label-current-password">{{ 'auth.changePasswordPage.currentPassword' | translate }}</small>
        <div class="acct-details__felem">
          <lib-rspl-textbox className="form-Field" id="current-password" type="password" name="password" label="Current Password"
            formControlName="currentPassword" [isLabelShow]="false" placeholder="Enter Current Password"
            [isRequired]="true" maxLength="16" [blockCopyPaste]="true">
          </lib-rspl-textbox>
        </div>
      </li>
      <li>
        <small class="acct-details__label" id="label-new-password">{{ 'auth.changePasswordPage.newPassword' | translate }}</small>
        <div class="acct-details__felem">
          <lib-rspl-textbox className="form-Field" id="new-password" label="New Password" type="password" name="password"
            (focusEvent)="focusPassword()" [isErrorShow]="false" placeholder="New Password" formControlName="password"
            [isLabelShow]="false" [isRequired]="true" maxLength="16" [blockCopyPaste]="true">
          </lib-rspl-textbox>
          <div class="pwd-hint" *ngIf="changePasswordForm.get('password')?.touched">
            <div class="pwd-hint__status pwd-hint__status--{{pswStatus}}"
              *ngIf="changePasswordForm.get('password')?.valid">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              {{pswStatus}}
            </div>
            <p *ngIf="changePasswordForm.get('password')?.touched && changePasswordForm.get('password')?.invalid">
              {{ 'auth.passwordPolicy.passwordShouldHave'
              | translate }}:</p>
            <ul *ngIf="changePasswordForm.get('password')?.invalid">
              <li [ngClass]="{active: matchPass('atleast 6')}"><i class="gg-check"></i>
                {{ 'auth.passwordPolicy.sixCharactersAtleast' | translate }}
              </li>
              <li [ngClass]="{active: matchPass('1 uppercase')}"><i class="gg-check"></i>
                {{ 'auth.passwordPolicy.oneUppercase' | translate }}</li>
              <li [ngClass]="{active: matchPass('1 lowercase')}" id="lowercase"><i class="gg-check"></i>
                {{ 'auth.passwordPolicy.oneLowerCase' | translate }}</li>
              <li [ngClass]="{active: matchPass('1 number')}"><i class="gg-check"></i>
                {{ 'auth.passwordPolicy.oneNumber' | translate }}</li>
              <li [ngClass]="{active: matchPass('1 special charecter')}"><i class="gg-check"></i>
                {{ 'auth.passwordPolicy.oneSpecialCharacter' | translate }}</li>
            </ul>
          </div>
        </div>
      </li>
      <li>
        <small class="acct-details__label" id="label-confirm-password">{{ 'auth.changePasswordPage.confirmPassword' | translate }}</small>
        <div class="acct-details__felem">
          <lib-rspl-textbox className="form-Field" id="confirm-password" type="password" name="confirmPassword"
            label="Confirm Password" formControlName="confirmPassword" [isLabelShow]="false"
            placeholder="Confirm New Password" [isRequired]="true" maxLength="16" [blockCopyPaste]="true">
          </lib-rspl-textbox>
        </div>
      </li>
    </ul>
  </form>
  <div class="btn-grp">
    <lib-rspl-button buttonType="stroked-button" (click)="closePopup()" color="primary"
      label="{{'auth.changePasswordPage.CancelButton' | translate}}" id="cancel-pass-btn"></lib-rspl-button>
    <lib-rspl-button buttonType="flat-button" color="primary" (click)="onSubmit()"
      label="{{'auth.changePasswordPage.changePasswordButton' | translate}}" id="change-pass-btn"></lib-rspl-button>

  </div>
  <a href="javascript:void(0)" (click)="closePopup()" class="modal__close" id="pop-close-btn">
    <lib-rspl-mat-icon [iconType]="'outlined'" [iconName]="'close'"></lib-rspl-mat-icon>
  </a>
</div>
