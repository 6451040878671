export class ProfileIconSourceData {
  profileIcon = [
    {
      type: 'Profile Icon',
      html: `<div>
                <h4>Small Profile Icon</h4>

                <lib-rspl-profile-icon
                  [src]="'assets/images/rishabh_logo.jpg'"
                  alt="test"
                  size="small"
                >
                </lib-rspl-profile-icon>
                <hr />
              </div>
              <div>
                <h4>Medium Profile Icon</h4>
                <lib-rspl-profile-icon
                  [src]="'assets/images/rishabh_logo.jpg'"
                  alt="test"
                  size="medium"
                >
                </lib-rspl-profile-icon>
                <hr />
              </div>
              <div>
                <h4>Large Profile Icon</h4>
                <lib-rspl-profile-icon
                  [src]="'assets/images/rishabh_logo.jpg'"
                  alt="test"
                  size="large"
                >
                </lib-rspl-profile-icon>
              </div>`,
      css: ``,
      ts: `/**
          * image path
          *
          * @type {string}
          * @memberof RsplProfileIconComponent
          */
      @Input() src: string | any;
      /**
       * alternative name for image
       *
       * @type {string}
       * @memberof RsplProfileIconComponent
       */
      @Input() alt: string | any;
      /**
       *can aaply stylesheet from parent
       *
       * @type {object}
       * @memberof RsplProfileIconComponent
       */
      @Input() styles: object | any;
      /**
       *size of profile icons
       *
       * @type {('small' | 'medium' | 'large')}
       * @memberof RsplProfileIconComponent
       */
      @Input() size: 'small' | 'medium' | 'large' = 'small';`,
      json: ``,
    },
  ];
}
