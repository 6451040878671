import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiComponentsRoutingModule } from './ui-components-routing.module';
import { UiComponentsComponent } from './ui-components.component';

import { HighlightOptions, HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { RadioSourceData } from './renderers/radio';
import { AccordianSourceData } from './renderers/accordian';
import { BadgeSourceData } from './renderers/badge';
import { ButtonSourceData } from './renderers/button';
import { CameraSourceData } from './renderers/camera';
import { CardsSourceData } from './renderers/cards';
import { CarosulSourceData } from './renderers/carosul';
import { ChartsSourceData } from './renderers/charts';
import { CheckboxSourceData } from './renderers/checkbox';
import { ChipSetSourceData } from './renderers/chipSet';
import { DatepickerSourceData } from './renderers/datepicker';
import { DraglistSourceData } from './renderers/draglist';
import { FabMenuSourceData } from './renderers/fabMenu';
import { HierarchySourceData } from './renderers/hierarchy';
import { IconSourceData } from './renderers/icon';
import { InputSourceData } from './renderers/input';
import { ListSourceData } from './renderers/list';
import { LoaderSourceData } from './renderers/loader';
import { MenuSourceData } from './renderers/menu';
import { MultiSelectDropDownSourceData } from './renderers/multiSelectDropDownSourceData';
import { NestedMenuSourceData } from './renderers/nestedMenu';
import { PopoverSourceData } from './renderers/popover';
import { PopupSourceData } from './renderers/popup';
import { ProfileIconSourceData } from './renderers/profileIcon';
import { ProgressBarSourceData } from './renderers/progressBar';
import { SelectChipMenuSourceData } from './renderers/selectChipMenu';
import { SingleSelectSourceData } from './renderers/singleSelectSourceData';
import { SlideToggleSourceData } from './renderers/slideToggle';
import { TabsSourceData } from './renderers/tabs';
import { TextareaSourceData } from './renderers/textarea';
import { RsplRadioListModule, RsplCheckboxGroupModule, RsplCheckboxModule, RsplMatIconModule, RsplDropdownSingleselectModule, RsplButtonModule, RsplTextboxModule, RsplDatePickerModule, RsplDragListModule, RsplHierarchyDropdownModule, RsplTabGroupModule, RsplMenuModule, RsplTextareaModule, RsplSelectableMenuChipModule, RsplAccordianModule, RsplPopoverModule, RsplPopupModule, RsplProgressBarModule, RsplProfileIconModule, RsplSidenavModule, RsplSlideToggleModule, RsplBadgeModule, RsplChipSetModule, RsplCardsModule, RsplNestedMenuModule, RsplLoaderModule, RsplListModule, RsplChartsModule } from '@rspl/angular-deps-library/dist/rspl-angular-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    UiComponentsComponent,
  ],
  imports: [
    CommonModule,
    UiComponentsRoutingModule,
   RsplRadioListModule,
   ReactiveFormsModule,
   MatExpansionModule,
   MatIconModule,
   HighlightModule,
   MatTabsModule,
   RsplCheckboxGroupModule,
   RsplCheckboxModule,
   RsplMatIconModule,
   RsplDropdownSingleselectModule,
   RsplButtonModule,
   RsplTextboxModule,
   RsplDatePickerModule,
   RsplDragListModule,
   RsplHierarchyDropdownModule,
   RsplTabGroupModule,
   RsplMenuModule,
   RsplTextareaModule,
   RsplSelectableMenuChipModule,
   RsplAccordianModule,
   RsplPopoverModule,
   RsplPopupModule,
   RsplProgressBarModule,
   RsplProfileIconModule,
   RsplSidenavModule,
   RsplSlideToggleModule,
   RsplBadgeModule,
   RsplChipSetModule,
   RsplCardsModule,
   RsplNestedMenuModule,
   RsplLoaderModule,
   RsplListModule,
   RsplChartsModule

  ],
  providers:[RadioSourceData,
    IconSourceData,
    CheckboxSourceData,
    MultiSelectDropDownSourceData,
    SingleSelectSourceData,
    ButtonSourceData,
    InputSourceData,
    DatepickerSourceData,
    DraglistSourceData,
    HierarchySourceData,
    TabsSourceData,
    TextareaSourceData,
    MenuSourceData,
    SelectChipMenuSourceData,
    AccordianSourceData,
    PopupSourceData,
    ProfileIconSourceData,
    ProgressBarSourceData,
    PopoverSourceData,
    SlideToggleSourceData,
    BadgeSourceData,
    ChipSetSourceData,
    CameraSourceData,
    CarosulSourceData,
    NestedMenuSourceData,
    LoaderSourceData,
    ListSourceData,
    FabMenuSourceData,
    CardsSourceData,
    ChartsSourceData,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: <HighlightOptions>{
        lineNumbers: true,
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        lineNumbersLoader: () => import('highlightjs-line-numbers.js'),
        themePath: 'node_modules/highlight.js/styles/androidstudio.css',
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml'),
        },
      },
    },]
})
export class UiComponentsModule { }
