import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { FieldConfig } from '@app/shared/module/dynamic-form/components/model/field.interface';

@Component({
  selector: 'app-f-dropdown-single-select',
  templateUrl: './f-dropdown-single-select.component.html',
  styleUrls: ['./f-dropdown-single-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'mfe-selectoption-wrap' },
    },
  ]
})
export class FBDropdownSingleSelectComponent {
  objectKeys = Object.keys;
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group: UntypedFormGroup;

  constructor() {


  }

}
