export class ChartsSourceData {
  charts = [
    {
      type: 'Donut Chart',
      html: ` <lib-rspl-chart-donut
      [data]="pieChartData"
      [chartConfiguration]="regularChart"
    ></lib-rspl-chart-donut>`,
      css: ``,
      ts: `/**
      * Holds chart configuration data which passes from parent component.
      */
    @Input() data: any;
    /**
     * Holds chart configuration data which passes from parent component.
     */
    @Input() chartConfiguration: DonutChartConfig | any;


      pieChartData = [

        {
          name: 'Overdue',
          value: 54,
          myValue: 2,
        },
        {
          name: 'Today',
          value: 147,
        },
        {
          name: 'Upcoming',
          value: 62,
          myValue: 1,
        },
        {
          name: 'No data set',
          value: 71,
          myValue: 2,
        },
      ];

      regularChart = {
        donut: true,
        colorScheme: { domain: ['#ff2e2e', '#00c2f9', '#ffc400', '#bcd0d6'] },
        gradient: true,
        legend: false,
        legendPosition: LegendPosition.Below,
        labels: false,
        tooltipDisabled: false,
        chartType: {
          type: 'regular',
          theaders: ['Tasks', 'My', 'All'],
        },
      };`,
      json: ` `,
    },
    {
      type: 'Line Chart',
      html: `  <lib-rspl-chart-line
      [lineGraphData]="lineGraphData"
    ></lib-rspl-chart-line>`,
      css: ``,
      ts: `
      @Input() lineGraphData: Array<any> = [];
      @Input() graphHeader: string = "Chart Graph";

      lineGraphData = [
        {
          name: 'Karthikeyan',
          series: [
            {
              name: 'Jan',
              value: '1000',
            },
            {
              name: 'Feb',
              value: '500',
            },
            {
              name: 'Mar',
              value: '1500',
            },
            {
              name: 'Apr',
              value: '3000',
            },
            {
              name: 'May',
              value: '2000',
            },
            {
              name: 'Jun',
              value: '5000',
            },

          ],
        },
      ];

      `,
      json: ` `,
    }
  ];
}
