import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routerObj } from '../../core/constants/router-constants';
import { UiComponentsComponent } from './ui-components.component';

const routes: Routes = [
  { path: '', component: UiComponentsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UiComponentsRoutingModule {}
