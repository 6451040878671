import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
@Injectable()
export class DynamicFormService {
  tenantFormProfile: any;

  constructor() { }
  formDataSubject = new Subject<any>();
    formDataChangesToParent(value){
      this.formDataSubject.next(value);
  }
  setTenantProfile(value) {
    this.formDataSubject.subscribe((data) => {
      this.tenantFormProfile = data.value;
    });
  }
  getTenantProfile() {
    return this.tenantFormProfile;
  }
}