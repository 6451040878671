<div class="modal__inner center">
  <h2>{{data?.Heading}}</h2>
  <p>{{data?.subHeadng}}</p>
  <div class="modal__inner-btns">
    <lib-rspl-button *ngIf="data?.cancelButtonLabel" buttonType="stroked-button" color="primary"
      label="{{data?.cancelButtonLabel}}" (buttonClick)="closePopup(data?.cancelButtonLabel)"></lib-rspl-button>
    <lib-rspl-button *ngIf="data?.actionButtonLabel" buttonType="flat-button" color="primary"
      label="{{data?.actionButtonLabel}}" (buttonClick)="closePopup(data?.actionButtonLabel)"></lib-rspl-button>
  </div>
  <a href="javascript:void(0)" (click)="dialogRef.close()" class="modal__close">
    <lib-rspl-mat-icon [iconType]="'outlined'" [iconName]="'close'"></lib-rspl-mat-icon>
  </a>
</div>