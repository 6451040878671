export class DraglistSourceData {
  draglist = [
    {
      type: 'Checkbox Group',
      html: `  <lib-rspl-drag-list
      [list]="dragList"
      [type]="'projectDesign'"
      ></lib-rspl-drag-list>`,

      ts: `import { customizeListItem } from '@rspl/       angular-deps-library/dist/rspl-angular-lib/model/    customize-list-item.model';
            import uiComponentsData from './json/ui-components.json'; //Json path is only for demo purpose. It may vary depending on your reqirement

            /**
             * To hold view data
             */
            @Input() view: View[] | any = [];
            /**
             * To hold customize data
             */
            @Input() list: customizeListItem[] | any;
            /**
             * To hold the screen name
             */
            @Input() type: string | any;

            @Output() viewClick = new EventEmitter();

            /**
            * Drag list data
             */
             dragList : customizeListItem[] | any = uiComponentsData.uiComponent.dragList; //data calling will be different as per user requirment`,
      json: `"dragList":[
        {
           "id":1,
           "name":"Design",
           "color":"#12db0b",
           "showActions":false,
           "dragDisabled":false,
           "dragPlacement":"left-outside",
           "menuIcon":"more_vert",
           "actionList":{
              "color":true,
              "menu":true
           },
           "menuOptions":[
              {
                 "id":1,
                 "label":"Delete"
              }
           ]
        },
        {
           "id":2,
           "name":"Design",
           "color":"#12db0b",
           "showActions":false,
           "dragDisabled":false,
           "dragPlacement":"left-outside",
           "menuIcon":"more_vert",
           "actionList":{
              "color":true,
              "menu":true
           },
           "menuOptions":[
              {
                 "id":1,
                 "label":"Delete"
              }
           ]
        },
        {
           "id":3,
           "name":"Design",
           "color":"#12db0b",
           "showActions":false,
           "dragDisabled":false,
           "dragPlacement":"left-outside",
           "menuIcon":"more_vert",
           "actionList":{
              "color":true,
              "menu":true
           },
           "menuOptions":[
              {
                 "id":1,
                 "label":"Delete"
              }
           ]
        }
     ];`,
    },
  ];
}
