<div class="modal__inner center">
  <div class="intro-page">
    <h2>{{ 'auth.resetPasswordPage.resetPassword' | translate }}</h2>
    <img src="https://via.placeholder.com/300x150" class="switch-image" alt="Switch Device illustration">
    <p>{{ 'auth.switchDevice.warningMsg' | translate }}</p>
  </div>
  <div class="modal__inner-btns">
    <lib-rspl-button buttonType="stroked-button" color="primary" label="" >
    </lib-rspl-button>
    <lib-rspl-button buttonType="flat-button" color="primary" label="{{ 'auth.switchDevice.switchNow' | translate }}" >
    </lib-rspl-button>
  </div>
  <a href="javascript:void(0)" (click)="closePopup()" class="modal__close">
    <lib-rspl-mat-icon [iconType]="'outlined'" [iconName]="'close'"></lib-rspl-mat-icon>
  </a>
</div>
