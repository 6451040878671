import { Injectable } from '@angular/core';
import { NgxIndexedDBService, ObjectStoreMeta } from 'ngx-indexed-db';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndexdbService {

  constructor(private dbService: NgxIndexedDBService) { }
 /**
  * add data into indexDB
  *
  * @param storeName
  * @param record
  * @returns
  */
  add(storeName: string, record: any) {
    return this.dbService.add(storeName, record).pipe(
      catchError(this.handleError)
    );
  }
  /**
   *get multiple record from indexDB
   *
   * @param storeName
   * @param index
   * @returns
   */
  bulkGet(storeName: string, index: string[] | number[]): void {
    return this.dbService.bulkGet(storeName, index).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * update indexDB record
   *
   * @param storeName
   * @param record
   * @returns
   */
  update(storeName: string, record: any) {
    return this.dbService.update(storeName, record).pipe(
      catchError(this.handleError)
    );
  }
 /**
  * delete indexDB record by passing key
  *
  * @param storeName
  * @param key
  * @returns
  */
  delete(storeName: string, key: string | number) {
    return this.dbService.delete(storeName, key).pipe(
      catchError(this.handleError)
    );
  }
 /**
  * clean specific store from indexDB
  *
  * @param storeName
  * @returns
  */
  clean(storeName: string) {
    return this.dbService.clear(storeName).pipe(
      catchError(this.handleError)
    );
  }
 /**
  * no of records available in store
  *
  * @param storeName
  * @returns
  */
  count(storeName: string) {
    return this.dbService.count(storeName).pipe(
      catchError(this.handleError)
    );
  }
 /**
  * no of records related to specific store
  *
  * @param storeName
  * @param key
  * @returns
  */
  countByIndex(storeName: string, key: string) {
    return this.dbService.countByIndex(storeName, key).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * delete multiple records
   *
   * @param storeName
   * @param key
   * @returns
   */
  bulkDelete(storeName: string, key: string[] | number[]) {
    return this.dbService.bulkDelete(storeName, key).pipe(
      catchError(this.handleError)
    );
  }
 /**
  * delete store
  *
  * @param storeName
  * @returns
  */
  deleteStore(storeName: string) {
    return this.dbService.deleteObjectStore(storeName).pipe(
      catchError(this.handleError)
    );
  }
 /**
  * create store by passing store schema
  *
  * @param storeSchemaData
  * @returns
  */
  createStore(storeSchemaData: ObjectStoreMeta): void {
    return this.dbService.createObjectStore(storeSchemaData);
  }
/**
 * get all records from store
 *
 * @param storeName
 * @returns
 */
  getAll(storeName: string) {
    return this.dbService.getAll(storeName).pipe(
      catchError(this.handleError)
    );
  }
 /**
  * get record from specific store matches with the specific key
  *
  * @param storeName
  * @param key
  * @param value
  * @returns
  */
  getByKey(storeName: string, key: string, value: string | number) {
    return this.dbService.getByIndex(storeName, key, value).pipe(
      catchError(this.handleError)
    );
  }
  /**
    * method for handling common api error, it accept 1 params 1. error object
    *
    *
    * @param error
    * @returns
    */
  handleError(error: ErrorEvent) {
    let errorData: object | string = '';
    if (error && error.error) {
      errorData = error.error;
    }
    else {
      errorData = error
    }
    return throwError(errorData);
  }
}

