import { Component, Input, Self, Optional } from '@angular/core';
import { ControlValueAccessor, FormGroup, NgControl } from '@angular/forms';

//typecasting purpose
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { FieldConfig } from '../../model/field.interface';
@Component({
  selector: 'app-f-datepicker',
  templateUrl: './f-datepicker.component.html',
  styleUrls: ['./f-datepicker.component.scss'],
})
export class FBDatepickerComponent {
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig = {} as FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group?: FormGroup;

  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() placeholder: string = '';
  value: Date = new Date();
  constructor() { }
}
