export class DatepickerSourceData {
  datepicker = [
    {
      type: 'Datepicker Group',
      html: ` <form [formGroup]="viewForm">
            <lib-rspl-date-picker
              id="datepicker"
              formControlName="datepicker"
            ></lib-rspl-date-picker>
          </form>`,

      ts: `import moment from 'moment';`,
    },
  ];
}
