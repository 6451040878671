import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private saveStatus = new BehaviorSubject<any>(null);
  public isAccountProfileFormValid: boolean;
  public EditTenantId: string = '';
  public tanentPackageData
  public createPackagePayload = {};
  /**
   * createAccountsTenantPayload holds the form data of tenant creation form
   */
  public createAccountsTenantPayload = {};
  /**
   * createAccountsTenantController holds the form controller of tenant creation form
   */
  public createAccountsTenantController: any = {};

  constructor() { }

  // Component Communication - starts

  /**
   * For storing the message in  the subject variable
   * @param subjectName
   * @param message
   */
  setMessage(subjectName: string, message?: any) {
    (this as any)[subjectName]?.next(message);
  }

  /**
   * for retriving the message from the subject variable
   * @param subjectName
   * @returns
   */
  getMessage(subjectName: string): Observable<any> {
    return (this as any)[subjectName].asObservable();
  }
  // Component Communication - ends
}
