import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from './../../../../core/services/token.service';
import { FunctionalPopupComponent } from './../../../../modules/shared/components/functional-popup/functional-popup.component';
import { ComponentModel } from './../../../../shared/model/component.model';
import { CommonService } from './../../../../shared/services/common.service';
import { PopupService } from './../../../../shared/services/popup/popup.service';
import { LoginService } from '../../services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { routerObj } from './../../../../core/constants/router-constants';
import { distinctUntilChanged } from 'rxjs';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [
    '../reset-password/reset-password.component.scss',
    '../login-page/login-page.component.scss',
  ],
})
export class ResetPasswordComponent implements OnInit {
  /**
   *reset password form declaration
   *
   * @type {FormGroup}
   * @memberof ResetPasswordComponent
   */
  passwordResetForm: UntypedFormGroup;
  /**
   *set saveNewPassword button to full width
   *
   * @memberof ResetPasswordComponent
   */
  buttonWidth = { width: '100%' }
  /**
   *password validation , it changes as per password validation status
   *
   * @type {('Low' | 'Fair' | 'Good' | 'Excellent')}
   * @memberof ResetPasswordComponent
   */
  pswStatus: 'Low' | 'Fair' | 'Good' | 'Excellent' = 'Low';
  /**
*set popup data using object it contains component that wnat to render in popup , heading , subHeadng , buttonlabels
*
* @type {ComponentModel}
* @memberof ResetPasswordComponent
*/
  popupData: ComponentModel =
    {
      componentName: FunctionalPopupComponent,
      data: { Heading: 'New Password Set Successfully', subHeadng: 'Your new password is saved successfully. You can now login using your new password', actionButtonLabel: 'Go To Login' }
    };
  /**
   *to check wheather user focused on password field or not
   *
   * @memberof ResetPasswordComponent
   */
  @ViewChild('confirmPassword', { read: ElementRef }) textboxRef: ElementRef;
  constructor(private renderer: Renderer2, private popupService: PopupService, private fb: UntypedFormBuilder, public LoginService: LoginService, private tokenStorage: TokenStorageService,
    private router: Router, private route: ActivatedRoute, private commonService: CommonService, private translate: TranslateService) {
    this.passwordResetForm = this.fb.group({
      email: [this.route.snapshot.paramMap.get('emailId') ? this.route.snapshot.paramMap.get('emailId') : ''],
      password: ['', [Validators.required,
      Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,}$')
      ]
      ],
      confirmPassword: ['', [Validators.required, this.passwordConfirmMatch]],
      code: [this.route.snapshot.paramMap.get('token') ? this.route.snapshot.paramMap.get('token') : ''],
    },
    )
    !(this.passwordResetForm.value.email || this.passwordResetForm.value.code) ?
      this.router.navigateByUrl(
        '/' + routerObj.AUTH.AUTH + '/' + routerObj.AUTH.LINK_EXPIRED
      ) : this.validateCode()
  }
  /**
   * on valueChanges of password field it set pswStatus as per validation status
   */
  ngOnInit(): void {
    this.passwordResetForm.get('confirmPassword')?.statusChanges.pipe(distinctUntilChanged()).subscribe((data) => {
      (data === 'INVALID') && this.renderer.addClass(this.textboxRef.nativeElement.childNodes[0], 'mat-form-field-invalid');
      (data === 'VALID') && this.renderer.removeClass(this.textboxRef.nativeElement.childNodes[0], 'mat-form-field-invalid');
    });
    this.passwordResetForm.get('password')?.valueChanges.subscribe((data) => {
      if (this.passwordResetForm.get('confirmPassword')?.valid && this.passwordResetForm.get('confirmPassword')?.value != data) {
        this.setConfirmPasswordError({ invalid: true });
      }
      if (this.passwordResetForm.get('confirmPassword')?.value === data && this.passwordResetForm.get('confirmPassword')?.dirty) {
        this.setConfirmPasswordError(null)
      }
      if (this.passwordResetForm.get('password')?.valid) {
        if (data.length == 6)
          this.pswStatus = 'Low';
        if (data.match('^(?=.*[a-z])(?=.*[A-Z]).{8,}$'))
          this.pswStatus = 'Fair';
        if (data.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$') || data.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_=+-]).{8,}$')
          || data.match('^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$') || data.match('^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$'))
          this.pswStatus = 'Good';
        if (data.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{10,}$'))
          this.pswStatus = 'Excellent';
      }
    })
  }
  /**
 * set confirm password error and update touch status
 *
 * @param error
 */
  setConfirmPasswordError(error) {
    this.passwordResetForm.get('confirmPassword')?.setErrors(error);
    this.passwordResetForm.get('confirmPassword')?.markAllAsTouched();
    this.passwordResetForm.get('confirmPassword')?.updateValueAndValidity();
  }
  /**
   * on click on saveNewPassword button hits this method and check is password valid or not (if password valid then changes password successfully popup appears)
   * @returns
   */
  onSubmit() {
    console.log(this.passwordResetForm, "this.passwordResetForm")
    if (this.passwordResetForm.invalid) {
      return;
    }
    this.LoginService.signOut();
    this.LoginService.resetPassword(this.passwordResetForm.value).subscribe((data: any) => {
      this.openPopup();
    })
  }
  /**
   * it accept key as a param , on basis of that check pattern for perticular test case , if it satisfy then green tick to perticular validation
   *
   * @param key
   * @returns
   */
  matchPass(key: string) {
    let passwordVal: string = this.passwordResetForm.get('password')?.value;
    switch (key) {
      case 'atleast 6':
        return /^(?=.*).{6,}$/.test(
          passwordVal
        );
        break;
      case '1 lowercase':
        return /^(.*[a-z].*)$/.test(passwordVal);
        break;
      case '1 uppercase':
        return /^(.*[A-Z].*)$/.test(passwordVal);
        break;
      case '1 number':
        return /^(.*[0-9].*)$/.test(passwordVal);
        break;
      case '1 special charecter':
        return /^(.*[!@#$%^&*_=+-].*)$/.test(passwordVal);
        break;
      default:
        return false;
        break;
    }
  }
  /**
   * This method is to open the popup
   * and after closing will return the submitted value
   */
  openPopup() {
    this.commonService.openAlertPopup(
      this.translate.instant(
        'auth.resetPasswordPage.passwordSetSuccessfullyHdg'
      ),
      this.translate.instant(
        'auth.resetPasswordPage.passwordSetSuccessfullyMsg'
      ),
      this.translate.instant('auth.resetPasswordPage.goToLogin')
    );
    this.popupService.dialogRef.afterClosed().subscribe((response: any) => {
      response === 'Go To Login' &&
        this.router.navigateByUrl(
          '/' + routerObj.AUTH.AUTH + '/' + routerObj.AUTH.LOGIN
        );
    });
  }
  validateCode() {
    this.LoginService?.validateCode(this.passwordResetForm?.value?.email, this.passwordResetForm?.value?.code)?.subscribe(() => {
    }, () => {
      this.router.navigateByUrl(
        '/' + routerObj.AUTH.AUTH + '/' + routerObj.AUTH.LINK_EXPIRED
      )
    })
  }
  focusPassword() {
    this.passwordResetForm.controls['password'].markAsTouched();
  }
  blurConfirmPassword() {
    setTimeout(() => {
      this.passwordResetForm.get('confirmPassword')?.valid && this.renderer.removeClass(this.textboxRef.nativeElement.childNodes[0], 'mat-form-field-invalid');
    }, 10);
  }
  /**
 * custom validation for confirm password , once confirm password match with password then form valid
 *
 * @param c
 * @returns
 */
  passwordConfirmMatch(c: AbstractControl) {
    if (c.value !== c.parent?.get('password')?.value) {
      return { invalid: true };
    }
    return null;
  }
}
