import { Pipe, PipeTransform } from '@angular/core';
import { PricePlan } from '@app/modules/accounts/model/account.model';

@Pipe({
  name: 'priceFromPriceplan'
})
export class PriceFromPriceplanPipe implements PipeTransform {

  transform(plan: PricePlan, ...args: unknown[]): string {
    if(!plan.currencyCode || plan.price === undefined || !plan.pricingModel)  {
      return 'N/A'
    }
    return (plan.pricingModel !== 'FLAT_FEE' && plan.pricingModel !== 'PER_UNIT')
        ? `Starts at ${this.currentcyConverter(plan.price)} ${plan.currencyCode}` : `${this.currentcyConverter(plan.price)} ${plan.currencyCode}`
  }

  currentcyConverter(num: number) {
    let numAsString = num.toString();
    let characters = numAsString.substring(0, (numAsString.length - 2)).split("").reverse();
    let parts: string[] = [];
    for (let i = 0; i < characters.length; i += 3) {
      let part = characters.slice(i, i + 3).reverse().join("");
      parts.unshift(part);
    }
    return `${parts.join(",")}.${numAsString.substring(numAsString.length - 2, (numAsString.length))}`;
  }

}
