import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../../model/field.interface';

@Component({
  selector: 'app-picture-upload',
  templateUrl: './picture-upload.component.html',
  styleUrls: ['./picture-upload.component.scss'],
})
export class FBPictureUploadComponent {
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group: UntypedFormGroup;
  imageSrc: string;
  constructor() { }

  /**
   * onfileupload accepts $event and will take the single file param and returns the imageSrc and reader.result
   * @param event
   */
  onfileUplaod(event) {
    const reader = new FileReader();
    if (event && event?.target?.files && event?.target?.files.length) {
      const [file] = event?.target?.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imageSrc = reader?.result as string;
        this.group.value.companyLogoId = reader?.result;
        // console.log(1, 2, 3, this.group.value);
      };
    }
  }
}
