import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TANENT_KEY, USER_KEY, USER_STORE, USER_STORE_KEY } from '@app/core/constants/index-DB.constants';
import { ApiHttpService } from '@app/core/services/api-http.service';
import { localStorageService } from '@app/core/services/localStorage.service';
import { IndexdbService } from '@app/core/services/indexdb.service';
import { StorageService } from '@app/core/services/storage.service';
import { TokenStorageService } from '@app/core/services/token.service';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { CommonService } from '@app/shared/services/common.service';
import { AuthenticationAPIConstants } from '../api-constants/authentication-api-constants';
import { changePasswordRequest, loginI, loginResponseI, resetPasswordRequest } from '../model/login.model';
import { BehaviorSubject, finalize, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  gettingRefreshToken: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  gettingRefreshToken$ = this.gettingRefreshToken.asObservable();

  constructor(private indexdbService: IndexdbService, private ApiHttpService: ApiHttpService, private tokenService: TokenStorageService,
    private url: AuthenticationAPIConstants, private CommonService: CommonService, private sharedService: SharedService, private localStorage: localStorageService,
    private dataStorage: StorageService) { }
  /**
   * login api
   * @param req
   * @returns
   */
  login(req: loginI) {
    const httpOptionsLogin = {
      headers: new HttpHeaders({
        "response-data-required": 'true',
        'Access-Control-Expose-Headers': 'Content-Encoding'
      }), 'Content-Type': 'application/x-www-form-urlencoded', observe: 'response' as 'response',

    };
    return this.ApiHttpService.post(this.url.endPoints['auth'].login, req, httpOptionsLogin)
  }
  forgotPassword(email: string) {
    return this.ApiHttpService.get(this.url.endPoints['auth'].forgotPassword(email))
  }
  validateCode(email: string, code: string) {
    return this.ApiHttpService.get(this.url.endPoints['auth'].ValidateCode(email, code))
  }
  resetPassword(req: resetPasswordRequest) {
    return this.ApiHttpService.post(this.url.endPoints['auth'].resetPassword, req)
  }
  changePassword(req: changePasswordRequest) {
    return this.ApiHttpService.post(this.url.endPoints['auth'].changePassword, req)
  }
  logout() {
    let refreshToken = this.dataStorage && this.dataStorage.authUser && this.dataStorage.authUser.token.refresh_token
      ? this.dataStorage.authUser.token.refresh_token : '';

    const httpOptionsLogout = {
      headers: new HttpHeaders({
        'refresh-token': refreshToken ? refreshToken : '',
      })
    };
    return this.ApiHttpService.post(this.url.endPoints['auth'].logout, '', httpOptionsLogout)
  }
  /**
   * logout user , clear all user sessions
   */
  signOut() {
    let loginData = this.dataStorage.info;
    this.tokenService.token_expiry_time = undefined;
    this.tokenService.saveToken('');
    if (loginData && loginData.rememberMe) {
      this.indexdbService.bulkDelete(USER_STORE, [USER_KEY, TANENT_KEY, USER_STORE_KEY]).subscribe(() => {
      });
    }
    else {
      this.indexdbService.clean(USER_STORE).subscribe(() => {
      });
    }
    this.localStorage.deleteLocalStorage(this.tokenService.TOKEN_KEY);
    this.sharedService.setMessage('saveStatus', null);
  }
  /**
   * api for get schema defination
   *
   * @returns
   */
  getSchemas() {
    return this.ApiHttpService.get(this.url.endPoints["schemas"])
  }

  /**
   * this method calls refresh token api and then update the current auth user info with updated data
   *
   * @returns
   */
  refreshToken() {
    let refreshToken = this.dataStorage?.authUser?.token?.refresh_token;
    this.gettingRefreshToken.next(true);
    let headers = new HttpHeaders();
    return this.ApiHttpService.post(this.url.endPoints['auth'].refreshToken, {
      refreshToken: refreshToken,
    }, { headers }).pipe(
      tap((refreshData: any) => {
        if (refreshData && refreshData.data) {
          this.dataStorage.authUser = {
            ...this.dataStorage.authUser,
            token: refreshData.data.token
          };
          this.tokenService.saveToken(refreshData.data.token.access_token);
        }
      }),
      finalize(() => {
        this.gettingRefreshToken.next(false);
      }));
  }
}
