import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

/**
 * ngx-translate HttpLoaderFactory() method expects and input and returns the path of i18n file to load the static strings based on selected Language code.
 * @param http 
 * @returns 
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "../../../../assets/i18n/", ".json");
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ], exports: [
    CommonModule,
    TranslateModule
  ]
})
export class NgxTranslateModule { }
