<form class="d-flex dynamic-form-tag fs-14" [formGroup]="group" *ngIf="field">
  <mat-label class="fs-14 mb-0 mt-1a col-3 o-60 pr-1">{{field.label}}<span *ngIf="field.required">*</span></mat-label>
  <mat-form-field class="dynamic-form__field fs-14 col-9" appearance="fill" [id]="field.id">
    <mat-label class="fs-14 mb-0 mt-1a col-3 o-60 pr-1" *ngIf="field.placeHolder">{{field.placeHolder}}</mat-label>
    <mat-select [disabled]="!!field.disabled" formControlName="{{field.name}}" placeholder="{{field.placeHolder}}">
      <mat-option *ngFor="let key of objectKeys(field.options)" [value]="key">{{field.options[key]}}</mat-option>
    </mat-select>
    <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
        <mat-error *ngIf="group.get(field.name)?.hasError(validation.name)">{{validation.message}}</mat-error>
    </ng-container>
  </mat-form-field>
</form>
