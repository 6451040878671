import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationInterceptor } from './core/interceptor/custom-http-interceptor';
import { PagesModule } from './pages/pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthCanLoad } from './core/guards/authCanLoad.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutModule } from './core/components/layout/layout.module';
import { NgxTranslateModule } from './shared/module/ngx-translate/ngx-translate.module';
import { ToastrModule } from 'ngx-toastr';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { TokenStorageService } from './core/services/token.service';
import { IndexdbService } from './core/services/indexdb.service';
import { STORE_CONFIG, STORE_SCHEMA, USER_STORE } from './core/constants/index-DB.constants';
import { CommonService } from './shared/services/common.service';
import { PopupService } from 'ag-grid-community';
import { ApiHttpService } from './core/services/api-http.service';
import { SnackBarService } from './shared/services/snack-bar/snack-bar.service';
import { ToastrMessageService } from './shared/services/toastr/toastr.service';
import { StorageService } from './core/services/storage.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const dbConfig: DBConfig = {
  name: 'MyDb',
  version: 1,
  objectStoresMeta: [
    {
      store: USER_STORE,
      storeConfig: STORE_CONFIG,
      storeSchema: STORE_SCHEMA,
    }
  ],
};

import { RsplCheckboxModule, RsplLoaderModule, RsplTextboxModule } from '@rspl/angular-deps-library/dist/rspl-angular-lib';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { localStorageService } from './core/services/localStorage.service';
import { UiComponentsModule } from './modules/ui-components/ui-components.module';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    PagesModule,
    LayoutModule,
    AuthenticationModule,
    BrowserAnimationsModule,
    NgxTranslateModule,
    ToastrModule.forRoot(),
    NgxIndexedDBModule.forRoot(dbConfig),
    ToastrModule.forRoot(),
    // IconModule,
    RsplTextboxModule,
    RsplCheckboxModule,
    RsplLoaderModule,
    UiComponentsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    AuthCanLoad, AuthGuard, TokenStorageService, IndexdbService, CommonService, PopupService, ApiHttpService, SnackBarService, ToastrMessageService, localStorageService, StorageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
