export class ListSourceData {
  list = [
    {
      type: 'List',
      html: ` <lib-rspl-list
      [listData]="listData"
      [listLabel]="'Demo List'"
    ></lib-rspl-list>`,
      css: ``,
      ts: `import { list } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/list.model';
      import uiComponentsData from './json/ui-components.json';

      /**
   *list Data
   *@example
   * <app-list listLabel="New Tenants" [listData]="listData"></app-list>
   *
   *
   * @type {list[]}
   * @memberof ListComponent
   */
   @Input() listData: list[] | any;
   /**
    *label of list Data
    *
    * @type {string}
    * @memberof ListComponent
    */
   @Input() listLabel: string | any;
   /**
    *no of entries visible initially
    *
    * @type {number}
    * @memberof ListComponent
    */
   @Input() noEntriesShow: number = 2;
   /**
    * data view status which is control by button
    *
    * @type {('View All' | 'Minimize')}
    * @memberof ListComponent
    */
   dataViewStatus: 'View All' | 'Minimize' = 'View All';

      listData:list[]=  uiComponentsData.uiComponent.list;`,
      json: ` "list":[
        {
          "label":"List 1",
          "subLabel":"Sub label 1"
        },
        {
          "label":"List 2",
          "subLabel":"Sub label 2"
        },
        {
          "label":"List 3",
          "subLabel":"Sub label 3"
        }
      ]`,
    },
  ];
}
