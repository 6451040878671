<p>
    {{data?.content}}
</p>
<div style="width: 100%;display:flex;">
    <div style="width: 50%;" *ngIf="data?.closeButtonText">
        <lib-rspl-button buttonType="stroked-button" color="primary" label="{{data?.closeButtonText}}"
            (buttonClick)="close()">
        </lib-rspl-button>
    </div>
    <div style="width: 50%;" *ngIf="data?.okButtonText">
        <lib-rspl-button buttonType="stroked-button" color="primary" label="{{data?.okButtonText}}" (buttonClick)="ok()">
        </lib-rspl-button>
    </div>
</div>