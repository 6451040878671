export class CardsSourceData {
  cards = [
    {
      type: 'Chip Card',
      html: `<div>
      <h4>Chip Card</h4>
      <lib-rspl-chip-card [cardContent]="cardContent"></lib-rspl-chip-card>
      <hr>
    </div>`,
      css: ``,
      ts: ` /**
      *card label that we can usad as a title
      *
      * @type {Array<any>}
      * @memberof RsplChipCardComponent
      */
  @Input() cardContent: Array<any> = [];`,
      json: ` `,
    },
    {
      type: 'Layout Card',
      html: `  <div>
      <h4>Layout Card</h4>
      <lib-rspl-layout-card [cardLabel]="'Layout Card'">
        <h1>Card 1</h1>
        <h2>Card 2</h2>
      </lib-rspl-layout-card>
    </div>`,
      css: ``,
      ts: ` /**
      *heading for card
      *
      * @type {string}
      * @memberof RsplLayoutCardComponent
      */
      @Input() cardLabel: string = '';
      /**
       *class for manage look and feel of card
       *
       * @type {string}
       * @memberof RsplLayoutCardComponent
       */
      @Input() class: string = 'main-div';`,
      json: ` `,
    },
  ];
}
