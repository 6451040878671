<div class="dynamic-form" *ngIf="fields && dynamicForm" [formGroup]="dynamicForm" id="formidset-0002">
  <div class="dynamic-form__row pb-3 border-bottom mb-3" *ngFor="let item of groupFormBy">
    <h3 class="field-header-label fs-20 text-bold hide-if-empty">{{ item }}</h3>
    <div class="dynamic-form__fields-wrap d-flex flex-wrap ml-n2 mr-n2">
      <div *ngFor="let field of fields;" class="hide-if-empty mb-2a col-6 pl-2 pr-7 dynamic-form__fields-item">
        <div *ngIf="item === field?.groupBy" [id]="'form-'+ field?.fieldId">
          <ng-container dynamicField [field]="field" [group]="dynamicForm"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <p>{{ this.dynamicForm.value | json }}</p> -->
