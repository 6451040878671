import { Component, EventEmitter, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../../model/field.interface';

@Component({
  selector: 'app-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss']
})
export class FBButtonToggleComponent {
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group: UntypedFormGroup;
  constructor() { }

  /**
   * onButtonSelect function call it will list down the selected options from the group of buttons and move them in to an string array
   * @param fieldName 
   */
  onButtonSelect(fieldName: string) {  }
}
@Pipe({ name: 'isValueChecked' })
export class IsValueCheckedPipe implements PipeTransform {
  transform(option: any, selectedValue: any) {
    if (Array.isArray(selectedValue)) {
      return selectedValue.findIndex((val) => val.value ? val.value == option.value : val == option) > -1;
    } else {
      return false;
    }
  }
}