import { Injectable } from '@angular/core';
import { loginI, loginResponseI } from '@app/modules/authentication/model/login.model';
import { CommonService } from '@app/shared/services/common.service';
import { firstValueFrom } from 'rxjs';
import { USER_KEY, USER_STORE } from '../constants/index-DB.constants';
import { IndexdbService } from './indexdb.service';
import { localStorageService } from './localStorage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  TOKEN_KEY = 'auth-token';
  REFRESHTOKEN_KEY = 'auth-refreshtoken';
  TANENT_KEY = 'tenant-id';
  USER_INFO_KEY = 'info';
  HEADER_LABEL = 'header-label';
   /**
   * buffer time in minutes to get new auth_token before current auth_token expires.
   * i.e. if value is 5, we will get new auth_token within 5 mins before current auth_token expires
   *
   * @type {number}
   * @memberof TokenStorageService
   */
  expiryBufferTime:number = 2;
  private _token = this.CommonService.decryptData(this.localStorage.getLocalStorage(this.TOKEN_KEY));
  constructor(private CommonService: CommonService, private localStorage: localStorageService, private indexdbService: IndexdbService) { }
  
  // it holds expiry time from access token
  private _token_expiry_time: number | undefined;
  get token_expiry_time() {
    if (!this._token_expiry_time && this.getToken()) {
      // this condition indicates we have access token but no expiry time.
      const decodedjwt = this.parseJwt(this._token)
      this._token_expiry_time = decodedjwt?.exp || 1;
    }
    return this._token_expiry_time ;
  }
  set token_expiry_time(value:number | undefined) {
    this._token_expiry_time = value;
  }

  saveRefreshToken(refreshToken: string) {
    this._token = refreshToken;
    this.localStorage.setLocalStorage(this.TOKEN_KEY, this.CommonService.encryptData(refreshToken));
  }

  getRefreshToken() {
    return this._token;
  }
  /**
   * save token in local storage , it takes token as a param
   * @param token
   */
  public saveToken(token: string): void {
    this._token = token;
    this.localStorage.setLocalStorage(this.TOKEN_KEY, this.CommonService.encryptData(token));
  }
  /**
   * get token from local storage
   * @returns
   */
  public getToken(): string | null {
    return this._token;
  }

   /**
   * this method decrpt the jwt token so we can exctact token related info like expiring time from it ..
   *
   * @param token
   * @returns
   */
   parseJwt(token:string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  /**
 * it calculate the remaining token expiry time
 *
 * @returns
 */
  istokenTimeExpired() {
    if (!this.token_expiry_time) {
      return false;
    }
    /**
     * timeDiff indicates time difference in minutes of when the token will expire with respect to current time.
     * i.e. timeDiff = 2 indicates current auth token will expire in 2 minutes.
     */
    let timeDiff: any = ((this.token_expiry_time * 1000) - (new Date().getTime())) / 60000;
    return timeDiff < this.expiryBufferTime;
  }
}