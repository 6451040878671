import { Injectable } from '@angular/core';

@Injectable()
export class AccountsAPIConstants {
  /**
   * endPoints variable holds the object with all the tenant related api url's
   */
  public endPoints: object;
  constructor() {
    this.endPoints = {
      accounts: {
        accountsList: `api/product/tenants`,
        packageList: `api/product/packages`,
        deleteTenant: `api/product/tenants/`,
        pricePlansByPackage: (packageId: string) => `api/product/packages/${packageId}/planPrice` ,
        signup: {
          questionnaire: `api/tenant/signup/schema`,
          registration : `api/security/registration`,
          update : `api/security/registration/update`,
        },
        tenantProfile: (uuid: number) => {
          return `api/product/tenants/${uuid}`;
        },
        tenantSubscription: (uuid: number) => {
          return `tenant/tenants/${uuid}/subscriptions`;
        },
        tenantHistroy: (uuid: number) => {
          return `tenant/tenants/${uuid}/subscriptions`;
        },
        tenantBillingSummary: (uuid: number) => {
          return `tenant/tenants/${uuid}/billingsummary`;
        },
        tenantBillingInvoices: (uuid: number) => {
          return `tenant/tenants/${uuid}/invoices`;
        },
        acgridFiltersData: (uuid: number) => {
          return `assets/json/accounts/grid-filters-list.json`;
        }
      },
    };
  }
}
