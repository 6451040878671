export class SlideToggleSourceData {
  slideToggle = [
    {
      type: 'Slide Toggle',
      html: `<lib-rspl-slide-toggle
      [slideToggleLabel]="'Toggle'"
    ></lib-rspl-slide-toggle>`,
      css: ``,
      ts: ` /**
      * Label for slidetoggle
      */
       @Input() slideToggleLabel: string = '';
       /**
       * Default value of the slidetoggle
       */
       @Input() slideToggleValue: any;
       /**
       * emits the updated toggle value
       */
       @Output() slideToggleChange = new EventEmitter<SlideToggleList>();`,
      json: ` `,
    },
  ];
}
