import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {
  demo = "demo"
  constructor(private snackBar: MatSnackBar) { }

  /**
 * This method is to open the snackbar with some message passed to this function as an argument and shows the 'Info, Success, Warning, Success' toastr messages.
 */
  openSnackBar(message: string, style: any, close: string = 'X') {
    this.snackBar.open(message, close, {
      duration: 5000,
      panelClass: ['default-snackbar', style + '-snackbar'],
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  /**
   *Samples:
   this.snackBarService.openSnackBar('Saved successfully!','success');
   this.snackBarService.openSnackBar('Server Error','error');
   this.snackBarService.openSnackBar('warning msg','warning');
   */
}
