import { Injectable } from '@angular/core';
import { PopupService } from './popup/popup.service';
import { ComponentModel } from '@app/shared/model/component.model';
import { FunctionalPopupComponent } from '@app/modules/shared/components/functional-popup/functional-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { ApiHttpService } from '../../core/services/api-http.service';
import { FieldConfig } from '../module/dynamic-form/components/model/field.interface';
import { Schema, SchemaAttribute } from '../model/schema.model';
import { GridOptionsData } from '../model/grid-filter.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  schemaData: any;
  popupData: ComponentModel = { componentName: FunctionalPopupComponent };

  constructor(private popupService: PopupService, private translate: TranslateService, private ApiHttpService: ApiHttpService) {
  }

  /*
    To get the string and convert into capitalize word with space in between
  */
  getStringCapitalizeWithSpace(string) {
    const cap = string.charAt(0).toUpperCase() + string.slice(1);
    return cap.replace(/([A-Z])/g, ' $1').trim();
  }

  /*
  * To construct the header based on the response
  * forCustomHeader - to check this header is for AG Grid or for custom Header
  */
  getHeaderKeysfromResponse(dataTableResponse: Array<object>, forCustomHeader?: string) {
    let headerKeysList = Object.keys(dataTableResponse[0]);
    headerKeysList = headerKeysList.map((key: string) => {
      return (!forCustomHeader) ? { field: key } : this.getStringCapitalizeWithSpace(key);
    });
    return headerKeysList;
  }
  TrackByFn(index: number) {
    return index;
  }
  /**
   * This method is to show the confirmation popup
   * @param heading
   * @param subHeadng
   * @param actionButtonLabel
   * @param cancelButtonLabel
   */
  openConfirmationPopup(heading: string, subHeadng: string, actionButtonLabel: string = this.translate.instant("general.yes"), cancelButtonLabel: string = this.translate.instant("general.no")) {
    this.popupData.data = { Heading: heading, subHeadng: subHeadng, actionButtonLabel: actionButtonLabel, cancelButtonLabel: cancelButtonLabel }
    this.popupService.openPopup(this.popupData);
  }

  /**
 * This method is to show the confirmation popup
 * @param heading
 * @param subHeadng
 * @param actionButtonLabel
 * @param cancelButtonLabel
 */
  openAlertPopup(heading: string, subHeadng: string, actionButtonLabel: string = this.translate.instant("general.ok")) {
    this.popupData.data = { Heading: heading, subHeadng: subHeadng, actionButtonLabel: actionButtonLabel }
    this.popupService.openPopup(this.popupData);
  }
  /**
   * for encrypt data
   *
   * @param data
   * @returns
   */
  encryptData(data) {
    return data ? btoa(data) : '';
  }
  /**
   * for decrypt data
   *
   * @param data
   * @returns
   */
  decryptData(data) {
    return data ? atob(data) : '';
  }
  /**
   * get data for specific "entity-name" from api schema
   *
   * @param entityName
   * @returns
   */
  getEntityFromSchema(entityName: string) {
    if (this.schemaData) {
      const findSchemna = this.schemaData.find((item) => item['entity-name'] === entityName).attributes;
      const filterSchemna = findSchemna.filter((attribute) => attribute.show)
      return filterSchemna;
    }
  }
  /**
   * get schema data from index DB and store it into global variable
   */
  setSchemaDataIntoVar(schema) {
    this.schemaData = schema;
  }
  /**
   * To get the Schema details based on schemaname
   *
   */
  getSchema(schemaName: any) {
    if (this.schemaData) {
      let schema: any = this.schemaData.filter(val => val['entity-name'] == schemaName)[0];
      return schema;
    } else {
      return null;
    }
  }
  /**
   * Cross cutting Data
   */
  getCrossCuttingData(filterResult) {
    let filterObj:FieldConfig[] = [];
    let sortObj = filterResult?.sort;
    let searchPlaceholdeArr = filterResult?.search.map(item => {return item.label});
    let groupByObj = filterResult?.groupBy;
    filterResult?.filter.forEach(element => {
      let item = Object.keys(element)[0];
      filterObj.push(
        {
          fieldType: 'buttonToggle',
          fieldId: item,
          cssClass: "field-css-class",
          defaultValue: [],
          label: element[item].groupLable,
          fieldName: item,
          placeHolder: item,
          options: element[item].options
        } as FieldConfig
      )
    });

    return {
      filterObj:filterObj,
      searchPlaceholdeArr: searchPlaceholdeArr,
      sortObj:sortObj,
      groupObj:groupByObj,
    }
  }
   /**
   * To get the All grid related options values
   *
   */
   getGridData(schema: Schema) {
    const gridOption = {
      filtersData: [],
      sortOptions: [],
      headersList: [],
      searchPlaceholder: '',
      defaultOrder: ''
    } as GridOptionsData;


    // find sort values
    schema['meta-data'].order.forEach((orderName) => {
      let orderSplit = orderName.split('.');
      let orderData = this.filterOrderData(schema['entity-name'], orderSplit);
      if (orderData) {
        gridOption.sortOptions.push({
          label: orderData.displayName,
          order: '',
          key: orderName,
          icon: orderData.icon
        });
      }
    });


    //filters
    schema['meta-data'].filter.forEach((filter) => {
      let filterSplit = filter.split('.');
      let filterData = this.filterOrderData(schema['entity-name'], filterSplit);
      if (filterData && gridOption.filtersData.findIndex(item => item.fieldName === filter) === -1) {
        let filterAttribute: FieldConfig = {
          fieldType: 'buttonToggle',
          fieldId: filter,
          cssClass: 'field-css-class',
          defaultValue: '',
          label: filterData.displayName,
          fieldName: filter,
          placeHolder: filterData.displayName,
          options: [],
        } as FieldConfig;
        if (filterData.values && filterData.type !== 'boolean') {
          let options = Object.keys(filterData.values).map(key => ({ value: key, label: filterData.values ? filterData.values[key] : ''}));
          filterAttribute.options = options;
        } else if (filterData.type === 'boolean') {
          filterAttribute.options = ['True', 'False'];
        }
        gridOption.filtersData.push(filterAttribute);
      }
    });

    schema.attributes.forEach((attribute) => {

      // compile headers
      if (attribute.show) {
        gridOption.headersList.push({
          field: attribute.name
        });
      }

    });

    // searchPlaceholder
    let searchDisplayName:string[] = [];
    schema['meta-data'].search.forEach((searchItem) => {
      let searchSplit = searchItem.split('.');
      let searchData = this.filterOrderData(schema['entity-name'], searchSplit);
      if (searchData) {
        searchDisplayName.push(searchData.displayName);
      }
    });
    gridOption.searchPlaceholder = searchDisplayName.join(', ');
    gridOption.defaultOrder = schema['meta-data']['default-order'];

    return gridOption;
  }
  /**
   * To get the selected grid Header Options
   *
   */
  getGridHeaders(event, gridParams, schema) {
    if (event.type !== 'close') {
      if (event.type === 'sort') {
        gridParams.gridParams.payload.orderBy = event.data?.key;
        gridParams.gridParams.payload.orderDirection = event.data.order ? event.data.order : 'DESC';
      } else if (event.type === "sortChanged") {
        const sortedColumn = event.columnApi.getColumnState().find((col) => Boolean(col.sort));
        gridParams.gridParams.payload.orderBy = sortedColumn?.colId;
        gridParams.gridParams.payload.orderDirection = sortedColumn?.sort.toUpperCase();
      } else if (event.type === 'search') {
        if (event.data === '') {
          delete gridParams.gridParams.payload['searchText'];
        } else {
          gridParams.gridParams.payload['searchText'] = event.data;
        }
      } else if (event.type === 'filter') {
        gridParams.gridParams.payload['filters'] = event.data;
      }
      return gridParams.gridParams;
    }
  }

  filterOrderData(schemaName:string, orderArray:string[], orderIndex = 0): SchemaAttribute {
    let filterAttribute= this.getSchema(schemaName)?.attributes.find((attribute)=>attribute.name===orderArray[orderIndex]);
    if(filterAttribute && filterAttribute['type'].includes('<<'))
    {
     const scehmaKey=filterAttribute['type'].replace('<<','').replace('>>','')
     filterAttribute=this.filterOrderData(scehmaKey,orderArray,(orderIndex+1))
     return filterAttribute as SchemaAttribute;
    }
    return filterAttribute as SchemaAttribute;
   }

  /**
   * Generate header defination from schema and page level object
   *
   * @param entity
   * @param columnsList
   * @returns
   */
  createHeaderDefination(entity: string, columnsList) {
    let columnDataFromSchema = this.getEntityFromSchema(entity);
    columnsList.forEach(item => {
      let field = item.field;
      if (field) {
        let displayName = columnDataFromSchema?.find(item => item.name === field);
        item['headerName'] = displayName ? displayName.displayName : 'ERROR';
        //Note: if any mismatch in UI object with Schema object then we show header lable with 'ERROR' for identification.
      }
    })
    return columnsList;
  }
  getAPI(url) {
    const httpOptions = {
      headers: new HttpHeaders({
        'disable-loader': `true`,
      }),
    };
    return this.ApiHttpService.get(url, httpOptions)
  }

  downloadFile(response: Blob, fileName: string) {
    const url = window.URL.createObjectURL(response);

    // Create an anchor element dynamically
    const link = document.createElement('a');

    // Set the href and download attributes of the anchor element
    link.href = url;
    link.download = fileName; // Specify the desired filename

    // Programmatically click the anchor element to trigger the file download
    link.click();
    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  }

  yyyymmddDateFormat() {
    let x = new Date();
    let y = x.getFullYear().toString();
    let m = (x.getMonth() + 1).toString();
    let d = x.getDate().toString();
    (d.length == 1) && (d = '0' + d);
    (m.length == 1) && (m = '0' + m);
    var yyyymmdd = y + m + d;
    return yyyymmdd;
  }
}
