import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../model/field.interface';
import { FBTextboxComponent } from '../renderers/textbox/f-textbox.component';
import { FBDropdownSingleSelectComponent } from '../renderers/dropdown-single-select/f-dropdown-single-select.component';
import { FBDatepickerComponent } from '../renderers/datepicker/f-datepicker.component';
import { FBRadioButtonListComponent } from '../renderers/radiobutton-list/f-radiobutton-list.component';
import { FBCheckboxComponent } from '../renderers/checkbox/f-checkbox.component';
import { FBCheckboxGroupComponent } from '../renderers/checkbox-group/checkbox-group.component';
import { FBTextareaComponent } from '../renderers/textarea/f-textarea.component';
import { FBSlideToggleComponent } from '../renderers/slide-toggle/f-slide-toggle.component';
import { FBDropdownMultiSelectComponent } from '../renderers/dropdown-multi-select/f-dropdown-multi-select.component';
import { FBButtonToggleComponent } from '../renderers/button-toggle/button-toggle.component';
import { FBPictureUploadComponent } from '../renderers/picture-upload/picture-upload.component';
import { FBDateFilterComponent } from '../renderers/date-filter/date-filter.component';
/**
 * componentMapper will map the type of field to respective components and helps dynamic form build to generate the reactive form.
 */
const componentMapper = {
  input: FBTextboxComponent,
  select: FBDropdownSingleSelectComponent,
  date: FBDatepickerComponent,
  radiobutton: FBRadioButtonListComponent,
  checkbox: FBCheckboxComponent,
  checkboxGroup: FBCheckboxGroupComponent,
  textArea: FBTextareaComponent,
  slideToggle: FBSlideToggleComponent,
  multiSelect: FBDropdownMultiSelectComponent,
  buttonToggle: FBButtonToggleComponent,
  file: FBPictureUploadComponent,
  datefilter: FBDateFilterComponent,
};
@Directive({
  // tslint:disable-next-line
  selector: '[dynamicField]',
})
export class DynamicFieldDirective implements OnInit, OnDestroy {
  /**
   * @Input field decorator will render the field by mapping the component based on field type property.
   */
  @Input() field: FieldConfig;

  /**
   * @Input group is a dynamic form group created with dynamic form controllers with validations based on injected json object.
   */
  @Input() group: UntypedFormGroup;
  componentRef: any;
  factory: any;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }
  ngOnInit() {
    if (this.field) {
      this.factory = this.resolver.resolveComponentFactory(
        componentMapper[this.field?.fieldType || this.field?.type]
      );
      this.componentRef = this.container.createComponent(this.factory);
      this.componentRef.instance.field = this.field;
      this.componentRef.instance.group = this.group;
    }
  }
  ngOnDestroy(): void {
    this.componentRef?.destroy(); // Don't forget to destroy the component.
  }
}
