import { Injectable, Optional } from '@angular/core';
import { CommonService } from '@app/shared/services/common.service';
import { TANENT_KEY, USER_INFO_KEY, USER_KEY, USER_STORE_KEY } from '../constants/index-DB.constants';
import { IAuthUser, ILoginInfo } from './service.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  authUser: IAuthUser;
  schema;
  tanentId: string | null;
  info: ILoginInfo;
  constructor(private commonService: CommonService) {

  }
  updateAllLocalVariables(data) {
    let userData = data.find((data) => data.key === USER_KEY);
    this.authUser = userData && userData.data ? JSON.parse(this.commonService.decryptData(userData.data)) : null;
    let schemaData = data.find((data) => data.key === USER_STORE_KEY);
    this.schema = schemaData && schemaData.data ? schemaData.data : null;
    let tanant = data.find((data) => data.key === TANENT_KEY);
    this.tanentId = schemaData && tanant.data ? this.commonService.decryptData(tanant.data) : null;
    let infoData = data.find((data) => data.key === USER_INFO_KEY);
    this.info = infoData && infoData.data ? JSON.parse(this.commonService.decryptData(infoData.data)) : null;

  }
}
