import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { routerObj } from '@app/core/constants/router-constants';
import { LoginService } from '@app/modules/authentication/services/login.service';
import { FunctionalPopupComponent } from '@app/modules/shared/components/functional-popup/functional-popup.component';
import { ComponentModel } from '@app/shared/model/component.model';
import { PopupService } from '@app/shared/services/popup/popup.service';
import { ToastrMessageService } from '@app/shared/services/toastr/toastr.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  /**
   *reset password form declaration
   *
   * @type {FormGroup}
   * @memberof ResetPasswordComponent
   */
  changePasswordForm: UntypedFormGroup;
  /**
   *password validation , it changes as per password validation status
   *
   * @type {('Low' | 'Fair' | 'Good' | 'Excellent')}
   * @memberof ResetPasswordComponent
   */
  pswStatus: 'Low' | 'Fair' | 'Good' | 'Excellent' = 'Low';
  /**
   *to check wheather user focused on password field or not
   *
   * @memberof ResetPasswordComponent
   */
  constructor(private translate: TranslateService, private router:Router,
    private toastrMessageService: ToastrMessageService, public popupService: PopupService, private fb: UntypedFormBuilder, private LoginService: LoginService) {
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', [Validators.required,
      Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,}$')
      ]
      ],
      password: ['', [Validators.required,
      Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,}$')
      ]
      ],
      confirmPassword: ['', [Validators.required, this.passwordConfirmMatch]]
    },
    )
  }
  ngOnInit() {
    this.changePasswordForm.get('password')?.valueChanges.subscribe((data) => {
      if (this.changePasswordForm.get('confirmPassword')?.valid && this.changePasswordForm.get('confirmPassword')?.value != data) {
        this.setConfirmPasswordError({ invalid: true });
      }
      if (this.changePasswordForm.get('confirmPassword')?.value === data && this.changePasswordForm.get('confirmPassword')?.dirty) {
        this.setConfirmPasswordError(null)
      }
      if (this.changePasswordForm.get('password')?.valid) {
        if (data.length == 6)
          this.pswStatus = 'Low';
        if (data.match('^(?=.*[a-z])(?=.*[A-Z]).{8,}$'))
          this.pswStatus = 'Fair';
        if (data.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$') || data.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_=+-]).{8,}$')
          || data.match('^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$') || data.match('^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$'))
          this.pswStatus = 'Good';
        if (data.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{10,}$'))
          this.pswStatus = 'Excellent';
      }

    })
  }
  /**
   * set confirm password error and update touch status
   *
   * @param error
   */
  setConfirmPasswordError(error) {
    this.changePasswordForm.get('confirmPassword')?.setErrors(error);
    this.changePasswordForm.get('confirmPassword')?.markAllAsTouched();
    this.changePasswordForm.get('confirmPassword')?.updateValueAndValidity();
  }
  /**
   * on click on saveNewPassword button hits this method and check is password valid or not (if password valid then changes password successfully popup appears)
   * @returns
   */
  onSubmit() {
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.LoginService.changePassword(this.changePasswordForm.value).subscribe((data) => {
      this.closePopup();
      this.LoginService.signOut();
      this.router.navigateByUrl(
        '/' + routerObj.AUTH.AUTH + '/' + routerObj.AUTH.LOGIN
      );
      data && this.toastrMessageService.openToastrSuccess(data['message'], this.translate.instant("general.success"));
    },
      (err) => {
        if (err.errors) {
          this.setServerError(err);
        }
      })
  }
  /**
 * used to set error comes from API
 * @param err
 */
  setServerError(err) {
    const length = err.errors.length;
    for (let i = 0; i < length; i++) {
      if (
        Object.keys(this.changePasswordForm.value).find(
          (control) => control == err.errors[i].field
        )
      ) {
        this.changePasswordForm.get(err.errors[i].field)?.
          setErrors({ serverError: err.errors[i].message });
      } else {
        this.toastrMessageService.openToastrError(
          `${err.errors[i].field}:- ${err.errors[0].message}`,
          this.translate.instant('general.error')
        );
        break;
      }
    }
    this.changePasswordForm.markAllAsTouched();
    this.changePasswordForm.updateValueAndValidity();
  }
  /**
   * it accept key as a param , on basis of that check pattern for perticular test case , if it satisfy then green tick to perticular validation
   *
   * @param key
   * @returns
   */
  matchPass(key: string) {
    let passwordVal: string = this.changePasswordForm.get('password')?.value;
    switch (key) {
      case 'atleast 6':
        return /^(?=.*).{6,}$/.test(
          passwordVal
        );
        break;
      case '1 lowercase':
        return /^(.*[a-z].*)$/.test(passwordVal);
        break;
      case '1 uppercase':
        return /^(.*[A-Z].*)$/.test(passwordVal);
        break;
      case '1 number':
        return /^(.*[0-9].*)$/.test(passwordVal);
        break;
      case '1 special charecter':
        return /^(.*[!@#$%^&*_=+-].*)$/.test(passwordVal);
        break;
      default:
        return false;
        break;
    }
  }
  focusPassword() {
    this.changePasswordForm.controls['password'].markAsTouched();
  }
  /**
 * custom validation for confirm password , once confirm password match with password then form valid
 *
 * @param c
 * @returns
 */
  passwordConfirmMatch(c: AbstractControl) {
    if (c.value !== c.parent?.get('password')?.value) {
      return { invalid: true };
    }
    return null;
  }
  /**
   * used to close popup
   */
  closePopup() {
    this.popupService.dialogRef.close();
    this.changePasswordForm.get('currentPassword')?.setValue('');
    this.changePasswordForm.get('password')?.setValue('');
    this.changePasswordForm.get('confirmPassword')?.setValue('');
  }
}