import { Component } from '@angular/core';
import { routerObj } from '@app/core/constants/router-constants';
import { PopupService } from '@app/shared/services/popup/popup.service';

@Component({
  selector: 'app-switch-device',
  templateUrl: './switch-device.component.html',
  styleUrls: ['./switch-device.component.scss']
})
export class SwitchDeviceComponent {
  countDown: number;
  routerObj = routerObj;
  constructor(private popupService: PopupService) {
  }
  closePopup() {
    this.popupService.dialogRef.close();
  }
}
