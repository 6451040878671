import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService } from '@app/shared/services/common.service';
import { PopupService } from '@app/shared/services/popup/popup.service';
import { LoginService } from '../../services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { routerObj } from './../../../../core/constants/router-constants';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../login-page/login-page.component.scss']
})
export class ForgotPasswordComponent {
  /**
   *forgot password form declaration
   *
   * @type {FormGroup}
   * @memberof ForgotPasswordComponent
   */
  forgotPasswordForm: UntypedFormGroup;
  /**
   *set Send Reset Link button to full width
   *
   * @memberof ForgotPasswordComponent
   */
  buttonWidth = { width: '100%' }
  routerObj;
  constructor(private popupService: PopupService, private fb: UntypedFormBuilder, public LoginService: LoginService, private commonService: CommonService, private translate: TranslateService) {
    this.forgotPasswordForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]]
    })
    this.routerObj = routerObj;
  }

  /**
   * on click on forgot password button hits this method and check is email valid or not (if email valid send reset password mail to user)
   * @returns 
   */
  onSubmit() {
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.LoginService.signOut();
    this.LoginService.forgotPassword(this.forgotPasswordForm.value.username).subscribe(() => {
      this.openPopup();
    })
  }
  /**
   * This method is to open the popup
   * and after closing will return the submitted value
   */
  openPopup() {
    this.commonService.openAlertPopup(this.translate.instant("auth.forgotPasswordPage.passwordLinkHdg"), this.translate.instant("auth.forgotPasswordPage.passwordLinkMsg"));
    this.popupService.dialogRef.afterClosed().subscribe(() => {
    });
  }
}

