import {
  Component,
  Input,
  Self,
  Optional,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { UntypedFormGroup, NgControl } from '@angular/forms';
import { FieldConfig } from '@app/shared/module/dynamic-form/components/model/field.interface';
@Component({
  selector: 'app-fb-checkbox',
  templateUrl: './f-checkbox.component.html',
  styleUrls: ['./f-checkbox.component.scss'],
})
export class FBCheckboxComponent {
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group: UntypedFormGroup;

  constructor() // Retrieve the dependency only from the local injector,
  // not from parent or ancestors.
  { }
}
