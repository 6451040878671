import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { routerObj } from './../../../../core/constants/router-constants';
import { TokenStorageService } from './../../../../core/services/token.service';
import { loginI, loginResponseI } from '../../model/login.model';
import { LoginService } from '../../services/login.service';
import { IndexdbService } from '@app/core/services/indexdb.service';
import { TANENT_KEY, USER_INFO_KEY, USER_KEY, USER_STORE, USER_STORE_KEY } from '@app/core/constants/index-DB.constants';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { forkJoin } from 'rxjs'
import { StorageService } from '../../../../core/services/storage.service';
import { ComponentModel } from '@app/shared/model/component.model';
import { SwitchDeviceComponent } from '../switch-device/switch-device.component';
import { PopupService } from '@app/shared/services/popup/popup.service';
import { CommonService } from '@app/shared/services/common.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit, AfterViewInit {
  /**
   *login form declaration
   *
   * @type {FormGroup}
   * @memberof LoginPageComponent
   */
  loginForm: UntypedFormGroup;
  /**
   *set login button to full width
   *
   * @memberof LoginPageComponent
   */
  buttonWidth = { width: '100%' }
  routerObj;
  dataStorage;
  alreadyLoginPopupData: ComponentModel =
    {
      componentName: SwitchDeviceComponent
    };
  constructor(private popupService: PopupService, private indexdbService: IndexdbService, private fb: UntypedFormBuilder, public LoginService: LoginService, private tokenStorage: TokenStorageService,
    private router: Router, private dbService: NgxIndexedDBService, private commonService: CommonService, private injector: Injector) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.pattern(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{6,}$'
          ),
        ],
      ],
      rememberMe: [false, [Validators.required]],
    });
  }
  /**
   * assign intial values to login form
   */
  ngOnInit(): void {

    this.routerObj = routerObj;
  }
  ngAfterViewInit(): void {
    this.assignFormValues();
  }
  /**
   * get values from local storage and assign to fields (if user checkd on "remember me" radio button)
   */
  assignFormValues() {
    this.indexdbService.getAll(USER_STORE).subscribe((data) => {
      this.dataStorage = this.injector.get(StorageService);
      this.dataStorage.updateAllLocalVariables(data);
      let localLoginDetails = this.dataStorage.info;
      if(localLoginDetails && localLoginDetails['rememberMe']) {
        localLoginDetails.password = this.commonService.decryptData(this.dataStorage.info.password);
        this.loginForm.patchValue(localLoginDetails);
      }
    })
  }
  /**
   * on click on login button hits this method and check is user valid or not (if user valid then redirect to dashboard screen)
   * @returns
   */
  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    const loginFormData = {...this.loginForm.value};
    loginFormData.password = this.commonService.encryptData(this.loginForm.value.password);
    this.LoginService.signOut();

    //this.LoadingService.hideLoader();
    this.LoginService.login({username: loginFormData.username, password: loginFormData.password}).subscribe((data: loginResponseI | any) => {
      let tanentId = data.headers.get(`${this.tokenStorage.TANENT_KEY}`);
      data = data?.body;
      if (data && data.data) {
        this.tokenStorage.saveToken(data.data.token.access_token);
        this.dataStorage.tanentId = tanentId;
        forkJoin([
          this.LoginService.getSchemas(),
          this.dbService.clear(USER_STORE),
          this.dbService.add(USER_STORE, { key: TANENT_KEY, data: this.commonService.encryptData(tanentId) }),
          this.dbService.add(USER_STORE, { key: USER_KEY, data: this.commonService.encryptData(JSON.stringify(data.data)) }),
          this.dbService.add(USER_STORE, { key: USER_INFO_KEY, data: this.commonService.encryptData(JSON.stringify(loginFormData)) }),
        ]).subscribe((res) => {
          this.indexdbService.add(USER_STORE, { key: USER_STORE_KEY, data: res[0] })
            .subscribe(() => {
              this.dbService.getAll(USER_STORE).subscribe((IndeDBRecord) => {
                this.dataStorage.updateAllLocalVariables(IndeDBRecord);
                this.router.navigateByUrl('/' + routerObj.ADMIN + '/' + routerObj.DASHBOARD);
              })
            });

        });
      }
    })
  }
  getSchemas() {
    this.LoginService.getSchemas().subscribe((res: any) => {
      // console.log("Schemas", res);
      localStorage.setItem("schemas", JSON.stringify(res));
    })

  }
  alreadyLoginPopup() {
    // this.commonService.openConfirmationPopup(
    //   'Switch Device',
    //   'Seems like you have logged into 5Day from anather device or browser Would you like to switch by logging out from here',
    //   'Stay Here',
    //   'Switch Now'
    // );
    this.popupService.openPopup(this.alreadyLoginPopupData);
  }

}
