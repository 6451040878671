import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RadioButtonList } from '@app/shared/model/radiobuton-list.model';
import { SelectDropdownList } from '@app/shared/model/select-list.model';
import { SlideToggleList } from '@app/shared/model/slide-toggle.model';
import { PopupService } from '@app/shared/services/popup/popup.service';
import { SnackBarService } from '@app/shared/services/snack-bar/snack-bar.service';
import { CarouselSlides } from '@app/shared/model/carousel.model';
import { ComponentModel } from '@app/shared/model/component.model';
import { DemoPopupPageComponent } from '../demo-popup-page/demo-popup-page.component';
import { Menu } from '@app/shared/model/menu.model';
import { list } from '@app/shared/model/list.model';
import { customizeListItem } from '@app/shared/model/customize-list-item.model';
import { ColorHelper, DataItem, LegendPosition } from '@swimlane/ngx-charts';
import { MatFabMenu } from '@angular-material-extensions/fab-menu';
import { WebcamImage } from 'ngx-webcam';
import { chipSetItem } from '@app/shared/model/chipset.model';
import { selectableChipMenu } from '@app/shared/model/selecteble-chip-with-menu.model';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { CommonService } from '@app/shared/services/common.service';

@Component({
  selector: 'app-demo-page',
  templateUrl: './demo-page.component.html',
  styleUrls: ['./demo-page.component.scss'],
  animations: [
    trigger('filtersView', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.5s ease-in')
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.3s ease-out', style({ transform: 'translateX(-100%)' }))
      ])
    ]),
  ]
})
export class DemoPageComponent {
  /**
  * List of options for Radio button(s)
  */
  list: Array<RadioButtonList> = [];
  /**
  * label for radiobutton list
  */
  label = "List of items.";

  customInputForm: UntypedFormGroup;
  buttonStyle = { margin: '10px' }

  /**
  * List of options for single/multi select dropdown
  */
  dropdownList: Array<SelectDropdownList> = [];
  /**
  * default Single Selected Dropdown Item if any
  */
  defaultSelectedDropdownItem: SelectDropdownList = {};
  /**
  * default Multi Selected Dropdown Item(s) if any
  */
  defaultSelectedDropdownItems: Array<SelectDropdownList> = [];

  /**
   * Placeholder of the textarea
   */
  placeholder: string = 'Write here..';
  /**
   * Label for the textarea
   */
  textareaLabel: string = '';
  /**
   * It holds content of the textarea
   */
  textareaValue: string = '';
  /**
  * It holds sample stylesheet value
  */
  styleVal = {
    margin: '5px'
  }
  /**
   * Dymanic staylesheet for profile icon
   *
   * slide toggle value
   */
  slideToggleData: SlideToggleList = {};
  /**
   * It holds the popup data
   * component is component name
   * data is any
   */
  popupData: ComponentModel = { componentName: DemoPopupPageComponent, data: { textareaFieldValue: 'Test Data..', placeholder: 'Write here..', textareaLabel: 'TextArea' } };
  isShowPopoverFlag: boolean;
  listData: list[] = [{ label: 'Tenant Name', subLabel: 'standard' },
  { label: 'Tenant Name', subLabel: 'standard' },
  { label: 'Tenant Name', subLabel: 'standard' },
  { label: 'Tenant Name', subLabel: 'standard' }]

  /**
   * It holds the tabs Labels & Content
   * Content should be in HTML format
   */
  menuItems: Menu[] = [{ label: 'Create New', isDisabled: false, icon: 'create_new_folder' },
  { label: 'Edit', isDisabled: false, icon: 'edit' },
  { label: 'Move', isDisabled: false, icon: 'remove_circle' },
  { label: 'Duplicate', isDisabled: false, icon: 'control_point_duplicate' },
  { label: 'Change Type', isDisabled: true, icon: 'track_changes' },
  ];
  chipMenuItems: Menu[] = [{ label: 'Duplicate', isDisabled: false },
  { label: 'Delete', isDisabled: false },
  ];
  /**
   *hold selected menu chip data
   *
   * @type {selectableChipMenu[]}
   * @memberof DemoPageComponent
   */
  chipData: selectableChipMenu[] = [{ label: 'Default', isDisabled: false, number: 1, icon: 'more_vert', menu: this.chipMenuItems },
  { label: 'Standard', isDisabled: false, icon: 'more_vert', menu: this.chipMenuItems },
  { label: 'Custom 1', isDisabled: true, icon: 'home', menu: this.chipMenuItems },
  ];
  buttonItemClickName: string = '';
  checkboxList = [
    { label: 'gaint view', name: 'gaintView' },
    { label: 'MileStone', name: 'MileStone' },
    { label: 'Baseline', name: 'Baseline' }];

  tabsList = [{ label: 'Text', content: '<p>Angular is a platform and framework for building single-page client applications using HTML and TypeScript. Angular is written in TypeScript. It implements core and optional functionality as a set of TypeScript libraries that you import into your applications.</p>' },
  { label: 'Video', content: '<iframe width="560" height="315" src="https://www.youtube.com/embed/0eWrpsCLMJQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' },
  { label: 'Image', content: '<img src = "https://picsum.photos/900/500?random&t=1" alt="demo image">' }];
  /**
   * It holds the Images of Carousel
   */
  carouselSlides: Array<CarouselSlides> = [
    { path: 'https://i.imgur.com/fHyEMsl.jpg' },
    { path: 'https://picsum.photos/900/500?random&t=1' },
    { path: 'https://picsum.photos/900/500?random&t=3' },
    { path: 'https://picsum.photos/900/500?random&t=6' },
    { path: 'https://picsum.photos/900/500?random&t=4' },
    { path: 'https://picsum.photos/900/500?random&t=8' }
  ];


  pieChartData: DataItem[] = [
    {
      "name": "Germany",
      "value": 8940000
    },
    {
      "name": "USA",
      "value": 5000000
    },
    {
      "name": "France",
      "value": 7200000
    },
    {
      "name": "UK",
      "value": 6200000
    }
  ];
  pieChartColorScheme: ColorHelper['customColors'] = {
    domain: ['red', 'green', 'black', 'yellow']
  };
  pieChartLegendPosition: LegendPosition = LegendPosition.Right;

  /**
   *fab menu list
   *
   * @type {MatFabMenu[]}
   * @memberof DemoPageComponent
   */
  fabButtonList: MatFabMenu[] = [
    {
      id: "create",
      icon: 'create'
    },
    {
      id: "mail",
      icon: 'mail'
    },
    {
      id: 'file_copy',
      icon: 'file_copy'
    },
    {
      id: 'phone',
      icon: 'phone'
    },
  ];
  isShowColorTheme: boolean;
  profileInitial: string = "W";
  fabMenuSelectedId: number | string;
  captureImage: string = '';
  chipListData: chipSetItem[] = [{ name: 'All', isSelected: true },
  { name: 'Open', isSelected: false },
  { name: 'Inprogress', isSelected: false }];
  selectedChip: chipSetItem;
  /**
   * Sample Accordian data
   *
   */
  accordionData = [
    {
      'panelOpenState': false, 'title': 'Account', 'subTitle': 'Wranglers Inc', 'type': 'account', 'desc': 'Type your name and age', 'menu': [{ id: 1, label: 'menu 1' }, { id: 2, label: 'menu 2' }, { id: 3, label: 'menu 3' }] as any, 'icon': 'more_vert', 'disabled': false, 'letter': 'W', 'role': 'Member',
      child: [
        { 'title': 'Workspace', 'subTitle': 'Software Services', 'type': 'workspace', 'desc': 'Type your name and age', 'menu': [{ id: 1, label: 'child 1' }, { id: 2, label: 'child 2' }] as any, 'icon': 'more_vert', 'letter': 'S' },
      ]
    },
    // { 'panelOpenState': false,'title': 'Account','subTitle': 'Rishabh Software Pvt. Ltd.', 'desc': 'Type the country name', 'data': 'Test1', 'icon': 'more_vert','disabled':true,'letter':'R' },
    {
      'panelOpenState': false, 'title': 'Account', 'subTitle': 'Rishabh Software Pvt. Ltd.', 'type': 'account', 'desc': 'Type the country name', 'menu': [{ id: 1, label: 'parent 1' }, { id: 2, label: 'parent 2' }] as any, 'icon': 'more_vert', 'disabled': false, 'letter': 'R', 'role': 'Member',
      component: DemoPopupPageComponent, data: []
    }
  ];
  /**
   * Sample Card data
   *
   */
  files = [
    { name: 'Lorem ipsum dollar sit', label: 'File Preview', date: 'Jun 14, 2020', format: 'XLS', image: 'assets/images/emp1.jpg', url: 'https://www.clickdimensions.com/links/TestPDFfile.pdf' },
    { name: 'Lorem ipsum dollar sit', label: 'File Preview', date: 'Jun 14, 2020', format: 'XLS', image: 'assets/images/emp2.jpg', url: 'https://www.orimi.com/pdf-test.pdf' },]


  //selecteble-chip-with-menu
  selectebleChipWithMenu: number;
  /**
 * To check customize popup is opened/closed
 */
  state: string = "closed";

  customizeSelected: boolean;
  /**
   * Customize list items
   */
  customizeList: customizeListItem[] = [
    {
      id: 1,
      name: 'Status',
      showActions: true,
      dragDisabled: true,
      actionList: {
        columnEnable: true,
        pinned: true
      }
    },
    {
      id: 2,
      name: 'Priority',
      showActions: true,
      dragDisabled: true,
      actionList: {
        columnEnable: true,
        pinned: true
      }
    },
    {
      id: 3,
      name: 'Severity',
      showActions: true,
      dragDisabled: false,
      actionList: {
        columnEnable: true,
        pinned: false
      }
    },
    {
      id: 4,
      name: 'Start Date',
      showActions: true,
      dragDisabled: false,
      actionList: {
        columnEnable: true,
        pinned: false
      }
    },
    {
      id: 5,
      name: 'Due Date',
      showActions: true,
      dragDisabled: false,
      actionList: {
        columnEnable: true,
        pinned: false
      }
    },
    {
      id: 6,
      name: 'Tags',
      showActions: true,
      dragDisabled: false,
      actionList: {
        columnEnable: true,
        pinned: false
      }
    },
    {
      id: 7,
      name: 'Created By',
      showActions: true,
      dragDisabled: false,
      actionList: {
        columnEnable: true,
        pinned: false
      }
    },
    {
      id: 8,
      name: 'Created Date',
      showActions: true,
      dragDisabled: false,
      actionList: {
        columnEnable: false,
        pinned: false
      }
    },
    {
      id: 9,
      name: 'Last Updated By',
      showActions: true,
      dragDisabled: false,
      actionList: {
        columnEnable: true,
        pinned: false
      }
    },
    {
      id: 10,
      name: 'Last Updated Date',
      showActions: true,
      dragDisabled: false,
      actionList: {
        columnEnable: true,
        pinned: false
      }
    }
  ];

  /**
   * Display members for open/close the slide-poup
   */
  displayMembers: boolean;
  /**
   * workspace Members List
   */
  workSpaceMembersList: any = [{ id: 1, image: 'assets/images/emp2.jpg', name: 'Kurt McLaughlin', role: 'Developer', status: 'Active' },
  { id: 2, image: 'assets/images/emp1.jpg', name: 'Bobby Brakus', role: 'Developer', status: 'Active' },
  { id: 3, image: 'assets/images/emp2.jpg', name: 'Marian Preffer', role: 'Developer', status: 'Active' },
  { id: 4, image: 'assets/images/emp1.jpg', name: 'Lionel Ebert', role: 'Developer', status: 'Invited' },
  { id: 5, image: 'assets/images/emp2.jpg', name: 'Otis Breitenberg', role: 'Developer', status: 'Inactive' },
  { id: 6, image: 'assets/images/emp1.jpg', name: 'Emesto Roberts IV', role: 'Developer', status: 'Active' },
  { id: 7, image: 'assets/images/emp2.jpg', name: 'Kurt McLaughlin', role: 'Developer', status: 'Active' },
  { id: 8, image: 'assets/images/emp1.jpg', name: 'Bobby Brakus', role: 'Developer', status: 'Active' },
  { id: 9, image: 'assets/images/emp2.jpg', name: 'Marian Preffer', role: 'Developer', status: 'Active' },
  { id: 10, image: 'assets/images/emp1.jpg', name: 'Lionel Ebert', role: 'Developer', status: 'Active' }];
  /**
   * This is to hold the Selected Members List
   */
  selectedMembersList: Array<string | number> = [];
  /**
   * this is to open/close the invite new slide-popup
   */
  inviteNew: boolean;
  /**
   * This is to get the invite new form data
   */
  inviteMemberForm: UntypedFormGroup;
  /**
   * List of Roles
   */
  rolesList = [{ id: '1', name: 'Project Manager' }, { id: '2', name: 'Account Owner/Admin' }, { id: '3', name: 'Project Member' }, { id: '4', name: 'Resource Manager' }, { id: '5', name: 'Financial Manager' }, { id: '6', name: 'Director/Program/Portfolio Manager' }, { id: '37', name: 'Guest' }, { id: '37', name: 'External member' }, { id: '37', name: 'Product Administrator' }];
  /**
   * Invitation types
   */
  invitationTypes = [{ id: 1, label: 'Virtual', iconName: 'info_outline', tooltipMessage: 'Virtual Invitation' }, { id: 2, label: 'Internal', iconName: 'info_outline', tooltipMessage: 'Internal Invitation' }, { id: 3, label: 'External', iconName: 'info_outline', tooltipMessage: 'External Invitation' }];

  designObject = {
    id: 0,
    name: '',
    color: ''
  }
  designProjectsData = [
    {
      'panelOpenState': false, 'text': 'To Do', 'type': 'status', 'disabled': false,
    },
    {
      'panelOpenState': false, 'text': 'In Progress', 'type': 'status', 'desc': 'Type the country name', 'disabled': false,
      component: DemoPopupPageComponent, data: [
        {
          id: 1,
          name: 'Design',
          color : '#12db0b',
          showActions: false,
          dragDisabled: false,
          dragPlacement: 'left-outside',
          menuIcon: 'more_vert',
          actionList: {
            color: true,
            menu: true
          },
          menuOptions: [{ id: 1, label: 'Delete' }]
        },
        {
          id: 2,
          name: 'Design Review',
          color : '#12db0b',
          showActions: true,
          dragDisabled: false,
          menuIcon: 'more_vert',
          actionList: {
            color: true,
            menu: true
          },
          menuOptions: [{ id: 1, label: 'Delete' }]
        }, {
          id: 3,
          name: 'Review',
          color : '#12db0b',
          showActions: true,
          dragDisabled: false,
          menuIcon: 'more_vert',
          actionList: {
            color: true,
            menu: true
          },
          menuOptions: [{ id: 1, label: 'Delete' }]
        }
      ]
    }, 
    {
      'panelOpenState': false, 'text': 'Done', 'type': 'status', 'disabled': false,
    }
  ]

  profileOptions = {
    colorCodes: {
      displayName: 'Color/Theme',
      codes: ['#E3545C', '#FC7FC2', '#5EE2FF', '#F4AE11', '#00D4C4', '#AE6EFF', '#FF8A00', '#508CFF', '#333333']
    },
    icons: {
      displayName: 'Icon',
      codes: ['home', 'star', 'favorite', 'bar_chart', 'notes', 'account_circle', 'description', 'work', 'flag', 'person']
    },
    images: {
      displayName: 'Image',
      url: ['assets/images/image1.jpg', 'assets/images/image2.jpg', 'assets/images/image3.jpg', 'assets/images/image4.jpg']
    }
  }


  constructor(private fb: UntypedFormBuilder,
    private popupService: PopupService,
    private snackBarService: SnackBarService,
    public commonService: CommonService) {
    //static data
    this.customInputForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        fullname: ['', [Validators.required, Validators.minLength(4)]],
        itemName: ['', [Validators.required]],
        description: ['', [Validators.required]],
        savePassword: [false, [Validators.required]],
        date: [new Date()],
        checkboxes: new UntypedFormGroup({
          gaintView: new UntypedFormControl(true),
          MileStone: new UntypedFormControl(''),
          Baseline: new UntypedFormControl('')
        })
      }
    );

    // console.log(this.customInputForm.value, "--------")
    this.list = [{ id: 1, label: 'item 1' }, { id: 2, label: 'item 2' }, { id: 3, label: 'item 3' }, { id: 4, label: 'item 4' }];
    this.dropdownList = [{ id: 1, name: 'item 1' }, { id: 2, name: 'item 2' }, { id: 3, name: 'item 3' }, { id: 4, name: 'item 4' }];
  }


  onFileSelect(file: File | null) {
    // console.log(file, "sssssss degi kya")
  }
  onSubmit() {
    // console.log(this.customInputForm)
  }

  /**
   * This method is to get the selected value from single select dropdown (from app-f-dropdown-single-select reusable component)
   */
  selectedItemFromDropdown(item: SelectDropdownList) {
    this.defaultSelectedDropdownItem = item;
  }

  /**
   * This method is to get the selected value(s) from multi select dropdown (from app-f-dropdown-multi-select reusable component)
   */
  selectedItemMultipleFromDropdown(items: Array<SelectDropdownList>) {
    this.defaultSelectedDropdownItems = items;
  }

  /**
   * trackBy method for ngFor in html page (Multi select dropdowns list)
   */
  multiselectItem(index: number) {
    return index;
  }

  /**
   * This method is to GET the Updated toggle value From reusable Component app-f-slide-toggle
   * And will update the slideToggleData
   */
  slideToggleValue(item: SlideToggleList) {
    this.slideToggleData = item;
  }

  /**
   * This method is to open the popup
   * and after closing will return the submitted value
   */
  openPopup() {
    this.popupService.openPopup(this.popupData);
    this.popupService.dialogRef.afterClosed().subscribe((response: any) => {
      // console.log("response ", response);
    });
  }

  /**
   * This method is to open the success message snackBar
   * send the message and type - type we can take from constants file in future
   */
  showSuccessSnackBar() {
    this.snackBarService.openSnackBar('Success Message!', 'success');
  }

  /**
   * This method is to open the error message snackBar
   * send the message and type - type we can take from constants file in future
   */
  showErrorSnackBar() {
    this.snackBarService.openSnackBar('Error Message!', 'error');
  }

  /**
   * This method is to open the warning message snackBar
   * send the message and type - type we can take from constants file in future
   */
  showWarningSnackBar() {
    this.snackBarService.openSnackBar('Warning Message!', 'warning');
  }
  menuItemClick(menuItem: Menu) {
    this.buttonItemClickName = menuItem.label;
  }

  fabMenuSelection(id: string | number) {
    this.fabMenuSelectedId = id;
    // console.log(id, "onFabMenuItemSelected")
  }

  captureCameraOutput(event: WebcamImage) {
    this.captureImage = event.imageAsDataUrl;
    // console.log(event.imageAsDataUrl, "imageAsDataUrl")
  }
  onChipClick(event: { chipNo: number, isSelected: boolean }) {
    this.chipListData[event.chipNo].isSelected = event.isSelected;
    this.selectedChip = this.chipListData[event.chipNo];
    // console.log(this.chipListData[event.chipNo], "Sssssssssss")
  }

  /**
   * This method is to get the selected icon
  */
  selectedIcon(icon: string) {
    // console.log("Selected Icon", icon);
  }
  /**
   * This method is to get the selected menu from accordion
  */
  selectedMenu(val: any) {
    // console.log("Selected Menu ", val);
  }
  /**
   * This method is to get/open the selected card file url
  */
  filePreview(url: string) {
    // console.log("Selected url ", url);
    window.open(url)
  }

  //app-selecteble-chip-with-menu actions
  chipSelect(selectedItem) {

    // console.log("chipSelectchipSelect", selectedItem)
    this.selectebleChipWithMenu = selectedItem;
  }
  menuSelect(selectedMenu) {
    // console.log("menuSelectmenuSelect", selectedMenu)

  }
  popoverButtonClick() {
    // console.log("popoverButtonClickpopoverButtonClick")
    this.isShowPopoverFlag = false;
  }

  close() {
    this.customizeSelected = false;
  }

  hide() {
    // console.log('hide');
  }
  show() {
    // console.log('show');
  }


  // VIEW/INVITE Member START
  /**
 * This method is to open the popup and display the content
 */
  viewMembers() {
    this.displayMembers = !this.displayMembers;
    if (this.inviteNew && !this.displayMembers) {
      this.inviteNew = false;
      this.state = 'closed';
    } else {
      this.changeState();
    }
  }

  /**
   * This method is to change the state of side popup
   */
  changeState(): void {
    (this.state === "closed") ? this.state = "open" : this.state = "closed";
  }

  /**
   * This method is to search the data from members list
   */
  search(value: any) {
    console.log("Search ", value);
  }

  /**
   * This method is to capture the selected member
   */
  selectedMembers(checked: any, id: string | number) {
    if (checked === true) {
      this.selectedMembersList.push(id);
    } else {
      this.selectedMembersList = this.removeItem(this.selectedMembersList, id)
    }
  }

  /**
   * This method is to remove the element from an array
   */
  removeItem(data, item) {
    for (var i in data) {
      if (data[i] == item) {
        data.splice(i, 1);
        break;
      }
    }
    return data;
  }

  /**
   * This method is to get the selected members data
   */
  addMembers() {
    console.log("Selected Members  ", this.selectedMembersList);
  }

  /**
   * This method is to open a popup of invite form
   */
  inviteNewMember() {
    if (!this.inviteMemberForm) {
      this.createInviteForm();
    }
    this.inviteNew = true;
    this.displayMembers = false;
  }

  /**
   * This method is to create a form to invite the members
   */
  createInviteForm() {
    const roles = this.rolesList.map(control => this.fb.control(false));
    this.inviteMemberForm = this.fb.group({
      email: [''],
      type: ['Internal'],
      roles: this.fb.array(roles),
    })
  }

  /**
   * This method is to get the roles in the formarray format
   */
  get rolesArr() {
    return this.inviteMemberForm.get('roles') as UntypedFormArray;
  }

  /**
   * This method is to invite the people based on entered data
   */
  invite() {
    const selectedRoles = this.inviteMemberForm.value.roles
      .map((checked, i) => checked ? this.rolesList[i] : null)
      .filter(value => value !== null);
    console.log("selectedRoles ", selectedRoles);
    let payload = this.inviteMemberForm.getRawValue();
    payload['roles'] = selectedRoles;
    console.log("Payload ", payload);
  }

  // VIEW/INVITE Member END
}
