import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { checkboxGroup } from '@app/shared/model/checkbox-group.model';
import { FieldConfig } from '@app/shared/module/dynamic-form/components/model/field.interface';

@Component({
  selector: 'app-checkboxwrapper-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
})
export class FBCheckboxGroupComponent {
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group: UntypedFormGroup;

  @Output() selectAllSubFeatures = new EventEmitter();
  selectedCount: number = 0;
  constructor() { }
  
  /**
   * trackBy method
   */
  checkboxSelectList(index: number) {
    return index;
  }
}
