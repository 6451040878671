import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemoPageComponent } from './demo-page/demo-page.component';
import { DemoPopupPageComponent } from './demo-popup-page/demo-popup-page.component';

const routes: Routes = [
  {
    path: 'demo',
    component: DemoPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
