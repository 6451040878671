export class PopoverSourceData {
  popover = [
    {
      type: 'Popover',
      html: `  <lib-rspl-popover position="bottom" #popover>
      <lib-rspl-button
        [buttonType]="'flat-button'"
        [label]="'Popover'"
        [color]="'primary'"
      ></lib-rspl-button>

      <br />
      <div modalMenu>
        <h1 class="person-list__item">This is Popover</h1>
        <h2>This is Popover</h2>
        <h3>This is Popover</h3>
        <h4>This is Popover</h4>
        <h5>This is Popover</h5>
        <h6>This is Popover</h6>
      </div>
    </lib-rspl-popover>`,
      css: ``,
      ts: `/**
      *position of popever irrespective to button
      *
      * @type {('left'|'right'|'top' |'bottom')}
      * @memberof PopoverComponent
      */
      @Input() position: 'left' | 'right' | 'top' | 'bottom' = 'left';
      @Input() forAccountOverlay: boolean = false;

      @ViewChild('popoverButton', { static: false }) popoverButtonDiv: ElementRef | any;
      @ViewChild('popover', { static: false }) popoverDiv: ElementRef | any;
      isShowPopoverFlag = false;`,
      json: ` `,
    },
  ];
}
