import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FieldConfig } from '@app/shared/module/dynamic-form/components/model/field.interface';

@Component({
  selector: 'app-f-slide-toggle',
  templateUrl: './f-slide-toggle.component.html',
  styleUrls: ['./f-slide-toggle.component.scss'],
})
export class FBSlideToggleComponent {
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group: UntypedFormGroup;

  constructor() { }
}
