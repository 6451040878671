export class MultiSelectDropDownSourceData {
  multiSelectDropdown = [
    {
        type: 'Multi Select Dropdown Group',
        html: `<lib-rspl-dropdown-multiselect
        [dropdownList]="dropdownList"
        ></lib-rspl-dropdown-multiselect>`,
        css: ``,
        ts: `import { ISelectDropdownOption } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/select-list.model';
        import uiComponentsData from './json/ui-components.json'; //Json path is only for demo purpose. It may vary depending on your reqirement

        /**
         * to get default selected item(s) if any
         */
         @Input() defaultSelectedItem: Array<ISelectDropdownOption> = [];

         /**
          * List of the items for selection
          */
         @Input() dropdownList: Array<ISelectDropdownOption> = [];

         /**
          * multi select dropdown label
          */
         @Input() label: string = '';

         /**
          * emits the selected item(s)
          */
         @Output() selectedValue = new EventEmitter<Array<ISelectDropdownOption>>();
         /**
         * Multi select dropdown list data
         */
        dropdownList: ISelectDropdownOption[] = uiComponentsData.uiComponent.dropDownList; //data calling will be different as per user requirment`,
        json: `"dropDownList":[
                  {
                    "id":1,
                    "name":"Multi Select 1"
                  },
                  {
                    "id":2,
                    "name":"Multi Select 3"
                  },
                  {
                    "id":3,
                    "name":"Multi Select with icon",
                    "icon":{
                      "name":"home"
                    }
                  },
                  {
                    "id":4,
                    "name":"Multi Select with icon color",
                    "icon":{
                      "name":"home",
                      "color":"blue"
                    }
                  }
                ];`,
      },
  ]
}
