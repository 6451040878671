<form [formGroup]="group" *ngIf="group && field" [class]="field.cssClass ? field.cssClass : ''">
  <mat-label>{{field.label}}</mat-label>


  <mat-form-field appearance="fill" [id]="field.id">
    <mat-label>{{field.label}}</mat-label>
    <input matInput [matDatepicker]="picker1" [formControlName]="field.name" [name]="field.name"
      [placeholder]="placeholder">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
</form>