export class LoaderSourceData {
  loader = [
    {
      type: 'Loader',
      html: `<lib-rspl-loader [loading]="true"></lib-rspl-loader>`,
      css: ``,
      ts: `  /**
      * To hold loader value to show/hide
      */
   @Input() loading: boolean | null = false;`,
      json: ` `,
    },
  ];
}
