import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormControl,
  FormGroupName,
  FormGroup,
} from '@angular/forms';
import { routerObj } from './../../../../core/constants/router-constants';
import { SharedService } from '@app/modules/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { DynamicFormService } from '../services/dynamic-form.service';
import { FieldConfig } from './model/field.interface';

/**
 * The DynamicFormBuilder Component
 * ```html
 * <app-dynamic-form [fields]="fieldsAvailable.accountDetails" [formName]="'profileFormData'" #profileFormData></app-dynamic-form>
 *
 * ```
 *
 * The following is the screenshot of DynamicFormBuilder Component
 *
 *
 * ![screenshot-1](screenshots/formbuilder.png)
 */
@Component({
  exportAs: 'app-dynamicForm',
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, AfterViewInit, OnChanges {
  /**
   * @Input field decorator will render the field by mapping the component based on field type property.
   */
  @Input() fields: FieldConfig[] = [];
  /**
   * @Input formConfig defines the configuration params of form.
   */
  @Input() formConfig: Object;
  /**
   * @Input formConfig defines the form name parameter of form.
   */
  @Input() formName: string = '';
  /**
   * @Input formConfig defines the form name parameter of form.
   */
  @Input() dindDaformBindData: any;
  /**
  * @Output formData gives the output value of all form controllers with values.
  */
  @Output() formData: EventEmitter<any> = new EventEmitter<any>();

  //dynamicForm: UntypedFormGroup;
  @Input() dynamicForm: FormGroup=this.fb.group({});
  @Input() initialFormValue: any;
  @Input() addLabelIntoFields: boolean = false;
  validList: any = [];
  control: any;
  groupFormBy: Array<string>;
  get value() {
    return this.dynamicForm.value;
  }
  constructor(
    private fb: UntypedFormBuilder,
    private change: ChangeDetectorRef,
    private sharedService: SharedService,
    private translate: TranslateService,
    private dynamicFormService: DynamicFormService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    // if(changes['fields']) {
    //   this.fields = this.fields.map((field) => ({ ...field, label: field?.fieldName || field?.label }));
    // }
  }

  ngOnInit() {
    this.createControl(this.fields, this.dynamicForm, this.initialFormValue);
    /**
     *  The below logic create group by in the forms based on groupBy option which is passed in the json object to generate the form.
     */
    if (this.fields) {
      this.groupFormBy = [
        ...new Set(
          this.fields.map((a: any) => {
            return a.groupBy;
          })
        ),
      ];
    }
    if (
      this.dynamicForm &&
      this.formName &&
      window.location.href.includes(
        routerObj.ACCOUNTS.ACCOUNTS + '/' + routerObj.ACCOUNTS.TENANT_DETAILS
      )
    ) {
      this.storePayload();
      this.dynamicForm.valueChanges.subscribe((selectedValue) => {
        this.sharedService.isAccountProfileFormValid = this.dynamicForm.valid;
        this.storePayload();
      });
    }
  }
  /**
   * storing the payload in Overlay component from dynamic forms module.
   */
  storePayload() {
    this.sharedService.createAccountsTenantPayload[this.formName] =
      this.dynamicForm.value;
    this.sharedService.createAccountsTenantController[this.formName] =
      this.dynamicForm;
  }

  ngAfterViewInit() {
    // this.formData.emit(this.dynamicForm.value);
    // console.log('this.formData', this.dynamicForm.value);
    this.dynamicFormService.setTenantProfile(this.dynamicForm.value);
    // if(this.dynamicForm.value.industry) {
    //   this.dynamicFormService.formDataChangesToParent(this.dynamicForm);
    // } else {
    this.dynamicFormService.formDataChangesToParent(this.dynamicForm);
    // }
  }
  // public ngAfterViewChecked(): void {
  //   this.dynamicFormService.formDataChangesToParent(this.dynamicForm);
  // }
  /**
   *
   * @returns createControl() will generate the controllers to generate the formbuilder basedon the json objects passed to this method.
   */
  createControl(fields: FieldConfig[], group: FormGroup, initialFormValue?: any) {
    //const group = this.fb.group({});
    //this.fields.forEach((field: any) =>
    fields.filter((field: FieldConfig) => !group.get(field?.fieldId || field?.name)).forEach((field: FieldConfig) =>  {
      if (field?.type === 'button') return;
      else {
        const control = this.fb.control(
          (initialFormValue && initialFormValue[field?.fieldId || field?.name]) || field.defaultValue,
          this.bindValidations(field.validations || [])
        );
        // control.markAsTouched(); fix for #4569
        // if (field.type === 'datefilter') {
        //   let control = this.fb.group(field.value);
        //   group.addControl(field.name, control);
        // } else {
        group.addControl(field.fieldId || field.name, control);
        // }

      }
    });

    return group;
  }

  /**
   *
   * @param validations
   * @returns bindValidations will expect the parameter to generate the validations and bind to the controler which created by createControl() method.
   */
  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList: any = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  /**
   *
   * @param formGroup validateAllFormFields will validate the formGroup
   */
  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      this.control = formGroup.get(field);
      this.control.markAsTouched({ onlySelf: true });

    });
  }
}
