export class CheckboxSourceData {
  checkbox = [
    {
      type: 'Checkbox Group',
      html: `<lib-rspl-checkbox
      *ngFor="let item of Checkbox; let i = index"
      labelPosition="after"
      class="checkBox"
      [label]="item.label"
      id="checkBoxId {{ i }}"
      formControlName="checkBox"
    ></lib-rspl-checkbox>`,
      css: `.checkBox{
        line-height: 3;
      }`,
      ts: `import { checkboxGroup } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/checkbox-group.model';
      import uiComponentsData from './json/ui-components.json'; //Json path is only for demo purpose. It may vary depending on your reqirement

      /**
       * we can decide label position using this property
       *
       * @type {('before' | 'after')}
       *
       */
      @Input() labelPosition: any;

      /**
       *value of checkbox
       *
       * @type {boolean}
       *
       */
      @Input() checked: boolean = false;

      /**
       * Check box list data
       */
      Checkbox: checkboxGroup[] = uiComponentsData.uiComponent.checkBoxList; //data calling will be different as per user requirment`,
      json: `  "checkBoxList": [
        {
          "label": "check box 1",
          "name": "check 1"
        },
        {
          "label": "check box 2",
          "name": "check 2"
        },
        {
          "label": "check box 3",
          "name": "check 3"
        },
        {
          "label": "check box 4",
          "name": "check 4"
        }
      ];`,
    },
  ];
}
