export class TabsSourceData {
  tabs = [
    {
      type: 'Tab Group',
      html: ` <lib-rspl-tab-group
            [dataSource]="tabsList"
          ></lib-rspl-tab-group>`,
      css: ``,
      ts: `import { ComponentModel } from '../../shared/model/component.model';

            /**
             * It holds the tabs Labels & Content
             */
            @Input() dataSource: any;
            /**
            * To hold default tab value
            */
            @Input() tabIndex: number = 0;

            /**
           * ViewRef of the dynamic component
           */
            @ViewChild('dynamicLoadComponent') dynamicLoadComponent: ComponentRef<IComponent> | any;
            /**
            * @Output() onDatePicked
            */
            @Output() selectedIndex = new EventEmitter();

            /**
           * This will hold the type of the tabs to be loaded
           */
            @Input() useType: string = 'static';

            /**
             * Tabs Data
             */
            tabsList: Array<ComponentModel> = [
              {
                data: { label: 'Demo Tab 1', value: 1 },
              },
              {
                data: { label: 'Demo Tab 2', value: 2 },
              },
            ];`,
      json: ``,
    },
  ];
}
