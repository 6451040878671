export const routerObj = {
  LOGIN: 'login',
  ADMIN: 'admin',
  DASHBOARD: 'dashboard',
  ACCOUNTS: {
    ACCOUNTS: 'accounts',
    TENANT_DETAILS: 'tenant-details',
    TENANT: 'tenant',
  },
  PACKAGES: {
    PACKAGES: 'packages',
    ADD_PACKAGES: 'add-packages',
    EDIT_PACKAGE: 'edit-package',
    EDIT_TENANT_FEATURES: 'edit-tenant-features'
  },
  SETTINGS: {
    SETTINGS: 'settings'
  },
  UICOMPONENTS:{
    UICOMPONENTS:'ui-components'
  },
  FEEDBACK: {
    FEEDBACK: 'feedback',
    FEEDBACK_VIEW: 'feedback-view'
  },
  BATCHJOB: {
    BATCHJOB: 'batchjob',
    BATCHJOB_DETAILS: 'batchjob-details'
  },
  AUTH: {
    AUTH: 'auth',
    LOGIN: 'login',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password',
    LINK_EXPIRED:'link-expired'
  },
  DEMO_PAGES: 'demo-pages'
};
