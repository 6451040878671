import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorageService } from './../../../core/services/token.service';
import { Observable, map } from 'rxjs';
import { AccountsAPIConstants } from '../api-constants/accounts-api-constants';
import { ApiHttpService } from './../../../core/services/api-http.service';
import { FormGroup } from '@angular/forms';
import { Package, PricePlan, Tenant } from '../model/account.model';

@Injectable()
export class AccountsService {
  tenantDataById: any;
  unsavedData: any;
  packageList: any;
  unSavedSubscription: any;
  profileFormStatus: any;
  subscriptionFormStatus: any;
  errorObj: any;
  constructor(
    private ApiHttpService: ApiHttpService,
    private tokenService: TokenStorageService,
    private url: AccountsAPIConstants,
    private http: HttpClient,

  ) {}
  /**
   * login api
   * @param req
   * @returns
   */
  getTenantList() {
    return this.ApiHttpService.get(this.url.endPoints['accounts'].accountsList);
  }
  getTenantListById(tenantId): Observable<Tenant> {
    return this.ApiHttpService.get(
      this.url.endPoints['accounts'].tenantProfile(tenantId)
    ).pipe(map(res => res['data'] as Tenant));
  }
  getPackageList(): Observable<Package[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'pageSize':'200', // currently static page size provided in future we can add virtual scroll over their
        'disable-loader':'true'
      }),
    };
    return this.ApiHttpService.get(this.url.endPoints['accounts'].packageList,httpOptions).pipe(map((res) => res['data']?.sort((a, b) => a?.title?.localeCompare(b?.title))));
  }
  getPricePlanByPackage(packageId: string): Observable<PricePlan[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'disable-loader':'true'
      }),
    };
    return this.ApiHttpService.get(this.url.endPoints['accounts'].pricePlansByPackage(packageId), httpOptions).pipe(map((res) => res['data']));
  }

  getQueAnswers(){
    return this.ApiHttpService.get(this.url.endPoints['accounts'].signup.questionnaire);
  }
  deleteTenant(id: string): Observable<any> {
    return this.ApiHttpService.delete(
      this.url.endPoints['accounts'].deleteTenant + id
    );
  }
  public getActionItemsData(): Observable<any> {
    return this.http.get(
      './../../../../assets/json/shared/floating_action_items.json'
    );
  }

  public getTenantProfileJSON(): Observable<any> {
    return this.http.get(
      './../../../../assets/json/accounts/tenant-profile.json'
    );
  }
  public getAccountsJSON(): Observable<any> {
    return this.http.get(
      './../../../../assets/json/accounts/accounts-list.json'
    );
  }
  public getTenantSubscritionJSON(): Observable<any> {
    return this.http.get(
      './../../../../assets/json/accounts/tenant-subscription.json'
    );
  }
  public getgridFilterslistJSON(): Observable<any> {
    return this.http.get(
      './../../../../assets/json/accounts/grid-filters-list.json'
    );
  }
  public createTenant(url, payload): Observable<any> {
    return this.http.post(url, payload);
  }
  public updateTenant(url, payload): Observable<any> {
    return this.http.patch(url, payload);
  }
  setPackagesList(value) {
    this.packageList = value;
  }
  setUnsavedSubscriptionForm(value) {
    this.unSavedSubscription = value;
  }
  getPackagesList() {
    return this.packageList;
  }
  getUnsavedSubscription() {
    return this.unSavedSubscription;
  }
  setTenantDataById(value) {
    this.tenantDataById = value;
  }
  getTenantInfo() {
    return this.tenantDataById;
  }
  setTenantUnsavedData(value) {
    this.unsavedData = value;
  }
  setTenantFormStatus(value) {
    this.profileFormStatus = value;
  }
  setSubscriptionFormStatus(value) {
    this.subscriptionFormStatus = value;
  }
  reStoreForm() {
    return this.unsavedData;
  }
  getProfileFormStatus() {
    return this.profileFormStatus;
  }
  getStubscriptionFormStatus() {
    return this.subscriptionFormStatus;
  }
  setCreateError(value: any) {
    this.errorObj = value;
  }
  getCreateError() {
    return this.errorObj;
  }

}
