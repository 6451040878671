export class HierarchySourceData {
  hierarchy = [
    {
      type: 'Hierarchy dropdown Group',
      html: `lib-rspl-hierarchy-dropdown
      [hierarchyList]="TREE_DATA"
      menuYPosition="below"
      menuXPosition="after"
      menuType="'custom'"
      (selectedHierarchy)="receivedMessageHandler($event)"
    >
    </lib-rspl-hierarchy-dropdown>`,

      ts: `import { HierarchyModel } from '@rspl/angular-deps-library/dist/rspl-angular-lib';
            import uiComponentsData from './json/ui-components.json'; //Json path is only for demo purpose. It may vary depending on your reqirement

            @Output() selectedHierarchy = new EventEmitter();
         /**
         *menu position with X Axis , available options are ('before' |       'after')
         *
         * @type {MenuPositionX}
         * @memberof RsplHierarchyDropdownComponent
         */
         @Input() menuXPosition: MenuPositionX = 'before';
         /**
          *menu position with Y Axis , available options are ('above' |       'below')
          *
          * @type {MenuPositionY}
          * @memberof RsplHierarchyDropdownComponent
          */
          @Input() menuYPosition: MenuPositionY = 'above';
         /**
          *menu position with Y Axis , available options are ('above' |       'below')
          *
          * @type {MenuPositionY}
          * @memberof RsplHierarchyDropdownComponent
          */
          @Input() hierarchyList: any;

            /**
             * Hierachy dropdown data
            */
            TREE_DATA: HierarchyModel[] | any = uiComponentsData.uiComponent.TREE_DATA; //data calling will be different as per user requirment`,
      json: ` "TREE_DATA": [
        {
          "label": "Workspace A",
          "type": "workspace",
          "name": "workspace-a",
          "children": [
            {
              "label": "Space 1",
              "type": "space",
              "name": "space-1",
              "children": [
                {
                  "label": "Project One",
                  "type": "project",
                  "name": "project-one",
                  "children": [
                    {
                      "label": "Section label 1",
                      "type": "section",
                      "name": "section-label-1"
                    },
                    {
                      "label": "Section label 2",
                      "type": "section",
                      "name": "section-label-2"
                    },
                    {
                      "label": "Section label 3",
                      "type": "section",
                      "name": "section-label-3"
                    }
                  ]
                },
                {
                  "label": "Project Two",
                  "type": "project",
                  "name": "project-two",
                  "children": [
                    {
                      "label": "Section label 4",
                      "type": "section",
                      "name": "section-label-4"
                    },
                    {
                      "label": "Section label 5",
                      "type": "section",
                      "name": "section-label-5"
                    },
                    {
                      "label": "Section label 6",
                      "type": "section",
                      "name": "section-label-6"
                    }
                  ]
                }
              ]
            },
            {
              "label": "Space 2",
              "type": "space",
              "name": "space-2",
              "children": [
                {
                  "label": "Project three",
                  "type": "project",
                  "name": "project-three",
                  "children": [
                    {
                      "label": "Section label 7",
                      "type": "section",
                      "name": "section-label-7"
                    },
                    {
                      "label": "Section label 8",
                      "type": "section",
                      "name": "section-label-8"
                    },
                    {
                      "label": "Section label 9",
                      "type": "section",
                      "name": "section-label-9"
                    }
                  ]
                },
                {
                  "label": "Project Four",
                  "type": "project",
                  "name": "project-four",
                  "children": [
                    {
                      "label": "Section label 10",
                      "type": "section",
                      "name": "section-label-10"
                    },
                    {
                      "label": "Section label 11",
                      "type": "section",
                      "name": "section-label-11"
                    },
                    {
                      "label": "Section label 12",
                      "type": "section",
                      "name": "section-label-12"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "label": "Workspace B",
          "type": "workspace",
          "name": "workspace-b",
          "children": [
            {
              "label": "Space 3",
              "type": "space",
              "name": "space-3",
              "children": [
                {
                  "label": "Project Five",
                  "type": "project",
                  "name": "project-five",
                  "children": [
                    {
                      "label": "Section label 13",
                      "type": "section",
                      "name": "section-label-13"
                    },
                    {
                      "label": "Section label 14",
                      "type": "section",
                      "name": "section-label-14"
                    },
                    {
                      "label": "Section label 15",
                      "type": "section",
                      "name": "section-label-15"
                    }
                  ]
                },
                {
                  "label": "Project Six",
                  "type": "project",
                  "name": "project-six",
                  "children": [
                    {
                      "label": "Section label 16",
                      "type": "section",
                      "name": "section-label-16"
                    },
                    {
                      "label": "Section label 17",
                      "type": "section",
                      "name": "section-label-17"
                    },
                    {
                      "label": "Section label 18",
                      "type": "section",
                      "name": "section-label-18"
                    }
                  ]
                }
              ]
            },
            {
              "label": "Space 4",
              "type": "space",
              "name": "space-4",
              "children": [
                {
                  "label": "Project Seven",
                  "type": "project",
                  "name": "project-seven",
                  "children": [
                    {
                      "label": "Section label 19",
                      "type": "section",
                      "name": "section-label-19"
                    },
                    {
                      "label": "Section label 20",
                      "type": "section",
                      "name": "section-label-20"
                    },
                    {
                      "label": "Section label 21",
                      "type": "section",
                      "name": "section-label-21"
                    }
                  ]
                },
                {
                  "label": "Project Eight",
                  "type": "project",
                  "name": "project-eight",
                  "children": [
                    {
                      "label": "Section label 22",
                      "type": "section",
                      "name": "section-label-22"
                    },
                    {
                      "label": "Section label 23",
                      "type": "section",
                      "name": "section-label-23"
                    },
                    {
                      "label": "Section label 24",
                      "type": "section",
                      "name": "section-label-24"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ];`,
    },
  ];
}
