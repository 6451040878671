export interface Button {
  id?: number;
  color?: string;
  iconColor?: string;
  label?: any;
  buttonType?: any;
  icon?: string;
}
export class ButtonSourceData {
  button = [
    {
      type: 'Button Group',
      html: `<lib-rspl-button
            class="button"
            *ngFor="let item of buttonList"
            [buttonType]="item.buttonType"
            [label]="item.label"
            [color]="item.color"
            [iconColor]="item.iconColor"
            [icon]="item.icon"
            class="item.button"
          ></lib-rspl-button>`,
      css: `.button{
                line-height: 2;
            }`,
      ts: `import { IButton } from './renderers/button';
            import uiComponentsData from './json/ui-components.json'; //Json path is only for demo purpose. It may vary depending on your reqirement

            @Input() color: string | any;
            /**
           *specified color of icon
           *
           *
           * @type {string}
           * @memberof RsplButtonComponent
           */
            @Input() iconColor: string | any;
            /**
             *label of button
             *
             * @type {string}
             * @memberof RsplButtonComponent
             */
            @Input() label: string = '';
            /**
             *different type of buttons available ,you can choose one of them
             *
             * @type {('button' | 'raised-button' | 'stroked-button' | 'flat-button'
             *     | 'icon-button' | 'fab' | 'mini-fab' | 'danger')}
             * @memberof RsplButtonComponent
             */
            @Input() buttonType:Button = 'button';
            /**
             *button with icon , need to specified icon name
             *
             * @type {string}
             * @memberof RsplButtonComponent
             */
            @Input() icon: string | any;
            /**
             * Icon Type for mat-icon
             */
             @Input() iconType = "outlined";
            /**
             *whether button is anabled or disabled
             *
             * @type {boolean}
             * @memberof RsplButtonComponent
             */
            @Input() disabled: boolean = false;
            /**
             *can set style from parent component
             *
             * @type {object}
             * @memberof RsplButtonComponent
             */
            @Input() styles: object | any;
            /**
             *button click event ,used to trigger event of parent component
             *
             * @memberof RsplButtonComponent
             */
            @Output() buttonClick = new EventEmitter();

            /**
             * Button list data
             */
            buttonList:IButton[] = uiComponentsData.uiComponent.buttonTypes;//data calling will be different as per user requirment`,
      json: `"buttonTypes":[
                {
                  "id":1,
                  "label":"Raised Button",
                  "buttonType":"raised-button"
                },
                {
                  "id":2,
                  "label":"Simple Button",
                  "buttonType":"button"
                },
                {
                  "id":3,
                  "label":"Stroked Button",
                  "buttonType":"stroked-button"
                },
                {
                  "id":4,
                  "label":"Flat Button",
                  "buttonType":"flat-button",
                  "color":"primary"
                },
                {
                  "id":5,
                  "label":"Icon Button",
                  "buttonType":"icon-button",
                  "icon":"home"
                },
                {
                  "id":6,
                  "label":"Fab Button",
                  "buttonType":"fab",
                  "icon":"star"
                },
                {
                  "id":7,
                  "label":"mini Fab Button",
                  "buttonType":"mini-fab",
                  "icon":"sort"
                },
                {
                  "id":8,
                  "label":"Icon and label Button",
                  "buttonType":"stroked-button",
                  "icon":"add"
                },
                {
                  "id":9,
                  "label":"Danger Button",
                  "buttonType":"danger"
                }
              ]`,
    },
  ];
}
