import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticationAPIConstants {
  public endPoints: object;
  constructor() {
    this.endPoints = {
      auth: {
        login: `api/security/login`,
        logout: `api/security/logout`,
        forgotPassword: (email: string) => {
          return `api/security/forgotcredentials/sendemail/${email}`
        },
        ValidateCode: (email: string, code: string) => {
          return `api/security/forgotcredentials/validatecode/email/${email}/code/${code}`
        },
        resetPassword: `api/security/forgotcredentials/resetPassword`,
        changePassword: `api/security/changepassword`,
        refreshToken: `api/security/refresh-token`
      },
      schemas: `api/schemadefinition/schemas`
    }
  }
}
