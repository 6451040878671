import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { MaterialModule } from 'src/material.module';
import { NgxTranslateModule } from '@app/shared/module/ngx-translate/ngx-translate.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import {
  RsplButtonModule,
  RsplMatIconModule,
  RsplMenuModule,
  RsplSidenavModule,
  RsplTextboxModule,
} from '@rspl/angular-deps-library/dist/rspl-angular-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { SidenavModule } from '@app/shared/module/sidenav/sidenav.module';

@NgModule({
  declarations: [
    LayoutComponent,
    NavbarComponent,
    HeaderComponent,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    MaterialModule,
    NgxTranslateModule,
    SidenavModule,
    RsplButtonModule,
    RsplTextboxModule,
    RsplSidenavModule,
    RsplMatIconModule,
    ReactiveFormsModule,
    RsplMenuModule
  ],
  providers: [],
})
export class LayoutModule {}
