import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { routerObj } from '../constants/router-constants';
import { TokenStorageService } from '../services/token.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private TokenService: TokenStorageService
  ) { }
  /**
   * only authenticate user can access authenticate urls
   * @param route
   * @param state
   * @returns
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    //   // console.log(route,state,"authguard")
    //   //check some condition
    //   const token = this.auth.getToken();
    //   const url = "/token/verify/";

    //   if (!token) {
    //     this.router.navigate(['/login']);
    //     return false;
    //   }

    //   return this.auth.verifyToken().pipe(
    //     catchError(err => {
    //       // console.log('Handling error locally and rethrowing it...', err);
    //       this.router.navigate(['/login']);
    //       return of(false);
    //     })
    //   );
    let token: string | null = this.TokenService.getToken();
    // let userData: loginResponseI | null = this.TokenService.getUser();
    // if (token && userData && userData.access_token === token) {
    //     return true;
    // }
    // else {
    //     this.router.navigate(['/login']);
    //     return false
    // }
    if (token) {
      return true;
    } else {
      this.router.navigate(['/' + routerObj.AUTH.AUTH + '/' + routerObj.AUTH.LOGIN]);
      return false;
    }
  }
}
