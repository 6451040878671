import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  /**
   *handling hide/show action for loader 
   *
   * @private
   * @memberof LoadingService
   */
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable().pipe(delay(1));

  constructor() { }
  /**
   * show loader
   */
  show() {
    this._loading.next(true);
  }
  /**
   * hide loader
   */
  hide() {
    this._loading.next(false);
  }
}
