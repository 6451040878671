export class InputSourceData {
  input = [
    {
      type: 'Input Group',
      html: `<lib-rspl-textbox
            [type]="text"
            [label]="'Input'"
            [placeholder]="'Input placeholder'"
          ></lib-rspl-textbox>`,

      ts: `/**
      * make textbox enabled or disabled
      *
      * @type {boolean}
      * @memberof FTextboxComponent
      */
     @Input() disabled: boolean = false;
     /**
      *set readyonly dynamically
      *
      * @type {boolean}
      * @memberof FTextboxComponent
      */
     @Input() isReadOnly: boolean = false;

     /**
      * label of button
      *
      * @type {string}
      * @memberof FTextboxComponent
      */
     @Input() label: string = '';
     /**
      * name param for textField
      *
      * @type {string}
      * @memberof FTextboxComponent
      */
     @Input() name: string = 'Input Field';
     /**
      *Dynamic Placeholder
      *
      * @type {string}
      * @memberof FTextboxComponent
      */
     @Input() placeholder: string = '';
     /**
   * Icon Tooltip Message
   */
     @Input() tooltipMessage: string = '';

     /**
   * Default Icon Tooltip Position
   */
     @Input() tooltipPosition: any = 'below';
     /**
      *hind for textfox
      *
      * @type {string}
      * @memberof FTextboxComponent
      */
     @Input() hint: string = '';

     /**
      * Type of textField
      *
      *
      * @type {('text' | 'email' | 'password' | 'tel')}
      * @memberof FTextboxComponent
      */
     @Input() type: 'text' | 'email' | 'password' | 'tel' | 'number' = 'text';
     /**
      * mat-formField Appereance e.g. 'legacy' | 'standard' | 'fill' | 'outline'
      *
      * @type {MatFormFieldAppearance}
      * @memberof FTextboxComponent
      */
     @Input() appearance: MatFormFieldAppearance = 'outline';
     /**
      *text field with icon as a prefix
      *
      * @type {string}
      * @memberof FTextboxComponent
      */
     @Input() icon: string = '';
     /**
      *dynamic class name
      *
      * @type {string}
      * @memberof FTextboxComponent
      */
     @Input() className: string = '';
     /**
      *blockCopyPaste functionality
      *
      * @type {string}
      * @memberof FTextboxComponent
      */
     @Input() blockCopyPaste: boolean = false;
     /**
      *field required or not
      *
      * @type {boolean}
      * @memberof FTextboxComponent
      */
     @Input() isRequired: boolean = false;
     /**
      *value of checkbox
      *
      * @type {(string | number)}
      * @memberof FTextboxComponent
      */
     @Input() value: any = '';
     /**
      *flag decide to show label or not
      *
      * @type {boolean}
      * @memberof FTextboxComponent
      */
     @Input() isLabelShow: boolean = true;
     /**
      *maximum length allowed in field
      *
      * @type {string}
      * @memberof FTextboxComponent
      */
     @Input() maxLength: string = '';
     /**
      *used to assign minimum value to type number
      *
      * @type {number}
      * @memberof FTextboxComponent
      */
     @Input() minNumber: number = 0;
     /**
      *used to assign maximum value to type number
      *
      * @type {number}
      * @memberof FTextboxComponent
      */
     @Input() maxNumber: number | any;
     @Input() isErrorShow: boolean = true;
     /**
      *form contorl for handling field properties
      *
      * @type {FormControl}
      * @memberof FTextboxComponent
      */
     textBoxControl: FormControl = new FormControl('');
     /**
     *To emit form data
     */
     @Output() formData: EventEmitter<any> = new EventEmitter<any>();
     /**
     * To emit input value
     */
     @Output() inputValue: EventEmitter<any> = new EventEmitter<any>();
     /**
     * Type of the input
     */
     @Input() inputtype: string = '';
     /**
      *focus event
      *
      * @type {EventEmitter<any>}
      * @memberof FTextboxComponent
      */
     @Output() focusEvent: EventEmitter<any> = new EventEmitter<any>();
     /**
      *blur event
      *
      * @type {EventEmitter<any>}
      * @memberof FTextboxComponent
      */
     @Output() blurEvent: EventEmitter<any> = new EventEmitter<any>();

     /**
   * Default value of the input box if any
   */
     @Input() defaultValue: string | any;



            `,
    },
  ];
}
