import { Component, ComponentRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { IComponent } from '@app/shared/model/component.model';
/**
 * The SideNav Component
 * ```html
 * <app-sidenav [sideNavData]="navData"></app-sidenav>
 *
 * ```
 *
 * The following is the screenshot of SideNav Component
 *
 *
  * ![screenshot-1](screenshots/sidenav.gif)
 */
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  /**
   * holds data which is coming from parent
   */
   @Input() sideNavData: any;
   /**
    * reference variable to display dynamic component
    */
   @ViewChild('dynamicLoadComponent') dynamicLoadComponent: ComponentRef<IComponent>;

   constructor(private viewContainerRef: ViewContainerRef) { }

   /**
    * Setting up the dynamic component & data
    */
   ngOnInit(): void {
    if (this.sideNavData) {
     this.dynamicLoadComponent = this.viewContainerRef.createComponent<IComponent>(this.sideNavData.componentName);
     this.dynamicLoadComponent.instance.data = this.sideNavData.data;
    }
   }

 }
