import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '@app/shared/module/dynamic-form/components/model/field.interface';

@Component({
  selector: 'app-f-dropdown-multi-select',
  templateUrl: './f-dropdown-multi-select.component.html',
  styleUrls: ['./f-dropdown-multi-select.component.scss'],
})
export class FBDropdownMultiSelectComponent {
  objectKeys = Object.keys;
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group: UntypedFormGroup;
  /**
   * to get default selected item(s) if any
   */

  constructor() { }

  /**
   * trackBy method for ngFor in html page (Multi select dropdown items list)
   */
  multiselectList(index: number) {
    return index;
  }
}
