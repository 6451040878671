import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'autofocus',
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) { }
  /**
   * on form load 'autofocus' selector will auto select the first input form the form.
   */
  ngAfterViewInit() {
    this.elementRef.nativeElement.focus();
  }
}
