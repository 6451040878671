export class CarosulSourceData {
  carousel = [
    {
      type: 'Carosoul',
      html: ` <lib-rspl-carousel [images]="images"></lib-rspl-carousel>`,
      css: ``,
      ts: `import {  ICarousel } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/carousel.model';

      @Input() images: Array<CarouselSlides> = [];

      images: ICarousel[]= [
        { path: 'https://wowslider.com/sliders/demo-93/data1/images/sunset.jpg' },
        {path:'https://res.cloudinary.com/practicaldev/image/fetch/s--MZvaMEOV--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/qll2w5atklv13ljmqope.png'},
        { path: 'https://wowslider.com/sliders/demo-93/data1/images/sunset.jpg' },
        {path:'https://res.cloudinary.com/practicaldev/image/fetch/s--MZvaMEOV--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/qll2w5atklv13ljmqope.png'}
      ];`,
      json: ` `,
    },
  ];
}
