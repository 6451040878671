<div class="custom-cls-uc-1">
  <form class="custom-cls-uc-2" [formGroup]="viewForm">
    <lib-rspl-radio-list
      class="float-right"
      (radioClick)="onTypeofDemoSelect($event)"
      [list]="viewType"
      id="view-type-radio"
      formControlName="timeEntryRadio"
    >
    </lib-rspl-radio-list>
  </form>
  <h2 class="custom-cls-uc-3">
    RSPL Library / {{ viewComponentName ? viewComponentName : "" }}
  </h2>
  <p class="custom-cls-uc-4">
    {{ selectedViewDescription }}
  </p>
  <div class="custom-cls-uc-5">
    <div class="custom-cls-uc-6">
      <ul class="custom-cls-uc-7">
        <li
          *ngFor="let item of itemList; let i = index"
          class="custom-cls-uc-8"
          (click)="onItemSelection(item)"
          [ngClass]="{ 'menu-active': viewComponent === item.value }"
        >
          <a style="text-decoration: none; color: #1e242f; font-weight: 500">{{
            item.name
          }}</a>
        </li>
      </ul>
    </div>
    <div class="custom-cls-uc-9">
      <div id="view-expansion">
        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel [expanded]="true" class="custom-cls-uc-10">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 style="margin: 0"><strong>Demo:</strong></h3>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div [ngSwitch]="viewComponent" class="expansion-panel-01">
              <div *ngSwitchCase="'input'">
                <div>
                  <h4>Input Group</h4>
                  <lib-rspl-textbox
                    [type]="'text'"
                    [label]="'Input'"
                    [placeholder]="'Input placeholder'"
                  ></lib-rspl-textbox>
                </div>
              </div>
              <form [formGroup]="radioForm">
              <div *ngSwitchCase="'radio'" class="custom-cls-uc-11">
                <div class="list-item">
                  <h4 *ngIf="radioForm">Simple Radio Button Group</h4>
                  <lib-rspl-radio-list
                    class="float-left"
                    [list]="simpleRadioList"
                    id="view-type-radio"
                    [iconRequired]="true"
                    formControlName="radiobutton1"
                  >
                  </lib-rspl-radio-list>
                  <hr />
                </div>

                <div class="list-item">
                  <h4 *ngIf="radioForm" >Radio Icon Button Group</h4>
                  <lib-rspl-radio-list
                    class="float-left"
                    [list]="iconRadioList"
                    id="view-type-radio"
                    [iconRequired]="true"
                    formControlName="radiobutton2"
                  >
                  </lib-rspl-radio-list>
                  <hr />
                </div>
                <div class="list-item">
                  <h4 *ngIf="radioForm">Tooltip Icon Button Group</h4>
                  <lib-rspl-radio-list
                    class="float-left"
                    [list]="iconTooltipRadioList"
                    id="view-type-radio"
                    [iconRequired]="true"
                    formControlName="radiobutton3"
                  >
                  </lib-rspl-radio-list>
                </div>
              </div>
            </form>
              <div *ngSwitchCase="'checkbox'" class="custom-cls-uc-11">
                <div class="list-item">
                  <h4>Checkbox Group</h4>
                  <lib-rspl-checkbox
                    *ngFor="let item of Checkbox; let i = index"
                    labelPosition="after"
                    class="checkBox"
                    [label]="item.label"
                    id="checkBoxId {{ i }}"

                  ></lib-rspl-checkbox>
                </div>
              </div>
              <div *ngSwitchCase="'button'">
                <div class="list-item">
                  <h4>Button Group</h4>
                  <div style="line-height: 4">
                    <lib-rspl-button
                      *ngFor="let item of buttonList; let i = index"
                      [buttonType]="item.buttonType"
                      [label]="item.label"
                      [color]="item.color"
                      [iconColor]="item.iconColor"
                      [icon]="item.icon"
                      class="item.button fs-14"
                    ></lib-rspl-button>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="'singleSelectDropdown'">
                <div>
                  <lib-rspl-dropdown-singleselect
                    [dropdownList]="dropdownList"
                  ></lib-rspl-dropdown-singleselect>
                </div>
              </div>
              <!-- <div
                *ngSwitchCase="'multiSelectDropdown'"
                class="custom-cls-uc-11"
              >
                <div class="list-item">
                  <h4>Multi Select Dropdown</h4>
                  <lib-rspl-dropdown-multiselect
                    [dropdownList]="dropdownList"
                  ></lib-rspl-dropdown-multiselect>
                </div>
              </div> -->
              <div *ngSwitchCase="'icon'" class="custom-cls-uc-11">
                <div class="list-item">
                  <h4>Icon Group</h4>
                  <lib-rspl-mat-icon [iconName]="'home'"></lib-rspl-mat-icon>
                  <hr />
                </div>
                <div class="list-item">
                  <h4>Icon Group with tool tip Message</h4>
                  <lib-rspl-mat-icon
                    [iconName]="'home'"
                    tooltipMessage="home tooltip"
                  ></lib-rspl-mat-icon>
                </div>
              </div>
              <div *ngSwitchCase="'datepicker'">
                <div class="picker">
                  <h4>Date Picker Group</h4>
                  <form [formGroup]="dateForm">
                    <span
                      class="{{ dateFormDetails.startDate.className }}"
                    ></span>
                    <lib-rspl-date-picker
                      formControlName="startDate"
                    ></lib-rspl-date-picker>
                  </form>
                </div>
              </div>
              <div *ngSwitchCase="'draglist'">
                <div>
                  <h4>Drag list</h4>
                  <lib-rspl-drag-list
                    [list]="dragList"
                    [type]="'projectDesign'"
                  ></lib-rspl-drag-list>
                </div>
              </div>
              <div *ngSwitchCase="'hierarchy'">
                <div></div>
                <h4>Hierarchy Dropdown</h4>
                <lib-rspl-hierarchy-dropdown
                  [hierarchyList]="TREE_DATA"
                  menuYPosition="below"
                  menuXPosition="after"
                  menuType="'custom'"
                >
                </lib-rspl-hierarchy-dropdown>
              </div>
              <div *ngSwitchCase="'tabs'">
                <div>
                  <h4>Tabs Group</h4>
                  <lib-rspl-tab-group
                    [dataSource]="tabsList"
                  ></lib-rspl-tab-group>
                </div>
              </div>
              <div *ngSwitchCase="'textarea'">
                <div>
                  <h4>Text Area</h4>
                  <lib-rspl-textarea
                    [label]="'Text Area label'"
                    [value]="'Test text area'"
                  ></lib-rspl-textarea>
                </div>
              </div>
              <div *ngSwitchCase="'menu'">
                <div>
                  <h4>Menu Group</h4>
                  <lib-rspl-menu
                    [menuItems]="menuItems"
                    [menuYPosition]="'below'"
                    [menuXPosition]="'after'"
                  >
                    <lib-rspl-mat-icon
                      [iconType]="'outlined'"
                      [iconName]="'more_vert'"
                    >
                    </lib-rspl-mat-icon>
                  </lib-rspl-menu>
                </div>
              </div>
              <div *ngSwitchCase="'chipmenu'">
                <div>
                  <h4>Selectable Menu Chip</h4>
                  <lib-rspl-selectable-menu-chip
                    [chipData]="chipData"
                    [selectedItem]="selectebleChipWithMenu"
                    (ChipSelect)="chipSelect($event)"
                  ></lib-rspl-selectable-menu-chip>
                </div>
              </div>
              <div *ngSwitchCase="'accordian'">
                <div>
                  <h4>Accordian Group</h4>
                  <lib-rspl-accordian
                    [accordionData]="accordianData"
                  ></lib-rspl-accordian>
                </div>
              </div>
              <div *ngSwitchCase="'popup'">
                <div>
                  <h4>Popup</h4>
                  <lib-rspl-button
                    [id]="'invite-new'"
                    [icon]="'add'"
                    class="float-left"
                    [buttonType]="'flat-button'"
                    color="primary"
                    label="Add"
                    (buttonClick)="openPopup()"
                  >
                  </lib-rspl-button>
                </div>
              </div>
              <div *ngSwitchCase="'profileIcon'">
                <div>
                  <h4>Small Profile Icon</h4>

                  <lib-rspl-profile-icon
                    [src]="'assets/images/image2.jpg'"
                    alt="test"
                    size="small"
                  >
                  </lib-rspl-profile-icon>
                  <hr />
                </div>
                <div>
                  <h4>Medium Profile Icon</h4>
                  <lib-rspl-profile-icon
                    [src]="'assets/images/image2.jpg'"
                    alt="test"
                    size="medium"
                  >
                  </lib-rspl-profile-icon>
                  <hr />
                </div>
                <div>
                  <h4>Large Profile Icon</h4>
                  <lib-rspl-profile-icon
                    [src]="'assets/images/image2.jpg'"
                    alt="test"
                    size="large"
                  >
                  </lib-rspl-profile-icon>
                </div>
              </div>
              <div *ngSwitchCase="'progressBar'">
                <div>
                  <h4>Progress Bar</h4>
                  <hr />
                  <strong>40% Completed</strong>
                  <lib-rspl-progress-bar
                    id="progress-bar-project"
                    [bufferValue]="10"
                    barColor="green"
                    [value]="40"
                  >
                  </lib-rspl-progress-bar>
                </div>
              </div>
              <div *ngSwitchCase="'popover'">
                <h4>Popover</h4>

                <div style="margin-left: 120px">
                  <br />
                  <lib-rspl-popover position="bottom" #popover>
                    <lib-rspl-button
                      [buttonType]="'flat-button'"
                      [label]="'Popover'"
                      [color]="'primary'"
                    ></lib-rspl-button>

                    <br />
                    <div modalMenu>
                      <h1 class="person-list__item">This is Popover</h1>
                      <h2>This is Popover</h2>
                      <h3>This is Popover</h3>
                      <h4>This is Popover</h4>
                      <h5>This is Popover</h5>
                      <h6>This is Popover</h6>
                    </div>
                  </lib-rspl-popover>
                </div>
              </div>
              <div *ngSwitchCase="'slideToggle'">
                <h4>Slide Toggle</h4>

                <div>
                  <lib-rspl-slide-toggle
                    [slideToggleLabel]="'Toggle'"
                  ></lib-rspl-slide-toggle>
                </div>
              </div>
              <div *ngSwitchCase="'badge'">
                <h4>Badge</h4>

                <div style="margin-top: 20px; margin-left: 50px">
                  <lib-rspl-badge [matBadgeCount]="4" [matBadgeSize]="'large'">
                    <lib-rspl-button
                      [buttonType]="'flat-button'"
                      [label]="'Badge'"
                      [color]="'primary'"
                      style="margin-right: 100px"
                    ></lib-rspl-button>
                  </lib-rspl-badge>
                </div>
              </div>
              <div *ngSwitchCase="'chipSet'">
                <div>
                  <h4>OneLine Chip Set</h4>

                  <lib-rspl-chip-set
                    [dataSource]="dataSource"
                  ></lib-rspl-chip-set>
                  <hr />
                  <h4>Stacked chip set</h4>
                  <lib-rspl-chip-set
                    [dataSource]="dataSource"
                    [chipType]="'stacked'"
                  ></lib-rspl-chip-set>
                </div>
              </div>


              <div *ngSwitchCase="'nestedMenu'">
                <div>
                  <h4>Nested Menu</h4>
                  <lib-rspl-nested-menu
                    [navItems]="nestedMenuu"
                  ></lib-rspl-nested-menu>
                </div>
              </div>
              <div *ngSwitchCase="'loader'">
                <div>
                  <h4>Loader</h4>
                  <lib-rspl-loader [loading]="true"></lib-rspl-loader>
                </div>
              </div>
              <div *ngSwitchCase="'list'">
                <div>
                  <lib-rspl-list
                    [listData]="listData"
                    [listLabel]="'Demo List'"
                  ></lib-rspl-list>
                </div>
              </div>
              <div *ngSwitchCase="'cards'">
                <div>
                  <h4>Chip Card</h4>
                  <lib-rspl-chip-card
                    [cardContent]="cardContent"
                  ></lib-rspl-chip-card>
                  <hr />
                </div>
                <div>
                  <h4>Layout Card</h4>
                  <lib-rspl-layout-card [cardLabel]="'Layout Card'">
                    <h1>Card 1</h1>
                    <h2>Card 2</h2>
                  </lib-rspl-layout-card>
                </div>
              </div>
              <div *ngSwitchCase="'charts'">
                <div>
                  <h4>Donut Chart</h4>
                  <lib-rspl-chart-donut
                    [data]="pieChartData"
                    [chartConfiguration]="regularChart"
                  ></lib-rspl-chart-donut>
                  <hr />
                </div>
                <div>
                  <h4>Line Chart</h4>
                  <lib-rspl-chart-line
                    [lineGraphData]="lineGraphData"
                  ></lib-rspl-chart-line>
                  <hr />
                </div>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true" class="custom-cls-uc-12">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 style="margin: 0"><strong>Source:</strong></h3>
              </mat-panel-title>
              <!-- <mat-panel-description>
                      Type your name and age
                      <mat-icon>account_circle</mat-icon>
                    </mat-panel-description> -->
            </mat-expansion-panel-header>
            <p class="custom-cls-uc-13">API Reference</p>
            <pre><code [highlight]="apiReference"></code></pre>
            <div
              *ngFor="let item of sourceItems; let i = index"
              class="custom-cls-uc-14"
            >
              <h4>
                <strong>{{ item.type }}</strong>
              </h4>
              <mat-tab-group animationDuration="0ms">
                <mat-tab label="HTML">
                  <pre><code [highlight]="item.html"></code></pre>
                </mat-tab>
                <mat-tab label="Typescript">
                  <pre><code [highlight]="item.ts"></code></pre>
                </mat-tab>
                <mat-tab label="CSS">
                  <pre><code [highlight]="item.css"></code></pre>
                </mat-tab>
                <mat-tab label="Json">
                  <pre><code [highlight]="item.json"></code></pre>
                </mat-tab>
              </mat-tab-group>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
