import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-msg-popup',
  templateUrl: './functional-popup.component.html',
  styleUrls: ['./functional-popup.component.scss']
})
export class FunctionalPopupComponent {

  /**
    * it holds if any data is coming from parent
    */
  data: any;
  constructor(public dialogRef: MatDialogRef<FunctionalPopupComponent>) {
  }
  /**
   * this method is to close the popup without updated values
   */
  closePopup(action: string) {
    this.dialogRef.close(action);
  }
}