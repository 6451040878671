import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { USER_STORE } from '@app/core/constants/index-DB.constants';
import { StorageService } from '@app/core/services/storage.service';
import { ComponentModel } from '@app/shared/model/component.model';
import { CommonService } from '@app/shared/services/common.service';
import { PopupService } from '../../../shared/services/popup/popup.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { IndexdbService } from '@app/core/services/indexdb.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  idDataDumb: boolean = false;
  dataStorage: StorageService;
  navData: ComponentModel = { componentName: NavbarComponent, data: {} };

  constructor(
    private injector: Injector,
    private dbService: IndexdbService,
    private commonService: CommonService,
    private popupService: PopupService
  ) {

  }
  ngOnInit(): void {
    this.storeIndexDBIntoVariables();
  }
  /**
   * used to store indexDB data into global variables
   */
  storeIndexDBIntoVariables() {
    this.dbService.getAll(USER_STORE).subscribe((result) => {
      this.idDataDumb = true;
      this.dataStorage = this.injector.get(StorageService);
      this.dataStorage.updateAllLocalVariables(result);
      this.commonService.setSchemaDataIntoVar(this.dataStorage.schema);
    })

  }
  /**
   * action perform when click on logout button from popup
   */
  logout() {
    this.popupService.dialogRef.close('logout');

  }
  /**
   * close worning popup
   */
  closePopup() {
    this.popupService.dialogRef.close(true)
  }
  ngOnDestroy(): void {
  }

}
