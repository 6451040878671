<lib-rspl-layout-card>
	<h2 class="text-center mb-5 fs-32 text-bold">{{ 'auth.forgotPasswordPage.forgotPassword' | translate }}</h2>
	<form [formGroup]="forgotPasswordForm">
		<ul class="form-element-wrap list-style-none p-0 m-0">
			<li class="form-element mb-1">
				<lib-rspl-textbox className="with-bg fs-14 show-error" type="email" id="email" name="email" label="Email" [blockCopyPaste]="true"
					[isLabelShow]="false" maxLength="128" placeholder="Email" formControlName="username">
				</lib-rspl-textbox>
			</li>
			<li class="form-element felem-submit">
				<div class="mdc-touch-target-wrapper">
					<lib-rspl-button [styles]="buttonWidth" id="forgotPasswordButton" buttonType="flat-button"
						color="primary" label="{{ 'auth.forgotPasswordPage.sendResetLink' | translate }}"
						(buttonClick)="onSubmit()">
					</lib-rspl-button>
				</div>
			</li>
		</ul>
	</form>

</lib-rspl-layout-card>
<a [routerLink]="['/'+ routerObj.AUTH.AUTH + '/' + routerObj.AUTH.LOGIN]" id="backToLoginLink"
	routerLinkActive="router-link-active" title="{{ 'auth.forgotPasswordPage.backToLogin' | translate }}"
	class="p-2 ghost-btn br-xs color-primary fs-14 d-table mt-1 mx-auto">{{
	'auth.forgotPasswordPage.backToLogin' | translate }}</a>
