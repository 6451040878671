<form class="d-flex dynamic-form-tag fs-14 flex-items-start" [formGroup]="group" *ngIf="field && field.show">
  <mat-label [id]="'label-' + field.id" class="fs-14 mb-0 mt-1a col-3 o-60 pr-1">{{field.label}}<span *ngIf="field.required">*</span></mat-label>
  <mat-form-field appearance="fill" *ngIf="field.countryCodeRequired" class="dynamic-form__field col-2 pr-2">
    <mat-select placeholder="+" (selectionChange)="createCountryCodeControl(field.name)"
      [(value)]="field.defaultCountryCode.code">
      <mat-option *ngFor="let item of countryCodes;" [value]="item.code">+{{item.code}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="dynamic-form__field flex-1" [appearance]="appearance" [id]="field.id">
    <div>
      <input matInput autofocus [formControlName]="field.name" [pattern]="field.validations?.pattern"
      [value]="field.value" [readonly]="field.disabled || field.disabled" minlength="{{field.minLength}}"
      maxlength="{{field.maxLength}}" placeholder="{{field.placeHolder}}" type="{{field.inputType}}"
      required="{{field.required ? field.required : false}}" (input)="phoneNumberFormat(field.name)"
      (blur)="createCountryCodeControl(field.name)" [id]="'form_mat_input_'+field.name">
    </div>
    <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
      <mat-error *ngIf="group.get(field.name)?.hasError(validation.name)">{{validation.message}}</mat-error>
    </ng-container>
    <ng-container ngProjectAs="mat-error">
      <mat-error *ngIf="group.get(field.name)?.hasError('custom')">{{group.get(field.name)?.errors?.['custom']}}</mat-error>
    </ng-container>
  </mat-form-field>
  <label class="fs-14 mb-0 mt-1a col-3 o-60 pl-2" [id]="field.name + '_tailLabel'" *ngIf="field.tailLabel">{{field.tailLabel}}</label>

</form>
