export class FabMenuSourceData {
  fabMenu = [
    {
      type: 'Fab Menu',
      html: `  <lib-rspl-fab-menu
      [fabButtonList]="fabBtnList"
      [direction]="'bottom'"
    ></lib-rspl-fab-menu>`,
      css: ``,
      ts: `import uiComponentsData from './json/ui-components.json';
      import { MatFabMenu } from '@angular-material-extensions/fab-menu';

      /**
   *array of floating actions button as menu to render
   *
   * @type {MatFabMenu[]}
   * @memberof FabMenuComponent
   */
   @Input() fabButtonList: MatFabMenu[] | any;
   /**
    *Close after selecting a fab menu item
    *
    * @type {boolean}
    * @memberof FabMenuComponent
    */
   @Input() closeAfterSelection: boolean = true;
   /**
    *Whether the component is disabled
    *
    * @type {boolean}
    * @memberof FabMenuComponent
    */
   @Input() isDisabled: boolean = false;
   /**
    *Whether the component is active
    *
    * @type {boolean}
    * @memberof FabMenuComponent
    */
   @Input() isActive: boolean = false;
   /**
    *the color of the main fab: primary, accent or warn
    *
    * @type {ThemePalette}
    * @memberof FabMenuComponent
    */
   @Input() color: ThemePalette = 'warn';
   /**
    *the direction of buttons: "top" | "bottom" | "left" | "right"
    *
    * @type {MatFabMenuDirection}
    * @memberof FabMenuComponent
    */
   @Input() direction: MatFabMenuDirection = 'right';
   /**
    *material icon string e.g: home, mail, phone
    *
    * @type {string}
    * @memberof FabMenuComponent
    */
   @Input() icon: string = 'add';
   @Output() fabMenuSelection = new EventEmitter();

      fabBtnList: MatFabMenu[] =  uiComponentsData.uiComponent.fabMenu`,
      json: ` "fabMenu": [
        {
          "id": 1,
          "icon": "lightbulb_outline"
        },
        {
          "id": 2,
          "icon": "timeline"
        },
        {
          "id": 3,
          "icon": "view_headline"
        },
        {
          "id": 4,
          "icon": "room"
        }
      ] `,
    },
  ];
}
