import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html-pipe/safe-html.pipe';
import { PriceFromPriceplanPipe } from './price-from-priceplan/price-from-priceplan.pipe';


@NgModule({
  declarations: [
    SafeHtmlPipe,
    PriceFromPriceplanPipe
  ],
  exports: [
    SafeHtmlPipe,
    PriceFromPriceplanPipe
  ],
  providers: [
    PriceFromPriceplanPipe
  ]
})
export class PipesModule { }
