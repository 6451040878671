export class MenuSourceData {
  menu = [
    {
      type: 'Menu Group',
      html: ` <lib-rspl-menu
            [menuItems]="menuItems"
            [menuYPosition]="'below'"
            [menuXPosition]="'after'"
          >
            <lib-rspl-mat-icon
              [iconType]="'outlined'"
              [iconName]="'more_vert'"
            >
            </lib-rspl-mat-icon>
          </lib-rspl-menu>`,
      css: ``,
      ts: `import { Menu } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/menu.model';
            import uiComponentsData from './json/ui-components.json'; //Json path is only for demo purpose. It may vary depending on your reqirement

            /**
             *list of all menu items with params (label, icon, isDisabled)
             *
             * @type {Menu[]}
             * @memberof RsplMenuComponent
             */
             @Input() menuItems: any;
             /**
              *menu position with X Axis , available options are ('before' | 'after')
              *
              * @type {MenuPositionX}
              * @memberof RsplMenuComponent
              */
             @Input() menuXPosition: MenuPositionX = 'before';
             /**
              *menu position with Y Axis , available options are ('above' | 'below')
              *
              * @type {MenuPositionY}
              * @memberof RsplMenuComponent
              */
             @Input() menuYPosition: MenuPositionY = 'above';
            /**
             * unique id for mat-menu
             */
             @Input() menuId: string = 'menuId';

             /**
              *action on menu item click , is passes menu item "label" as a param to parent
              *
              * @memberof RsplMenuComponent
              */
             @Output() menuItemClick = new EventEmitter();
             /**
              * To hold already selected item if any
              */
             activeItem = { label: '' };

            /**
             * Menu Data
             */
            menuItems: Menu[] = uiComponentsData.uiComponent.menuList;`,
      json: `  "menuList":[
                {
                  "id":1,
                  "label":"Menu",
                  "isDisabled": false
                },
                {
                  "id":1,
                  "label":"Disable Menu",
                  "isDisabled": true
                },
                {
                  "id":1,
                  "label":"Menu Icon",
                  "isDisabled": false,
                  "icon":"home"
                }
              ]`,
    },
  ];
}
