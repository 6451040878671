import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  /**
   *heaser label 
   *
   * @type {(string | null)}
   * @memberof HeaderComponent
   */
  @Input() headerLabel: string | null;
  constructor() { }

}
