export class BadgeSourceData {
  badge = [
    {
      type: 'Badge',
      html: ` <lib-rspl-badge [matBadgeCount]="4" [matBadgeSize]="'large'" >
      <lib-rspl-button
      [buttonType]="'flat-button'"
      [label]="'Badge'"
      [color]="'primary'"
      style="margin-right: 100px;"
    ></lib-rspl-button>
    </lib-rspl-badge>`,
      css: ``,
      ts: `/**
      *pass no that we want to show
      *
      * @type {number}
      * @memberof RsplBadgeComponent
      */
      @Input() matBadgeCount: number | any;
      /**
       *size of badge ( small | medium | large)
       *
       * @type {MatBadgeSize}
       * @memberof RsplBadgeComponent
       */
      @Input() matBadgeSize: MatBadgeSize = "small";
      /**
       *position of badge ("above after" | "above before" | "below before" | "below after" | "before" | "after" | "above" | "below")
       *
       * @type {MatBadgePosition}
       * @memberof RsplBadgeComponent
       */
      @Input() matBadgePosition: MatBadgePosition = "after";
      /**
       *color of badge ("primary" | "accent" | "warn" | undefined)
       *
       * @type {ThemePalette}
       * @memberof RsplBadgeComponent
       */
      @Input() matBadgeColor: ThemePalette = "warn";
      /**
       *customize stylesheet
       *
       * @type {object}
       * @memberof RsplBadgeComponent
       */
      @Input() style: object | any;`,
      json: ` `,
    },
  ];
}
