<lib-rspl-layout-card>
	<h2>{{ 'auth.resetPasswordPage.resetPassword' | translate }}</h2>
	<form [formGroup]="passwordResetForm">
		<ul class="form-element-wrap">
			<li class="form-element full">
				<lib-rspl-textbox className="form-Field" id="password" (focusEvent)="focusPassword()" [isErrorShow]="false"
					label="New Password" type="password" name="password" placeholder="New Password" [blockCopyPaste]="true"
					[isLabelShow]="false" formControlName="password" [isRequired]="true" maxLength="16">
				</lib-rspl-textbox>
				<div class="pwd-hint" *ngIf="passwordResetForm.get('password')?.touched">
					<div class="pwd-hint__status pwd-hint__status--{{pswStatus}}"
						*ngIf="passwordResetForm.get('password')?.valid">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						{{pswStatus}}
					</div>
					<p *ngIf="passwordResetForm.get('password')?.touched && passwordResetForm.get('password')?.invalid">
						{{ 'auth.passwordPolicy.passwordShouldHave'
						| translate }}:</p>
					<ul *ngIf="passwordResetForm.get('password')?.invalid">
						<li [ngClass]="{active: matchPass('atleast 6')}"><i class="gg-check"></i>
							{{ 'auth.passwordPolicy.sixCharactersAtleast' | translate }}
						</li>
						<li [ngClass]="{active: matchPass('1 uppercase')}"><i class="gg-check"></i>
							{{ 'auth.passwordPolicy.oneUppercase' | translate }}</li>
						<li [ngClass]="{active: matchPass('1 lowercase')}" id="lowercase"><i class="gg-check"></i>
							{{ 'auth.passwordPolicy.oneLowerCase' | translate }}</li>
						<li [ngClass]="{active: matchPass('1 number')}"><i class="gg-check"></i>
							{{ 'auth.passwordPolicy.oneNumber' | translate }}</li>
						<li [ngClass]="{active: matchPass('1 special charecter')}"><i class="gg-check"></i>
							{{ 'auth.passwordPolicy.oneSpecialCharacter' | translate }}</li>
					</ul>
				</div>
			</li>
			<li class="form-element full">
				<lib-rspl-textbox className="form-Field" id="confirmPassword" type="password" name="confirmPassword"
					label="Confirm Password" [isLabelShow]="false" placeholder="Confirm Password" [blockCopyPaste]="true"
					(blurEvent)="blurConfirmPassword()" formControlName="confirmPassword" [isRequired]="true"
					maxLength="16" #confirmPassword>
				</lib-rspl-textbox>
			</li>
			<li class="form-element full felem-submit">
				<div class="mdc-touch-target-wrapper">
					<lib-rspl-button id="saveNewPasswordButton" [styles]="buttonWidth" buttonType="flat-button"
						color="primary" label="{{ 'auth.resetPasswordPage.saveNewPassword' | translate }}"
						(buttonClick)="onSubmit()">
					</lib-rspl-button>
				</div>
			</li>
		</ul>
	</form>
</lib-rspl-layout-card>
