import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry, throwError } from 'rxjs';
import { environment } from '@environments/environment';
@Injectable()
export class ApiHttpService {

  constructor(private http: HttpClient) {
  }
  /**
   * method is intermediate between api call with get method it accept 2 params 1. url (path of api) 2.(header options)
   *
   *
   * @param url
   * @param options
   * @returns
   */
  public get(url: string, options?: object) {
    //API_URL_LOCAL is only for development purpose with local mock json file
    const domain = (url.includes('.json')) ? environment.API_URL_LOCAL : environment.API_URL;

    return this.http.get(domain + url, options).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * method is intermediate between api call with post method it accept 3 params 1. url (path of api) 2. (data want to pass) 3.(header options)
   *
   *
   * @param url
   * @param data
   * @param options
   * @returns
   */
  public post(url: string, data: any, options?: object) {
    //API_URL_LOCAL is only for development purpose with local mock json file
    const domain = (url.includes('.json')) ? environment.API_URL_LOCAL : environment.API_URL;
    return this.http.post(domain + url, data, options).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * method is intermediate between api call with put method it accept 3 params 1. url (path of api) 2. (data want to pass) 3.(header options)
   *
   *
   * @param url
   * @param data
   * @param options
   * @returns
   */
  public put(url: string, data: any, options?: object) {
    //API_URL_LOCAL is only for development purpose with local mock json file
    const domain = (url.includes('.json')) ? environment.API_URL_LOCAL : environment.API_URL;
    return this.http.put(domain + url, data, options).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * method is intermediate between api call with patch method it accept 3 params 1. url (path of api) 2. (data want to pass) 3.(header options)
   *
   * @param url
   * @param data
   * @param options
   * @returns
   */
  public patch(url: string, data: any, options?: object) {
    //API_URL_LOCAL is only for development purpose with local mock json file
    const domain = (url.includes('.json')) ? environment.API_URL_LOCAL : environment.API_URL;
    return this.http.patch(domain + url, data, options).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * method is intermediate between api call with delete method it accept 2 params 1. url (path of api) 2.(header options)
   *
   *
   * @param url
   * @param options
   * @returns
   */
  public delete(url: string, options?: object) {
    //API_URL_LOCAL is only for development purpose with local mock json file
    const domain = (url.includes('.json')) ? environment.API_URL_LOCAL : environment.API_URL;
    return this.http.delete(domain + url, options).pipe(
      catchError(this.handleError)
    );
  }
  /**
   * method for handling common api error, it accept 1 params 1. error object
   *
   *
   * @param error
   * @returns
   */
  handleError(error: ErrorEvent) {
    let errorData: object | string = '';
    if (error && error.error) {
      // client-side error
      errorData = error.error;
    }
    else {
      // server-side error
      // errorMessage = error.error && error.error.message
      errorData = error && error.message
    }
    //errorData && console.log(error.error, "errorMessage")
    return throwError(errorData);
  }
}
