export class IconSourceData {
  icon = [
    {
      type: 'Icon Group',
      html: `  <lib-rspl-mat-icon [iconName]="'home'"></lib-rspl-mat-icon>`,

      ts: `
      /**
       * Icon Name
       */
      @Input() iconName: string;
      /**
       * Icon style class
       */
      @Input() cssClass: string ;
      /**
       * unique Id for mat-icon
       */
      @Input() id: string;

      /**
       * icon type
       */
      @Input() iconType: string = 'outlined';
      /**
       * Icon color
       */
      @Input() iconColor: string;
      /**
       * Icon Tooltip Message
       */
      @Input() tooltipMessage: string;

      /**
       * Default Icon Tooltip Position
       */
      @Input() tooltipPosition: any = 'right';

     `,
    },
    {
      type: 'Icon Tooltip Group',
      html: ` <lib-rspl-mat-icon
      [iconName]="'home'"
      tooltipMessage="home tooltip"
      ></lib-rspl-mat-icon>`,

      ts: `
      /**
       * Icon Name
       */
      @Input() iconName: string;
      /**
       * Icon style class
       */
      @Input() cssClass: string ;
      /**
       * unique Id for mat-icon
       */
      @Input() id: string;

      /**
       * icon type
       */
      @Input() iconType: string = 'outlined';
      /**
       * Icon color
       */
      @Input() iconColor: string;
      /**
       * Icon Tooltip Message
       */
      @Input() tooltipMessage: string;

      /**
       * Default Icon Tooltip Position
       */
      @Input() tooltipPosition: any = 'right';

     `,
    },
  ];
}
