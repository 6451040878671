export class TextareaSourceData {
  textarea = [
    {
      type: 'Text Area',
      html: ` <lib-rspl-textarea
      [textareaLabel]="'Text Area label'"
      [textareaValue]="'Test text area'"
    ></lib-rspl-textarea>`,
      css: ``,
      ts: `/**
      *assign id to specific textarea
      *
      * @type {string}
      * @memberof FTextareaComponent
      */
      @Input() id: string = 'textarea';

      /**
       * Label for the textarea
       */
      @Input() textareaLabel: string = '';

      /**
       * placeholder for the textarea
       */
      @Input() textareaPlaceholder: string = '';

      /**
       * default/updated value of the textarea
       */
      @Input() textareaValue: string = '';

      /**
       * matform field appearance
       */
      appearance: MatFormFieldAppearance = 'outline';

      /**
       * form control for handling field property
       */
      @Input() name: string = 'Input Field';
      /**
       *label for show form error
       *
       * @type {string}
       * @memberof StaticFTextareaComponent
       */

      @Input() label: string = '';
      /**
     *set readyonly dynamically
     *
     * @type {boolean}
     * @memberof FTextboxComponent
     */
      @Input() isReadOnly: boolean = false;
      @Input() maxLength: string = '';
      @Input() isLabelShow: boolean = true;`,
      json: ``,
    },
  ];
}
