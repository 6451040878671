import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/material.module';
import { PipesModule } from '../core/pipes/pipes.module';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { NgxChartsModule, } from '@swimlane/ngx-charts';
import { WebcamModule } from 'ngx-webcam';
import { BlockCopyPasteDirective } from './directives/blockCopyPasteDirective.directive';
import { ErrorDirective } from './directives/error.directive';
import { NgxTranslateModule } from './module/ngx-translate/ngx-translate.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { DynamicFormModule } from './module/dynamic-form/dynamic-form.module';
import { AccountsService } from '@app/modules/accounts/services/account-forms.service';
import { AccountsAPIConstants } from '@app/modules/accounts/api-constants/accounts-api-constants';
import { RsplButtonModule, RsplTextboxModule } from '@rspl/angular-deps-library/dist/rspl-angular-lib';


@NgModule({
  declarations: [
    BlockCopyPasteDirective,
    ErrorDirective,

  ],
  imports: [
    CommonModule,
    WebcamModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    PipesModule,
    IvyCarouselModule,
    NgxChartsModule,
    NgxTranslateModule,
    AngularDraggableModule,
    DynamicFormModule,
    RsplButtonModule,
    RsplTextboxModule

  ],
  exports: [
    ReactiveFormsModule,
    WebcamModule,
    FormsModule,
    MaterialModule,
    IvyCarouselModule,
    NgxChartsModule,
  ],
  providers: [AccountsService , AccountsAPIConstants]

})
export class SharedModule { }
