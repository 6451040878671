import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { FieldConfig } from '@app/shared/module/dynamic-form/components/model/field.interface';

@Component({
  selector: 'app-f-textarea',
  templateUrl: './f-textarea.component.html',
  styleUrls: ['./f-textarea.component.scss'],
})
export class FBTextareaComponent {
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group: UntypedFormGroup;
  appearance: MatFormFieldAppearance = 'outline';

  constructor() { }

}
