import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class localStorageService {
  TOKEN_KEY = 'auth-token';
  
  constructor() {

  }
  /**
   *get LocalStorage by name
   *
   * @param name
   * @returns
   */
  getLocalStorage(name: string) {
    return localStorage.getItem(name);
  }
  /**
   *set LocalStorage by name
   *
   * @param name
   * @param value
   */
  setLocalStorage(name: string, value: string) {
    localStorage.setItem(name,value)
  }
  /**
   * delete LocalStorage by name
   *
   * @param name
   */
  deleteLocalStorage(name: string) {
    localStorage.removeItem(name);
  }

  /**
   * delete all LocalStorage
   */
  deleteAll() {
    localStorage.clear();
  }


}