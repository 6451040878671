export class ChipSetSourceData {
  chipSet = [
    {
      type: 'Chip Set',
      html: `<h4>OneLine Chip Set</h4>
       <lib-rspl-chip-set
        [dataSource]="dataSource"
      ></lib-rspl-chip-set>
      <hr />
      <h4>Stacked chip set</h4>
      <lib-rspl-chip-set
        [dataSource]="dataSource"
        [chipType]="'stacked'"
      ></lib-rspl-chip-set>`,
      css: ``,
      ts: `import { chipSetItem } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/chipset.model';
      import uiComponentsData from './json/ui-components.json'; //Json path is only for demo purpose. It may vary depending on your reqirement

      dataSource: chipSetItem[]= uiComponentsData.uiComponent.chipSetData; //data calling will be different as per user requirment

      /**
      *chip set values array with name and isSelected keys
      *
      * @type {chipSetItem[]}
      * @memberof ChipSetComponent
      */
      @Input() dataSource: chipSetItem[] | any;
     /**
       *type of chip set
       *
       * @type {('stacked' | 'oneLine')}
       * @memberof ChipSetComponent
       */
      @Input() chipType: 'stacked' | 'oneLine' = 'oneLine';
      /**
       *event for capture selected chip
       *
       * @memberof ChipSetComponent
       */
      @Output() chipClick = new EventEmitter();

      selectedChip: chipSetItem | undefined;`,
      json: `
      "chipSetData":[
        {
          "name" : "Chip Set 1",
          "isSelected" : false
        },
        {
          "name" : "Chip Set 2",
          "isSelected" : true
        },
        {
          "name" : "Chip Set 3",
          "isSelected" : false
        }
      ] `,
    },
  ];
}
