export class PopupSourceData {
  popup = [
    {
      type: 'Popup Group',
      html: ` <lib-rspl-button
            [id]="'invite-new'"
            [icon]="'add'"
            class="float-left"
            [buttonType]="'flat-button'"
            color="primary"
            label="Add"
            (buttonClick)="openPopup()"
          >
          </lib-rspl-button>`,
      css: ``,
      ts: `import { PopupService } from '../../shared/services/popup/popup.service';
            import { SubTaskPopupComponent } from '../shared/components/sub-task-popup/sub-task-popup.component';

            /**
             * reference variable to display dynamic component
             */
          @ViewChild('dynamicLoadComponent') dynamicLoadComponent: ComponentRef<IComponent> | any;

            popupOpen: ComponentModel = {
                componentName:SubTaskPopupComponent
              };

            constructor(rivate popupService: PopupService){}

            openPopup(){
                this.popupService.dialogWidth = 'small';
                this.popupService.openPopup(this.popupOpen);
                this.popupService.dialogRef.afterClosed();
              }

           `,
      json: ``,
    },
  ];
}
