export class SelectChipMenuSourceData {
  chipmenu = [
    {
      type: 'Selectable Chip Menu',
      html: `<lib-rspl-selectable-menu-chip
            [chipData]="chipData"
            [selectedItem]="selectebleChipWithMenu"
            (ChipSelect)="chipSelect($event)"
          ></lib-rspl-selectable-menu-chip>`,
      css: ``,
      ts: `import { selectableChipMenu } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/selecteble-chip-with-menu.model';
            import uiComponentsData from './json/ui-components.json'; //Json path is only for demo purpose. It may vary depending on your reqirement

            /**
             *used to pass chip data along with menu data
            *
            * @type {(selectableChipMenu[] | any)}
            * @memberof RsplSelectableMenuChipComponent
            */
           @Input() chipData: selectableChipMenu[] | any;
            /**
             *used to store selected chip item
             *
             * @type {(number | any)}
             * @memberof RsplSelectableMenuChipComponent
             */
            @Input() selectedItem: number | any;
            /**
             *event emits index of selected chip item
             *
             * @memberof RsplSelectableMenuChipComponent
             */
            @Output() ChipSelect = new EventEmitter();
             /**
              *event emits value and index of selected chip item
              *
              * @memberof RsplSelectableMenuChipComponent
              */
             @Output() MenuSelect = new EventEmitter();

            /**
             * Select menu chip data
             */
            chipData: selectableChipMenu[] = uiComponentsData.uiComponent.chipdata;`,
      json: `"chipdata": [
                {
                  "label": "Default",
                  "isDisabled": false,
                  "number": 2,
                  "icon": "more_vert",
                  "menu": [
                    {
                      "label": "Duplicate",
                      "isDisabled": false
                    },
                    {
                      "label": "Delete",
                      "isDisabled": false
                    }
                  ]
                },
                {
                  "label": "Waterfall Projects",
                  "isDisabled": false,
                  "icon": "more_vert",
                  "menu": [
                    {
                      "label": "Duplicate",
                      "isDisabled": false
                    },
                    {
                      "label": "Delete",
                      "isDisabled": false
                    }
                  ]
                },
                {
                  "label": "Design Projects",
                  "isDisabled": false,
                  "icon": "more_vert",
                  "menu": [
                    {
                      "label": "Duplicate",
                      "isDisabled": false
                    },
                    {
                      "label": "Delete",
                      "isDisabled": false
                    }
                  ]
                }
              ]`,
    },
  ];
}
