<div class="sign-up-wrapper sign-up-first scroll-parent ofa-y" style="background-image: url(assets/images/self-registration/signup-gradient.svg);" *ngIf="componentType!==authRoute.AUTH.LINK_EXPIRED else expire">
  <div class="auth-page-header py-2 px-3">
    <img src="assets/images/5day-admin-logo.svg" alt="5day">
  </div>
  <!-- login form -->
  <div class="sign-up-content container d-flex scroll-parent">
    <div class="hero-content py-2 d-flex flex-items-center flex-column m-auto col-sm-6 col-lg-5 max-100">
      <div *ngIf="componentType===authRoute.AUTH.FORGOT_PASSWORD">
        <app-forgot-password></app-forgot-password>
      </div>
      <div *ngIf="componentType===authRoute.AUTH.RESET_PASSWORD">
        <app-reset-password></app-reset-password>
      </div>
      <div *ngIf="componentType===authRoute.AUTH.LOGIN">
        <app-login-page></app-login-page>
      </div>
    </div>
    <div class="hero-image d-none d-sm-flex flex-items-center ml-auto pl-sm-2 pl-md-5 py-2 col-sm-6 col-md-7">
      <div class="sign-up-image d-flex flex-justify-end mx-auto">
        <img src="assets/images/self-registration/welcome-signup.svg" alt="5day" class="d-block">
      </div>
    </div>
  </div>
</div>

<ng-template #expire><app-link-expire></app-link-expire></ng-template>
