export const USER_STORE = 'metaData';
export const STORE_CONFIG = { keyPath: 'key', autoIncrement: true };
export const STORE_SCHEMA = [
  { name: 'data', keypath: 'data', options: { unique: false } },
  { name: 'key', keypath: 'key', options: { unique: false } }
]
export const USER_STORE_KEY = 'schema';
export const USER_KEY = 'authUser';
export const TANENT_KEY = 'tenant-id';
export const USER_INFO_KEY = 'info';
export const HEADER_LABEL = 'header-label';