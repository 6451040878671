import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupComponent } from '../../components/popup/popup.component';
import { ComponentModel } from '../../model/component.model';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  /**
   * DialogRef variable for popup (Mat dialog)
   */
  dialogRef: MatDialogRef<any>;
  constructor(private dialog: MatDialog) { }

  /**
   * This method expects a data parameter and opens a mat popup dialog box.
   */
  openPopup(data: ComponentModel) {
    this.dialogRef = this.dialog.open(PopupComponent, {
      data: data,
      disableClose: true,
      width: '500px',
    });
  }
}
