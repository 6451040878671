<div class="nav-wrapper parentOf-hidden" [ngClass]="{ 'customwidth-expandBtn': isExpanded, 'customwidth-expandBtn-no': !isExpanded  }" (window:resize)="onResize($event)">
  <span class="nav-wrapper-inner p-2a d-flex flex-items-end">
    <img src="./../../../../assets/image.png" alt="5Day Admin" class="size-30" />
    <span *ngIf="isExpanded" class="nav-logo-label fs-14 color-white pl-2 o-60">{{ 'general.admin' | translate }}</span>
  </span>
  <ul class="nav-list-ul p-0 px-2 m-0 list-style-none">
    <li *ngFor="let navList of ('sideNav.menuItems' | translate); trackBy: sidenavMenuTrackBy; let i = index" class="fs-14 mb-1 cursor-pointer" [class.flex-justify-left]="isExpanded"
      [attr.id]="'sidenav_item_'+navList?.name" [class.flex-justify-center]="!isExpanded">
      <a href="javascript:void(0)" [attr.id]="'click_on_nav_item'+i" (click)="onMenuClick(navList.name,navList.routerLink)" [class.active]="currentURL.includes(navList.routerLink)" class="py-2 px-3 fs-14 d-flex flex-items-center o-90 br-xs cursor-pointer">
        <lib-rspl-mat-icon class="fs-20 fs-fixed ml-n1 mt-n1 mb-n1" [id]="'iconIdOnToggle'+i" [iconType]="'outlined'" [iconName]="navList?.icon"></lib-rspl-mat-icon>
        <span class="flex-1 pl-2 d-flex flex-items-center" *ngIf="isExpanded" [attr.id]="'navitem-name'+i">{{navList?.name}}</span>
      </a>
    </li>
  </ul>
  <div class="d-block menu-add-btn position-sticky bottom-0 z-index-1 mt-auto p-2a">
    <lib-rspl-menu [menuItems]="menuItems" (menuItemClick)="menuItemClick($event)" class="col-12" id="bottom_menu">
      <a href="javascript:void(0)" class="d-flex flex-items-center br-sm">
        <span id="iconIdOnToggle" class="d-flex flex-justify-center d-flex flex-items-center flex-justify-center size-30 bg-gray br-xs">A</span>
        <div *ngIf="isExpanded" class="flex-1 mb-0 d-flex flex-items-center text-normal color-white fs-14 pl-2 minwidth-0">
          <span id="navitem-name-admin" class="text-ellipsis">{{ 'general.admin' | translate }}</span>
          <lib-rspl-mat-icon id="keyboard_arrow_down" [iconType]="'outlined'" [iconName]="'keyboard_arrow_down'" class="ml-auto"></lib-rspl-mat-icon>
        </div>
      </a>
    </lib-rspl-menu>
  </div>

  <a href="javascript:void(0)" id="on_toggle_nav_bar" class="hidden-default size-20 br-full bg-white fs-14 fs-fixed color-primary box-shadow mt-3 mr-n2 position-absolute top-0 right-0 z-index-1 d-flex flex-items-center flex-justify-center"
    (click)="onToggleNavBar()">
    <lib-rspl-mat-icon id="on_toggle_nav_bar_icon" [iconType]="'outlined'" [iconName]="isExpanded ? 'angle_left' : 'angle_right'"></lib-rspl-mat-icon>
  </a>
</div>

<div class="example-sidenav-content app-container main-content main-wrapper"
  [ngClass]="{ 'customwidth-expandBtn-layout': isExpanded, 'customwidth-expandBtn-layout-no': !isExpanded  }">
  <app-header class="header d-flex" *ngIf="showHeader" [headerLabel]="headerLabel | titlecase">
  </app-header>
  <div class="layout-wrapper col-12">
    <router-outlet (activate)="setHeader()"></router-outlet>
  </div>
</div>
