import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@app/modules/authentication/services/login.service';
import { LoadingService } from '@app/shared/components/loader/loading.service';
import { ToastrMessageService } from '@app/shared/services/toastr/toastr.service';
import { catchError, filter, finalize, Observable, switchMap, take, tap, throwError } from 'rxjs';
import { routerObj } from '../constants/router-constants';
import { StorageService } from '../services/storage.service';
import { TokenStorageService } from '../services/token.service';
import { AuthenticationAPIConstants } from '@app/modules/authentication/api-constants/authentication-api-constants';

const TOKEN_HEADER_KEY = 'Authorization';
// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json',
//     'Access-Control-Allow-Origin': '*',
//     "Access-Control-Allow-Headers": "X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization",
//     "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE, PATCH"

//   }),responseType:'text', observe: 'response'
// };
@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  loadingQueue: string[] = [];

  constructor(
    private loader: LoadingService,
    private _router: Router,
    private tokenService: TokenStorageService,
    private loginService: LoginService,
    private toastrMessageService: ToastrMessageService,
    private dataStorage: StorageService,
    private url: AuthenticationAPIConstants
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let authReq = request;
    if (request.headers.get('disable-loader') != 'true') {
      this.loadingQueue.push(request.url);
      this.loader.show();
    }
    if (this.tokenService.istokenTimeExpired() && !this.loginService.gettingRefreshToken.value
      && !request.url.includes(this.url.endPoints['auth'].refreshToken)) {
      this.loginService.refreshToken().subscribe();
    }
    return this.loginService.gettingRefreshToken$.pipe(
      filter((isRefreshing) => {
        return !isRefreshing || request.url.includes(this.url.endPoints['auth'].refreshToken);
      }),
      take(1),
      switchMap((res) => {
        const token = this.tokenService.getToken();
        // TODO : Backend need to fix this issue (api should success when token is one of headers). Will remove first if condition in future.
        if (request.url.includes('api/schemadefinition/schemas') || request.url.includes('api/tenant/signup/schema')) {
          // console.log("Schema Call");
        } else if (token) {
          authReq = this.addTokenHeader(request, token);
        } else {
          authReq = this.addTenentToHeader(request);
        }
        return next.handle(authReq).pipe(
          catchError((error) => {
            // Checking if it is an Authentication Error (401)
            if (error.status === 401 || error.status === 403 || request.url.includes(this.url.endPoints['auth'].refreshToken)) {
              this.loginService.signOut();
              error && error.error && this.toastrMessageService.openToastrError(error.error.message, "Error");
              this._router.navigateByUrl(
                '/' + routerObj.AUTH.AUTH + '/' + routerObj.AUTH.LOGIN
              );
              return throwError(error);
            }
            else if (error.status === 500) {
              this.toastrMessageService.openToastrError('Something Went Wrong !', "Error");
              return throwError(error);
            }
            else if (error.error && error.error.errors) {
              return throwError(error);
            }
            else if (error && error.error && error.error.message) {
              this.toastrMessageService.openToastrError(error.error.message, "Error");
              return throwError(error);
            }
            else {
              this.toastrMessageService.openToastrError('Connection Timeout. Please check your internet connectivity.', "Error");
            }
            return throwError(error);
          }),
          finalize(() => {

            if (request.headers.get('disable-loader') != 'true') {
              this.loadingQueue.pop();
              if (this.loadingQueue.length === 0) {
                this.loader.hide();
              }
            }

          })
        );
      }));
  }
  /**
   *  use for adding token in header of authenticate api
   * @param request
   * @param token
   * @returns
   */
  private addTokenHeader(request: HttpRequest<any>, token: string | null) {
    return request.clone({
      headers: request.headers.set('response-data-required', `true`).set('Authorization', `Bearer ${token}`).set('tenant-id', this.dataStorage && this.dataStorage.tanentId ? this.dataStorage.tanentId : '')
      //.set('Access-Control-Allow-Origin', '*').set('Access-Control-Allow-Headers', 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization').set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, DELETE, PATCH')
    });
  }
  /**
  //  * use for adding TENENT_KEY in header of login apis
  //  * @param request
  //  * @returns
  //  */
  private addTenentToHeader(request: HttpRequest<any>) {
    return request.clone({
      headers: request.headers.set('accounturl', '5dayadmin') // TODO : currently account url is hardcoded once we get this from backend we can replace it
    });
  }
}
