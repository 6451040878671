import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class ToastrMessageService {

  config: object = {
    timeOut: 3000,
    closeButton: true
  }

  constructor(private toastr: ToastrService) { }

  openToastrSuccess(message: string, heading?: string) {
    this.toastr.success(message, heading ? heading : '', this.config);
  }
  openToastrError(message: string, heading?: string) {
    this.toastr.error(message, heading ? heading : '', this.config);
  }
  openToastrWarning(message: string, heading?: string) {
    this.toastr.warning(message, heading ? heading : '', this.config);
  }

}
