export class RadioSourceData {
  radio = [
    {
      type: 'Simple Radio Button Group',
      html: `<lib-rspl-radio-list
      class="float-left"
      [list]="simpleRadioList"
      id="view-type-radio"
      [iconRequired]="true"
      formControlName="radiobutton1"
    >
    </lib-rspl-radio-list>`,
      css: ``,
      ts: `import { RadioButtonList } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/radiobuton-list.model';
      import uiComponentsData from './json/ui-components.json'; //Json path is only for demo purpose. It may vary depending on your reqirement

      /**
       * Simple radio list data
       */
      simpleRadioList: RadioButtonList[] = uiComponentsData.uiComponent.radioList.simpleRadioBtn; //data calling will be different as per user requirment`,
      json: ` "simpleRadioBtn": [
        {
          "id": 1,
          "value": "radio-one",
          "label": "Simple Radio One"
        },
        {
          "id": 2,
          "value": "radio-two",
          "label": "Simple Radio Two"
        }
      ];`,
    },
    {
      type: 'Icon Radio Button Group',
      html: ` <lib-rspl-radio-list
      class="float-left"
      [list]="iconRadioList"
      id="view-type-radio"
      [iconRequired]="true"
      formControlName="radiobutton1"
    >
    </lib-rspl-radio-list>`,
      css: ``,
      ts: `import { RadioButtonList } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/radiobuton-list.model';
      import uiComponentsData from './json/ui-components.json'; //Json path is only for demo purpose. It may vary depending on your reqirement

      /**
       * Icon radio list data
       */
      iconRadioList: RadioButtonList[] = uiComponentsData.uiComponent.radioList.iconRadioBtn; //data calling will be different as per user requirment`,
      json: ` "iconRadioBtn": [
        {
          "id": 1,
          "value": "radio-icon-one",
          "iconName": "home",
          "label": "Icon Radio Button one"
        },
        {
          "id": 2,
          "value": "radio-icon-two",
          "iconName": "settings",
          "label": " Icon Radio Button two"
        }
      ];`,
    },
    {
      type: 'Icon Tooltip Radio Button Group',
      html: ` <lib-rspl-radio-list
      class="float-left"
      [list]="iconRadioList"
      id="view-type-radio"
      [iconRequired]="true"
      formControlName="radiobutton1"
    >
    </lib-rspl-radio-list>`,
      css: ``,
      ts: `import { RadioButtonList } from '@rspl/angular-deps-library/dist/rspl-angular-lib/model/radiobuton-list.model';
      import uiComponentsData from './json/ui-components.json'; //Json path is only for demo purpose. It may vary depending on your reqirement

      /**
   * Icon tooltip radio list data
   */
   iconTooltipRadioList: RadioButtonList[] = uiComponentsData.uiComponent.radioList.iconTooltipRadioBtn; //data calling will be different as per user requirment`,
      json: `  "iconTooltipRadioBtn": [
        {
          "id": 1,
          "value": "radio-icon-one",
          "iconName": "home",
          "label": "Tooltip Icon Radio Button one",
          "tooltipMessage": "home icon"
        },
        {
          "id": 2,
          "value": "radio-icon-two",
          "iconName": "settings",
          "label": "Tooltip Icon Radio Button two",
          "tooltipMessage": "settings icon"
        }
      ];`,
    },
  ];
}
