import { Directive, HostListener } from '@angular/core';
/**
 *block copy paste data into password field
 *
 * @export
 * @class BlockCopyPasteDirective
 */
@Directive({
  selector: '[appBlockCopyPasteDirective]'
})

export class BlockCopyPasteDirective {
  constructor() {
  }
  /**
   * hostlistener for paste event
   * @param e
   */
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }
  /**
   * hostlistener for copy event
   * @param e 
   */
  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }
  /**
   * hostlistener for cut event
   * @param e 
   */
  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

}