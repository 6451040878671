import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@angular/flex-layout';
import { LoginService } from './services/login.service';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NgxTranslateModule } from '@app/shared/module/ngx-translate/ngx-translate.module';
import { AuthenticationAPIConstants } from './api-constants/authentication-api-constants';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { LinkExpireComponent } from './components/link-expire/link-expire.component';
import { SwitchDeviceComponent } from './components/switch-device/switch-device.component';
import {
  RsplButtonModule,
  RsplCardsModule,
  RsplCheckboxModule,
  RsplMatIconModule,
  RsplTextboxModule,
} from '@rspl/angular-deps-library/dist/rspl-angular-lib';

@NgModule({
  declarations: [
    LoginPageComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AuthenticationComponent,
    LinkExpireComponent,
    SwitchDeviceComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CoreModule,
    AuthenticationRoutingModule,
    NgxTranslateModule,
    RsplButtonModule,
    RsplTextboxModule,
    RsplMatIconModule,
    RsplCardsModule,
    RsplCheckboxModule
  ],
  providers: [LoginService, AuthenticationAPIConstants],
  bootstrap: [LoginPageComponent],
})
export class AuthenticationModule {}
