import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../../model/field.interface';

@Component({
  selector: 'app-fb-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class FBDateFilterComponent {
  /**
   * fieldConfig defines the interface of formfiled with its mandate and optional parameters.
   */
  field: FieldConfig;
  /**
   * group is a FormGroup which binds with the dynamic form group as a formControlerName
   */
  group: UntypedFormGroup;
  /**
   * This variable holds the min date range value of the date-picker
   */
  minDate = new Date();

  constructor() { }

  ondateChange(event: any, type: string, fieldName: string) {
    this.group.patchValue({ [type]: event });
    if (
      (this.group.getRawValue()[fieldName][this.field.options[0]] != '' &&
        this.group.getRawValue()[fieldName][this.field.options[0]] != null) ||
      (this.group.getRawValue()[fieldName][this.field.options[1]] != '' &&
        this.group.getRawValue()[fieldName][this.field.options[1]] != null)
    ) {
      Object.keys(this.group.getRawValue()).forEach((key) => {
        if (key != fieldName) {
          this.group.patchValue({ [key]: null });
        }
      });
    }
    if (
      this.group.getRawValue()[fieldName][this.field.options[0]] != '' &&
      this.group.getRawValue()[fieldName][this.field.options[0]] != null &&
      this.group.getRawValue()[fieldName][this.field.options[1]] != '' &&
      this.group.getRawValue()[fieldName][this.field.options[1]] != null &&
      this.group.getRawValue()[fieldName][this.field.options[0]] >
      this.group.getRawValue()[fieldName][this.field.options[1]]
    ) {
      // console.log('apply validations');
    }
  }
}
