import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appError]'
})
export class ErrorDirective implements OnChanges {
  /**
   *error block which is generated by form control
   *
   * @type {object}
   * @memberof ErrorDirective
   */
  @Input() error: object;
  /**
   *name of particular form field 
   *
   * @type {string}
   * @memberof ErrorDirective
   */
  @Input() name: string;
  /**
   *label of particular form field 
   *
   * @type {string}
   * @memberof ErrorDirective
   */
  @Input() label: string;
  constructor(private el: ElementRef, private translate: TranslateService) { }
  /**
   * lifecycle event hit when every change happen in formField
   */
  ngOnChanges() {

    this.error && this.translate.get(`errors.${this.name}.${Object.keys(this.error)[0]}`).
      subscribe((data) => {
        Object.keys(this.error)[0] === "minlength" && (data = data.replace('<minChar>', this.error!['minlength']!['requiredLength']));
        Object.keys(this.error)[0] === "max" && (data = data.replace('<max>', this.error!['max']!['max']));
        Object.keys(this.error)[0] === "serverError" && (data = this.error!['serverError']);
        this.el.nativeElement.innerHTML = `${data.includes('***') ? data.replace('***', this.label) : data}`
      })
  }
}
