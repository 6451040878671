<div class="site-wrapper">
  <main class="site-content">
    <div class="intro-page">
      <lib-rspl-layout-card>
        <figure class="card-icon center">
          <img src="../../../../../assets/images/broken-link.svg" alt="Sorry! The link has expired">
        </figure>
        <h3>{{ 'auth.linkExpired.linkExpiredHeading' | translate }}</h3>
        <p>{{ 'auth.linkExpired.linkExpiredSubHeading' | translate }}</p>
        <lib-rspl-button buttonType="flat-button" id="ResetPassword" color="primary"
          label="{{ 'auth.linkExpired.ResetPassword' | translate }}"
          [routerLink]="['/'+ routerObj.AUTH.AUTH + '/' + routerObj.AUTH.FORGOT_PASSWORD]">
        </lib-rspl-button>
        <a [routerLink]="['/'+ routerObj.AUTH.AUTH + '/' + routerObj.AUTH.LOGIN]" id="backToLoginLink"
          routerLinkActive="router-link-active" title="{{ 'auth.linkExpired.backToLogin' | translate }}"
          class="txt-link">{{
          'auth.linkExpired.backToLogin' | translate }}</a>
      </lib-rspl-layout-card>
    </div>
  </main>
</div>
