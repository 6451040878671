import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  CanLoad,
  Route,
  Router,
  UrlSegment,
} from '@angular/router';
import { Observable } from 'rxjs';
import { routerObj } from '../constants/router-constants';
import { TokenStorageService } from '../services/token.service';
@Injectable({
  providedIn: 'root',
})
export class AuthCanLoad implements CanLoad {
  constructor(private router: Router, private tokenService: TokenStorageService) { }
  /**
   * it used for only allowed authentication flow related urls
   * @param route
   * @param segments
   * @returns
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    let token: string | null = this.tokenService.getToken();
    if(token){
      this.router.navigateByUrl(routerObj.ADMIN + '/' + routerObj.DASHBOARD);
      return false;
    }
    if (
      segments &&
      segments[1] &&
      (segments[1].path === routerObj.LOGIN ||
        segments[1].path === routerObj.AUTH.FORGOT_PASSWORD ||
        segments[1].path === routerObj.AUTH.RESET_PASSWORD ||
        segments[1].path === routerObj.AUTH.LINK_EXPIRED)
    ) {
      return true;
    } else {
      this.router.navigateByUrl(routerObj.AUTH.AUTH + '/' + routerObj.AUTH.LOGIN);
      return false;
    }
  }
}
