import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';
import { DemoPageComponent } from './demo-page/demo-page.component';
import { CommonModule } from '@angular/common';
import { DemoPopupPageComponent } from './demo-popup-page/demo-popup-page.component';
import { RsplAccordianModule, RsplButtonModule, RsplCheckboxGroupModule, RsplDragListModule, RsplMatIconModule, RsplRadioListModule, RsplTextareaModule, RsplTextboxModule } from '@rspl/angular-deps-library/dist/rspl-angular-lib';


@NgModule({
  declarations: [
    DemoPageComponent],
  imports: [
    FormsModule,
    SharedModule,
    CommonModule,
    PagesRoutingModule,
    RsplButtonModule,
    RsplCheckboxGroupModule,
    RsplTextboxModule,
    RsplAccordianModule,
    RsplMatIconModule,
    RsplRadioListModule,
    RsplTextareaModule,
    RsplDragListModule
  ],
  exports: [DemoPageComponent]
})
export class PagesModule { }
