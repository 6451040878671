export class NestedMenuSourceData {
  nestedMenu = [
    {
      type: 'Nested Menu',
      html: `   <lib-rspl-nested-menu
      [navItems]="nestedMenuu"
    ></lib-rspl-nested-menu>`,
      css: ``,
      ts: `import {  NavItem } from '@rspl/angular-deps-library/dist/rspl-angular-lib';
      import uiComponentsData from './json/ui-components.json';

      @Input() navItems: NavItem[] | any;
      @Output() onClick = new EventEmitter();

      nestedMenuu: NavItem[] =  uiComponentsData.uiComponent.nestedMenu`,
      json: `"nestedMenu": [
      {
        "iconName": "more_horiz",
        "children": [
          {
            "id": "hide_tasks",
            "label": "Hide Completed Tasks",
            "selected": false
          },
          {
            "id": "hide_empty_columns",
            "label": "Hide Empty Columns",
            "selected": false
          },
          {
            "id": "sub_tasks",
            "label": "Sub Tasks",
            "selected": false,
            "children": [
              {
                "id": "subMenu1",
                "label": "Show Sub Tasks",
                "selected": false
              },
              {
                "id": "subMenu2",
                "label": "Show Sub Tasks Expanded",
                "selected": false
              },
              {
                "id": "subMenu3",
                "label": "Show Sub Tasks Expanded",
                "selected": false
              },
              {
                "id": "subMenu4",
                "label": "Show Sub Tasks as Separate Tasks",
                "selected": false
              },
              {
                "id": "subMenu5",
                "label": "Show Sub Tasks Count on Task",
                "selected": false
              },
              {
                "id": "subMenu6",
                "label": "Hide Completed Sub Tasks",
                "selected": false
              }
            ]
          },
          {
            "id": "hide_repeat_tasks",
            "label": "Hide Repeating Tasks",
            "selected": false
          },
          {
            "id": "save_changes",
            "label": "Save view changes",
            "selected": false
          },
          {
            "id": "reset_tasks",
            "label": "Reset to default view",
            "selected": false
          },
          {
            "id": "set_default_view",
            "label": "Set as default view",
            "selected": false
          },
          {
            "id": "set_default_everyone",
            "label": "Set as default to everyone",
            "selected": false
          },
          {
            "id": "export_csv",
            "label": "Export to CSV",
            "selected": false
          }
        ]
      }
    ] `,
    },
  ];
}
