export class AccordianSourceData {
  accordian = [
    {
      type: 'Accoridan Group',
      html: `<lib-rspl-accordian
        [accordionData]="accordianData"
      ></lib-rspl-accordian>`,
      css: ``,
      ts: `

        /**
   * Parent/Main Accordion reference
   */
   @ViewChild("accordion") accordion: any;
   /**
    * Child/sub accordian referenc
    */
   @ViewChild("subAccordion") subAccordion: any;
   /**
    * Parent accordian open state true/false
    */
   panelOpenState = false;
   /**
    * Accordion data
    */
   @Input() accordionData: any;
   /**
    * Emit selected option from accrodian menu
    */
   @Output() selectedMenu = new EventEmitter();
   /**
    * reference variable to display dynamic component
    */
   outlet: any;
   /**
    * component injector
    */
   injector: Injector | any;
   /**
    * Accordion Class
    */
   @Input() cssClass: string | any;

        /**
         * Accordian data
         */
        accordianData = [
            {
              panelOpenState: false,
              text: 'Accordian 1',
              type: 'ProjectSummary',

              disabled: false,
              component: DesignProjectsComponent,
              data: [],
            },
            {
              panelOpenState: false,
              text: 'Accordian 2',
              type: 'ProjectSummary',

              disabled: false,
              component: DesignProjectsComponent,
              data: [],
            },
          ];`,
      json: ``,
    },
  ];
}
