import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DynamicFormRoutingModule } from './dynamic-form-routing.module';
import { MaterialModule } from 'src/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormComponent } from './components/dynamic-form.component';
import { DynamicFieldDirective } from './components/directives/dynamic-field.directive';
import { FBTextboxComponent } from './components/renderers/textbox/f-textbox.component';
import { FBDropdownSingleSelectComponent } from './components/renderers/dropdown-single-select/f-dropdown-single-select.component';
import { FBDatepickerComponent } from './components/renderers/datepicker/f-datepicker.component';
import { FBRadioButtonListComponent } from './components/renderers/radiobutton-list/f-radiobutton-list.component';
import { FBCheckboxComponent } from './components/renderers/checkbox/f-checkbox.component';
import { FBCheckboxGroupComponent } from './components/renderers/checkbox-group/checkbox-group.component';
import { FBTextareaComponent } from './components/renderers/textarea/f-textarea.component';
import { CheckboxIndividualComponent } from './components/renderers/checkbox-group/components/checkbox.component';
import { CheckboxGroupListComponent } from './components/renderers/checkbox-group/components/checkbox-group.component';
import { FBSlideToggleComponent } from './components/renderers/slide-toggle/f-slide-toggle.component';
import { FBDropdownMultiSelectComponent } from './components/renderers/dropdown-multi-select/f-dropdown-multi-select.component';
import { FBButtonToggleComponent, IsValueCheckedPipe } from './components/renderers/button-toggle/button-toggle.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FBPictureUploadComponent } from './components/renderers/picture-upload/picture-upload.component';
import { AutoFocusDirective } from './components/directives/auto-foucs.directive';
import { FBDateFilterComponent } from './components/renderers/date-filter/date-filter.component';
import { DynamicFormService } from './services/dynamic-form.service';
@NgModule({
    declarations: [
        DynamicFormComponent,
        DynamicFieldDirective,
        FBTextboxComponent,
        FBDropdownSingleSelectComponent,
        FBDatepickerComponent,
        FBRadioButtonListComponent,
        FBCheckboxComponent,
        FBCheckboxGroupComponent,
        FBTextareaComponent,
        CheckboxIndividualComponent,
        CheckboxGroupListComponent,
        FBSlideToggleComponent,
        FBDropdownMultiSelectComponent,
        FBButtonToggleComponent,
        FBPictureUploadComponent,
        AutoFocusDirective,
        FBDateFilterComponent,
        IsValueCheckedPipe
    ],
    imports: [
        CommonModule,
        DynamicFormRoutingModule,
        MaterialModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        FormsModule,
    ],
    exports: [
        DynamicFormComponent,
        FBTextboxComponent,
        FBDropdownSingleSelectComponent,
        FBDatepickerComponent,
        FBRadioButtonListComponent,
        FBCheckboxComponent,
        FBCheckboxGroupComponent,
        FBTextareaComponent,
        CheckboxIndividualComponent,
        CheckboxGroupListComponent,
        FBSlideToggleComponent,
        FBDropdownMultiSelectComponent,
        FBButtonToggleComponent,
        FBPictureUploadComponent,
        FBDateFilterComponent
    ],
    providers: [
        DynamicFormService
    ]
})
export class DynamicFormModule { }
