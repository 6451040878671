import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { routerObj } from './../../../../../core/constants/router-constants';
import { LoginService } from './../../../../../modules/authentication/services/login.service';
import { SharedService } from './../../../../../modules/shared/services/shared.service';
import { Menu } from '../../../../../shared/model/menu.model';
import { CommonService } from './../../../../../shared/services/common.service';
import { PopupService } from '../../../../../shared/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import { navbarInterface } from '../../model/navbar.model';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ComponentModel } from '@app/shared/model/component.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  /**
   * emits the selected menu label name to store it global and use in another components.
   */
  @Output() selectedMenuLabel = new EventEmitter<any>();

  /**
   * sidebar label
   *
   * @type {string}
   * @memberof NavbarComponent
   */
  @Input() sidebarHeaderLabel = '';
  @Input() isExpandedInput = true;
  /**
   *sidebar logo
   *
   * @type {string}
   * @memberof NavbarComponent
   */
  @Input() sidebarHeaderLogo = 'assets/logo3.png';
  /**
   * sidebar navigation list
   *
   * @type {navbarInterface[]}
   * @memberof NavbarComponent
   */
  @Input() sidenavMenuList: navbarInterface[];
  /**
   * Holds value of sidebar expanded/not
   */
  isExpanded = true;
  /**
   *selected menu
   *
   * @type {string}
   * @memberof NavbarComponent
   */
  selectedMenu: string;
  /**
   *menu items for admin menu
   *
   * @type {Menu[]}
   * @memberof NavbarComponent
   */
  menuItems: Menu[] = [
    { id: 1, label: 'Change Password', isDisabled: false },
    { id: 2, label: 'Logout', isDisabled: false }
  ];
  /**
   * Display header/not
   */
  showHeader: boolean = true;
  /**
   * Holds header label value
   */
  headerLabel: string | null = '';
  /**
   * Data which is coming from the shared component(sidenav)
   */
  data: any;
  ChangePasswordPopupData: ComponentModel = {
    componentName: ChangePasswordComponent,
    data: {
      Heading: 'New Password Set Successfully',
      subHeadng: 'Your new password is saved successfully. You can now login using your new password',
      actionButtonLabel: 'Go To Login'
    }
  };

  currentURL: string;

  constructor(
    private router: Router,
    private LoginService: LoginService,
    private translate: TranslateService,
    private popupService: PopupService,
    private commonService: CommonService,
    private sharedService: SharedService,
  ) {
    this.translate.setDefaultLang('en');
    this.checkShowHeader();
    if (window.innerWidth <= 991) {
      this.isExpanded = true;
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => this.checkShowHeader());
  }

  checkShowHeader() {
    this.currentURL = this.router.url;
    if (
      this.currentURL.includes(routerObj.ACCOUNTS.TENANT_DETAILS)
      || this.currentURL.includes(routerObj.PACKAGES.ADD_PACKAGES)
      || this.currentURL.includes(routerObj.PACKAGES.EDIT_PACKAGE)
      || this.currentURL.includes(routerObj.PACKAGES.EDIT_TENANT_FEATURES)
      || this.currentURL.includes(routerObj.FEEDBACK.FEEDBACK_VIEW)
    ) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  }

  setHeader() {
    let path = this.router.url.split('/')[2];
    this.headerLabel = decodeURIComponent(path);
  }
  /**
   * trackBy method
   */
  sidenavMenuTrackBy(index: number) {
    return index;
  }

  /**
   * resize sidebar
   * @param event
   */
  onResize(event) {
    if (event.target.innerWidth <= 991) {
      this.isExpanded = true;
    }
  }
  // on nav menu item list click
  onMenuClick(label: string, routerLink: string) {
    this.sharedService
      .getMessage('saveStatus')
      .pipe(take(1))
      .subscribe((message) => {
        const statusChange = message;
        if (statusChange?.toLowerCase().includes('unsaved')) {
          this.commonService.openConfirmationPopup(
            this.translate.instant('general.confirmation'),
            this.translate.instant('general.unSavedChanges')
          );
          this.popupService.dialogRef
            .afterClosed()
            .subscribe((response: any) => {
              if (response === 'Yes') {
                this.menuSelected(label, routerLink);
                this.sharedService.setMessage('saveStatus', null);
              }
            });
        } else {
          this.menuSelected(label, routerLink);
        }
      });
  }
  /**
   * select specific menu and redirect to that menu
   *
   * @param label
   * @param routerLink
   */
  menuSelected(label, routerLink) {
    this.selectedMenu = label;
    this.selectedMenuLabel.emit(this.selectedMenu);
    this.router.navigateByUrl('/' + routerObj.ADMIN + '/' + routerLink);
  }

  /**
   * side nav menu button click event at the bottom.
   * @param buttonName
   */
  menuItemClick(menuItem: Menu) {
    if (menuItem.id === 2) {
      this.LoginService.logout().subscribe((data: any) => {
        this.LoginService.signOut();
        this.router.navigateByUrl(
          '/' + routerObj.AUTH.AUTH + '/' + routerObj.AUTH.LOGIN
        );
      });
    }
    else {
      this.openChangePasswordPopup();
    }
  }
  /**
   * on toggle icon click event handles below.
   */
  toggelMenuFunction(e: boolean) {
    this.isExpanded = e;
  }

  
  // on toggle side menu item click event in header bar icon.
  onToggleNavBar() {
    this.isExpanded = !this.isExpanded;
    // this.isExpanded.emit(this.isExpandedPass);
  }
  
  openChangePasswordPopup() {
    this.popupService.openPopup(this.ChangePasswordPopupData);
    this.popupService.dialogRef.afterClosed().subscribe((response: any) => {
      response === 'Go To Login' &&
        this.router.navigateByUrl(
          '/' + routerObj.AUTH.AUTH + '/' + routerObj.AUTH.LOGIN
        );
    });
  }
}
