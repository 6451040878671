<form class="d-flex dynamic-form-tag fs-14 flex-items-start" [formGroup]="group" *ngIf="field">
  <mat-label class="fs-14 o-50">{{field.label}} <span *ngIf="field.required">*</span></mat-label>
  <div class="dynamic-form__field mat-form-field" formGroupName="{{field.name}}" *ngIf="field.options else singleDatePicker">
      <mat-form-field appearance="fill" class="rspl-datepicker">
          <!-- <mat-label>{{field.options[0]}}</mat-label> -->
          <input matInput [matDatepicker]="fromDatePicker" formControlName="{{field.options[0]}}"
              name="{{field.options[0]}}" (dateInput)="ondateChange($event.value,field.options[0],field.name)"
              placeholder="From Date">
          <mat-datepicker-toggle class="position-absolute top-0 right-0 left-0 bottom-0" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill" class="rspl-datepicker">
          <!-- <mat-label>{{field.options[1]}}</mat-label> -->
          <input matInput [matDatepicker]="toDatePicker" formControlName="{{field.options[1]}}"
              name="{{field.options[1]}}" (dateInput)="ondateChange($event.value,field.options[1],field.name)"
              placeholder="To Date">
          <mat-datepicker-toggle class="position-absolute top-0 right-0 left-0 bottom-0" matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
      </mat-form-field>
  </div>
  <ng-template #singleDatePicker>
          <mat-form-field appearance="fill" class="rspl-datepicker">
              <input matInput [matDatepicker]="datePicker" formControlName="{{field.name}}"
                   placeholder="{{field.placeHolder ? field.placeHolder : ''}}" [min]="minDate" readonly>
              <mat-datepicker-toggle class="position-absolute top-0 right-0 left-0 bottom-0" matSuffix [for]="datePicker"></mat-datepicker-toggle>
              <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>
  </ng-template>
</form>
