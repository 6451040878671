import { Component } from '@angular/core';
import { routerObj } from './../../../../core/constants/router-constants';

@Component({
  selector: 'app-link-expire',
  templateUrl: './link-expire.component.html',
  styleUrls: ['./link-expire.component.scss', '../login-page/login-page.component.scss']
})
export class LinkExpireComponent {
  routerObj;
  constructor() {
    this.routerObj = routerObj;
  }
}
